import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { FormInstance } from 'antd/lib/form/Form';
import { AskAlphaSettingsValues } from '../../types';
import ChatDisplayItems from './ChatDisplayItems';
import ChatQuestionInput from './ChatQuestionInput';

type Props = {
    settingsForm: FormInstance<AskAlphaSettingsValues>;
};

const ChatRenderer: React.FC<Props> = ({ settingsForm }) => {
    return (
        <>
            <ChatDisplayItems />
            <ChatQuestionInput settingsForm={settingsForm} />
        </>
    );
};

export default observer(ChatRenderer);
