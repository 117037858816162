import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

interface Props {
    externalWindow?: Window;
    children: React.ReactNode;
    width?: number;
    height?: number;
    title?: string;
    setExternalWindow: (externalWindow?: Window) => void;
    onWindowClose?: () => void;
}

const WindowPortal: React.FC<Props> = (props: Props) => {
    const {
        externalWindow,
        children,
        width = 1280,
        height = 900,
        title = '',
        setExternalWindow,
        onWindowClose
    } = props;

    const [container] = useState(() => document.createElement('div'));

    useEffect(() => {
        const openNewWindow = () => {
            const newWindow = window.open('', '', `width=${width},height=${height}`);

            if (!newWindow) {
                console.error('Failed to open new window.');
                return;
            }

            const cleanup = () => {
                if (onWindowClose) {
                    onWindowClose();
                }

                if (newWindow) {
                    newWindow.close();
                }
            };

            newWindow.document.body.appendChild(container);
            newWindow.document.head.innerHTML = document.head.innerHTML;
            newWindow.document.title = title;

            setExternalWindow(newWindow);

            newWindow.addEventListener('beforeunload', cleanup);

            return () => {
                newWindow.removeEventListener('beforeunload', cleanup);
                cleanup();
            };
        };

        if (!externalWindow) {
            openNewWindow();
        }
    }, [container, width, height, title, externalWindow, setExternalWindow, onWindowClose]);

    if (!externalWindow) {
        return null;
    }

    return createPortal(children, container);
};

export default WindowPortal;
