import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Button, Form, Input, Checkbox, Divider } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { DynamicUiModel } from '../../common/stores/DynamicUiModel';
import { FormInstance } from 'antd/lib/form/Form';
import { FormInputParams } from '../../common/services/types';
import { sortInputs, setInputReadonly } from './DocumentSectionsRenderer';
import { hasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import { TabModel } from '../../common/types/TabModel';
import authContext from '../../authorization/AuthContext';
import Registry from './DynamicControlsRegistry';

type Props = {
    tab: TabModel;
    ui: DynamicUiModel;
    form: FormInstance;
    packageName: string;
    showingBlocks: boolean;
    isVisible: boolean;
    isLoading: boolean;
    setIsVisible: (isVisible: boolean) => void;
    setShowingBlocks: (showingBlocks: boolean) => void;
    getGearIcon: (inputParams: FormInputParams) => false | JSX.Element | undefined;
    handleAction: (control: string, actionId: string) => void;
};

interface FormValues {
    packageName: string;
    showingBlocks: boolean;
}

const DocumentSettingsDialog: React.FC<Props> = ({
    ui,
    tab,
    form,
    packageName,
    showingBlocks,
    isVisible,
    isLoading,
    setIsVisible,
    setShowingBlocks,
    getGearIcon,
    handleAction
}) => {
    const authCtx = React.useContext(authContext);

    const [settingsForm] = useForm<FormValues>();

    const uiInputs = React.useMemo(() => {
        return ui.inputs.filter(i => i.behavior?.renderInSettingsModal);
    }, [ui.inputs]);

    const onClose = (values: FormValues) => {
        setShowingBlocks(values.showingBlocks);
        setIsVisible(false);
        settingsForm.resetFields();
    };

    React.useEffect(() => {
        if (isVisible) {
            settingsForm.setFieldsValue({ packageName, showingBlocks });
        }
    }, [isVisible, settingsForm, packageName, showingBlocks]);

    const formId = React.useMemo(() => `document-settings-form-${tab.id}`, [tab]);

    return (
        <Modal
            centered
            width={600}
            title="Settings"
            // Force render and do not destroy on close because otherwise form values will not be passed to iota from this dialog
            forceRender
            closable={false}
            maskClosable={false}
            visible={isVisible}
            className="document-settings-dialog"
            footer={[
                <Button
                    data-id="document-settings-close"
                    key="document-settings-close"
                    size="large"
                    type="primary"
                    htmlType="submit"
                    form={formId}
                    style={{ fontSize: 14 }}
                >
                    Close
                </Button>
            ]}
        >
            <Form
                id={formId}
                data-id={formId}
                layout="vertical"
                form={settingsForm}
                initialValues={{
                    packageName,
                    showingBlocks
                }}
                onFinish={onClose}
            >
                <Form.Item name="packageName" label="Document Name">
                    <Input disabled />
                </Form.Item>

                <Form.Item name="showingBlocks" valuePropName="checked">
                    <Checkbox>Toggle found blocks</Checkbox>
                </Form.Item>
            </Form>

            {!!uiInputs.length && (
                <>
                    {isLoading ? (
                        <div style={{ height: 30 }} />
                    ) : (
                        <>
                            <Divider />
                            <Form form={form}>
                                <div className="document-settings-widgets">
                                    {sortInputs(uiInputs).map((inputParams, index) => {
                                        const WidgetComponent = Registry[inputParams.controlType];

                                        if (
                                            !hasPermission(
                                                authCtx.permissions,
                                                AppPermissions.CanManageSessionsAndDocuments
                                            )
                                        ) {
                                            setInputReadonly(inputParams);
                                        }

                                        return (
                                            <WidgetComponent
                                                key={index}
                                                ui={ui}
                                                form={form}
                                                inputParams={inputParams}
                                                onAction={handleAction}
                                                getGearIcon={getGearIcon}
                                            />
                                        );
                                    })}
                                </div>
                            </Form>
                        </>
                    )}
                </>
            )}
        </Modal>
    );
};

export default observer(DocumentSettingsDialog);
