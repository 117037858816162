import './tracing';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import './styles/index.css';
import security from './modules/common/services/SecurityService';
import isElectron from 'is-electron';
import isOnline from 'is-online';
import 'mobx-react-lite/batchingForReactDom';
import 'react-virtualized-select/node_modules/react-select/dist/react-select.css';
import 'react-virtualized-select/styles.css';
import NoRolesError from './modules/common/types/CustomErrors';
import UserProfileService from './modules/common/services/UserProfileService';
import { UserInfo } from './modules/common/types/UserInfo';
import { AuthProvider } from './modules/authorization/AuthContext';

declare global {
    // eslint-disable-next-line no-unused-vars
    interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
        require: (val: string) => any;
    }
}

const initApp = () => {
    const userProfileService = new UserProfileService();
    const queryParams = new URLSearchParams(window.location.search);
    const accessToken = queryParams.get('access_token');
    const refreshToken = queryParams.get('refresh_token');

    security.init(accessToken, refreshToken).then(() => userProfileService.getUserInfo().then((userInfo: UserInfo) => {
        // console.log(authenticated ? 'authenticated' : 'not authenticated');
        if (!userInfo!.permissions || !userInfo.permissions.length) {
            const noRolesError = new NoRolesError();
            ReactDOM.render(
                noRolesError.errorScreen,
                document.getElementById('root') as HTMLElement
            );
        } else {
            ReactDOM.render(
                <AuthProvider value={{permissions: userInfo!.permissions, objectPermissions: userInfo!.objectPermissions}}>
                    <App />
                </AuthProvider>,
                document.getElementById('root') as HTMLElement
            );
        }

    })).catch(function (err: object) {
        if (err instanceof NoRolesError) {
            ReactDOM.render(
                (err as NoRolesError).errorScreen,
                document.getElementById('root') as HTMLElement
            );
        } else {
            console.log(err);
            alert('failed to initialize');
        }
    });

    if (module.hot) {
        module.hot.accept(['./App'], () => {
            const NextApp = require('./App').default;
            ReactDOM.render(
                <NextApp />,
                document.getElementById('root') as HTMLElement
            );
        });
    }
};

const electronInit = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Promise.resolve(isOnline()).then(r => {
        if (!r) {
            window.addEventListener('online', initApp);
            ReactDOM.render(
                <div id="offline-overlay">
                    <div className="offline-msg-container">
                        <div className="offline-msg-content">
                            <div className="offline-msg-header">
                Offline
                            </div>
                            <div className="offline-msg-text">
                Your network is unavailable.
                Check your internet connection.
                            </div>
                        </div>
                    </div>
                </div>,
                document.getElementById('root') as HTMLElement
            );

            setTimeout(electronInit, 5000);
        } else {
            initApp();
        }
    });
};

if (isElectron()) {
    electronInit();
} else {
    initApp();
}