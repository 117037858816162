import * as React from 'react';
import { useLocalStore } from 'mobx-react-lite';
import { DocumentVisualStore } from '.';
import { useStore } from '../../common/stores/context';
import { ApplicationDefinitionsStore, ErrorStore } from '../../common/stores';
import ImageService from '../../common/services/ImageService';
import { DocumentService } from '../../common/services';

function createStore(appStore: ApplicationDefinitionsStore, errorStore: ErrorStore) {
    const minioService = new ImageService();
    const documentService = new DocumentService();
    const documentVisualStore = new DocumentVisualStore(appStore, minioService, errorStore, documentService);

    return {
        documentVisualStore
    };
}

const storeContext = React.createContext<ReturnType<typeof createStore> | null>(null);
type Props = {
    children: React.ReactNode;
};

export const DocumentsStoreProvider: React.FC<Props> = ({ children }) => {
    const root = useStore();
    const store = useLocalStore(() => createStore(root.applicationDefinitionsStore, root.errorStore));
    return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
};

export const useDocumentsStore = () => {
    const store = React.useContext(storeContext);
    if (!store) {
        // this is especially useful in TypeScript so you don't need to be checking for null all the time
        throw new Error('You have forgot to use StoreProvider, shame on you.');
    }

    return store;
};
