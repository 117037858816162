import { Button, Divider, Input, Modal } from 'antd';
import { Observer, observer } from 'mobx-react-lite';
import * as React from 'react';
import { useStore } from '../../common/stores/context';
import { DynamicUiModel } from '../../common/stores/DynamicUiModel';
import { Utils } from '../../common/misc/Utils';

type Props = {
    isVisible: boolean;
    handleCancel: () => void;
    ui: DynamicUiModel;
};

export const FieldCommentModal: React.FC<Props> = ({ isVisible, handleCancel, ui }) => {
    const { projectsStore } = useStore();

    const handleSaveClick = () => {
        ui.saveFieldComment();
    };

    const getCommentContainer = (value: string, userId: string, date: Date) => {
        return (
            <div className="comments-container">
                {projectsStore.allUsers[userId]}
                <div>{Utils.formatDateStringShort(date.toString())}</div>
                <div style={{ marginTop: 10 }}>{value}</div>
                <Divider />
            </div>
        );
    };

    return (
        <Observer>
            {() => (
                <Modal
                    title={ui.commentsDialogFieldName}
                    visible={isVisible}
                    onCancel={handleCancel}
                    closable={false}
                    maskClosable={false}
                    destroyOnClose
                    centered
                    className="alpha-modal"
                    footer={[
                        <Button key="back" onClick={handleCancel} className="light">
                            Cancel
                        </Button>,
                        <Button
                            key="submit"
                            type="primary"
                            onClick={handleSaveClick}
                            disabled={!ui.currentCommentsValue}
                        >
                            Save
                        </Button>
                    ]}
                >
                    <div>
                        <div className="iota-app-field-comments">
                            {ui.fieldsComments?.map(c => getCommentContainer(c.value, c.userId, c.date))}
                        </div>
                        <Input
                            value={ui.currentCommentsValue}
                            placeholder="Enter your reply here..."
                            onChange={e => ui.setCurrentComment(e.target.value)}
                            style={{ marginTop: 20 }}
                        />
                    </div>
                </Modal>
            )}
        </Observer>
    );
};

export default observer(FieldCommentModal);
