import { ImagePreviewSize, ImagePreviewWithSize } from '../../documents/types/types';
import appClient from './AppClient';
export default class ImageService {
    getImagePaths(packageId: string, indexDate: string, fromPage?: number, toPage?: number) {
        const url =
            process.env.REACT_APP_IMAGE_URL +
            `paths/${packageId}/?fromPage=${fromPage}&toPage=${toPage}&indexDate=${indexDate}`;
        return appClient.get<string>(url);
    }

    getImageUrlFromPath(path: string, imageSize: ImagePreviewSize, indexDate: string): string {
        const url =
            process.env.REACT_APP_IMAGE_URL + `preview/?path=${path}&imageSize=${imageSize}&indexDate=${indexDate}`;
        return url;
    }

    // eslint-disable-next-line max-len
    getImagePathsWithSizes(
        packageId: string,
        imageSize: ImagePreviewSize,
        indexDate: string,
        fromPage?: number,
        toPage?: number
    ) {
        const url =
            process.env.REACT_APP_IMAGE_URL +
            `paths/${packageId}/sizes?fromPage=${fromPage}&toPage=${toPage}&indexDate=${indexDate}&imageSize=${imageSize}`;
        return appClient.get<ImagePreviewWithSize[]>(url);
    }

    getImagePreview(imageName: string, imageSize: ImagePreviewSize, indexDate: string) {
        encodeURIComponent(imageName);
        const url =
            process.env.REACT_APP_IMAGE_URL +
            `preview/?path=${imageName}&imageSize=${imageSize}&indexDate=${indexDate}`;
        return appClient.get(url);
    }
}
