import * as React from 'react';
import { AppPermissions } from '../Permissions';
import { ReactElement } from 'react';
import { AuthConsumer } from '../AuthContext';

export const hasPermission = (permissions: string[], action: AppPermissions | AppPermissions[]) => {
    if (!permissions || !permissions.length) {
        return false;
    } else if (typeof action === 'string' && permissions.includes(action)) {
        return true;
    } else if (Array.isArray(action) && action.some(a => permissions.includes(a))) {
        return true;
    }

    return false;
};

export const hasObjectPermission = (
    objectPermission: { [id: string]: string[] },
    id: string | undefined,
    action: AppPermissions | AppPermissions[]
) => {
    if (!objectPermission || !id || !objectPermission[id]) {
        return false;
    } else if (typeof action === 'string' && objectPermission[id].includes(action)) {
        return true;
    } else if (Array.isArray(action) && action.some(a => objectPermission[id].includes(a))) {
        return true;
    }

    return false;
};

type Props = {
    permissionClaim: AppPermissions | AppPermissions[];
    yes?: () => ReactElement;
    no?: () => ReactElement;
    entityId?: string;
};

export const HasPermission: React.FC<Props> = props => {
    return (
        <AuthConsumer>
            {({ permissions, objectPermissions }) => (
                <>
                    {hasPermission(permissions, props.permissionClaim) ||
                    hasObjectPermission(objectPermissions, props.entityId, props.permissionClaim) ||
                    process.env.NODE_ENV === 'test'
                        ? (props.yes && props.yes()) || props.children
                        : props.no
                          ? props.no()
                          : null}
                </>
            )}
        </AuthConsumer>
    );
};
