import * as React from 'react';
import './PanelSwitch.less';

interface Props {
    options: { id: string; label: string }[];
    onClick: (id: string) => void;
}

const PanelSwitch: React.FC<Props> = ({ options, onClick }: Props) => (
    <div className="panel-switch">
        {options.map(o => (
            <span key={o.id} className="panel-switch-option" onClick={() => onClick(o.id)}>
                {o.label}
            </span>
        ))}
    </div>
);

export default PanelSwitch;
