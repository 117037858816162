import * as React from 'react';
import { observer } from 'mobx-react-lite';
import Select, { SelectProps } from 'antd/lib/select';
import { Utils } from '../misc/Utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SelectWithTextSelection: React.FC<SelectProps<any>> = observer(props => {
    const sel = window.getSelection();
    const handleOnMouseDown = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        Utils.handleDropdownItemSelection(e, sel!);
    };

    const handleVisibleChange = (isOpen: boolean) => {
        if (!isOpen) {
            sel!.removeAllRanges();
        }
    };

    return (
        <Select {...props} onMouseDown={handleOnMouseDown} onDropdownVisibleChange={handleVisibleChange}>
            {props.children}
        </Select>
    );
});
