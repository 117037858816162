import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Form } from 'antd';
import { SectionsTabModel } from '../../models';
import { PanelSwitch } from '../../components';
import { LayoutRendererProps } from '../../componentTypes';
import { useLayoutRendererStyle } from '../../hooks';
import SectionRenderer from './SectionRenderer';
import './SectionsLayoutRenderer.less';

interface Props extends LayoutRendererProps {
    tab: SectionsTabModel;
}

const SectionsLayoutRenderer: React.FC<Props> = (props: Props) => {
    const { tab, formKey, form, onValuesChange, getComponentRows } = props;

    const rendererRef = React.useRef<HTMLDivElement>(null);

    const style = useLayoutRendererStyle(tab.id, rendererRef);

    const sectionRefs = React.useRef<Map<string, React.RefObject<HTMLDivElement>>>(new Map());

    const addSectionRef = React.useCallback((sectionId: string, sectionRef: React.RefObject<HTMLDivElement>) => {
        sectionRefs.current.set(sectionId, sectionRef);
    }, []);

    const onPanelSwitchClick = (sectionId: string) => {
        const sectionRef = sectionRefs.current.get(sectionId);

        if (sectionRef && sectionRef.current) {
            sectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <>
            <PanelSwitch
                options={tab.sections.map(section => ({ id: section.id, label: section.name }))}
                onClick={onPanelSwitchClick}
            />

            <div ref={rendererRef} className="sections-layout-renderer-container custom-scroll" style={style}>
                <Form id={tab.id} key={formKey} form={form} onValuesChange={onValuesChange}>
                    <div className="sections-layout-content">
                        {tab.sections.map(section => (
                            <SectionRenderer
                                key={section.id}
                                tab={tab}
                                section={section}
                                getComponentRows={getComponentRows}
                                addSectionRef={addSectionRef}
                            />
                        ))}
                    </div>
                </Form>
            </div>
        </>
    );
};

export default observer(SectionsLayoutRenderer);
