/* eslint-disable @typescript-eslint/member-ordering */
import { action, makeObservable } from 'mobx';
import { TabModel } from '../types/TabModel';
import { DynamicUiModel } from './DynamicUiModel';
import TabsStore from './TabsStore';

export default class MultiDocumentTabVisualStore {
    constructor(private tabsStore: TabsStore) {
        makeObservable(this, {
            openTabWithLayout: action.bound,
            closePreviousTab: action.bound
        });
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    openTabWithLayout = async (ui: DynamicUiModel, tab: TabModel, packageName?: string) => {
        let docTabMeta = { ...tab.metadata };
        docTabMeta.dynamicUi = ui.uiModel;
        docTabMeta.packageSetId = undefined;
        docTabMeta.packageId = ui.layoutConfiguration.activeLayoutId;
        docTabMeta.parentTabId = tab.id;
        docTabMeta.packageName = packageName ?? tab.metadata.packageName;
        await this.tabsStore.addTab({
            id: `${tab.id}-${ui.layoutConfiguration.activeLayoutId}`,
            title: packageName ?? tab.metadata.packageName,
            isSelected: true,
            type: 'document',
            metadata: docTabMeta
        });
    };

    closePreviousTab = (tab: TabModel) => {
        const previouslyOpenTab = this.tabsStore.tabs.find(t => t.metadata && t.metadata.parentTabId === tab.id);
        if (previouslyOpenTab) {
            this.tabsStore.removeTab(this.tabsStore.tabs.indexOf(previouslyOpenTab));
        }
    };
}
