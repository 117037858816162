import { ApplicationDefinitionsService } from '../services';
import { action, observable, runInAction, computed, makeObservable } from 'mobx';
import { ApplicationDefinition, ApplicationDefinitionGroup } from '../services/types';

export default class ApplicationDefinitionsStore {
    projectApplications: ApplicationDefinition[] = [];

    projectApplicationGroups: ApplicationDefinitionGroup[] = [];

    applicationNamesDictionary: { [id: string]: ApplicationDefinition } = {};

    loading: boolean = false;

    get hasApps() {
        return !!this.projectApplications && !!this.projectApplications.length;
    }

    constructor(public service: ApplicationDefinitionsService) {
        makeObservable(this, {
            projectApplications: observable,
            projectApplicationGroups: observable,
            applicationNamesDictionary: observable,
            loading: observable,
            hasApps: computed,
            loadApplicationsForProject: action.bound
        });
    }

    clearApplications() {
        runInAction(() => {
            this.projectApplications = [];
            this.projectApplicationGroups = [];
        });
    }

    async loadApplicationsForProject(projectId: string) {
        if (!projectId) {
            return this.clearApplications();
        }

        runInAction(() => {
            this.loading = true;
        });
        const appRes = await this.service.getApplicationDefinitionsForProject(projectId, 'Published');
        const groupRes = await this.service.getApplicationDefinitionGroupsForProject(projectId);
        runInAction(() => {
            this.projectApplications = appRes;
            this.projectApplicationGroups = groupRes;
            this.loading = false;

            this.projectApplications
                .filter(a => a.state === 'Published')
                .forEach(a => {
                    this.applicationNamesDictionary[a.id] = a;
                });
        });
    }
}
