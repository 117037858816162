import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Route, Navigate } from 'react-router';

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
    component: React.ComponentClass | React.FunctionComponent;
}

const ProtectedRoute: React.FC<Props> = ({ component: Component, projectsRootUI, ...props }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let comp: any = () => <Navigate to={'Projects'} />;
    if (projectsRootUI!.currentProject) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        comp = (p: any) => <Component {...p} />;
    }

    return <Route {...props} element={comp} />;
};

export default observer(ProtectedRoute);
