import * as React from 'react';
import MaskInput, { IInputProps } from 'react-maskinput/lib';
interface ExtendedInputProps extends IInputProps {
    className?: string;
}
export class ExtendedMaskedInput extends React.Component<ExtendedInputProps, string> {
    render() {
        return <MaskInput {...this.props} />;
    }
}
