import * as React from 'react';
import { SearchVisualStore } from '../../stores';
import { SearchResultModel, SearchResultPersonModelData } from '../../types';
import { observer } from 'mobx-react-lite';
import Avatar from 'react-avatar';

type Props = {
    store: SearchVisualStore;
    result: SearchResultModel;
    style?: React.CSSProperties;
};

const PeopleSearchResult: React.FC<Props> = ({ result, style }) => {
    if (result.type !== 'PEOPLE') {
        return null;
    }

    const item = result.data as SearchResultPersonModelData;

    const handleLinkClick = () => {
        if (result.url) {
            window.open(result.url);
        }
    };

    const handleEmailClick = (email: string) => {
        // TODO: will we do anything?
        console.log(email);
    };

    const generateJobTitle = () => {
        const job = item.jobTitle ? item.jobTitle : 'Works';
        const department = item.department ? `in ${item.department}` : '';
        const company = item.companyName ? `in ${item.companyName}` : '';
        const officeLocation = item.officeLocation ? `, ${item.officeLocation}` : '';

        if ((department + company + officeLocation).trim() === '' && !item.jobTitle) {
            return null;
        } else {
            return `${job} ${department} ${company} ${officeLocation}`;
        }
    };

    return (
        <div style={{ cursor: 'default', ...style }} key={item.id} className={'ant-list-item project-list-item'}>
            <div>
                <Avatar round name={item.displayName} size="45" style={{ marginRight: 10 }} />
            </div>
            <div>
                <div>
                    <span
                        style={{
                            fontSize: 13,
                            cursor: 'pointer',
                            color: '#2750b7',
                            textDecoration: 'underline'
                        }}
                        onClick={handleLinkClick}
                    >
                        {item.displayName}
                    </span>
                </div>
                <div className="additional-info">
                    <div style={{ margin: '3px 0' }}>
                        {item.emails &&
                            item.emails.map((e, i) => (
                                <span
                                    key={`${item.id}-email-${i}`}
                                    style={{ borderRadius: 4 }}
                                    className={'project-tag selectable'}
                                    onClick={() => handleEmailClick(e)}
                                >
                                    {e}
                                </span>
                            ))}
                    </div>
                    <div className="search-blocks-preview">
                        {item.birthday && <div>Birthday: {item.birthday}</div>}
                        <div>{generateJobTitle()}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(PeopleSearchResult);
