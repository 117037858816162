import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { MultiDocumentTabRenderer } from '.';
import { SessionService } from '../services';
import { MultiDocumentTabStore, useStore, WorkflowInstanceStore } from '../stores';
import { TabModel } from '../types/TabModel';

const MultiDocumentTabsContent: React.FC = () => {
    const { tabsStore, iotaSessionStore, multiDocumentTabVisualStore, userProfileStore } = useStore();

    const getTabStyle = (isSelected: boolean): React.CSSProperties => {
        if (isSelected) {
            return {
                display: 'block'
            };
        } else {
            return {
                display: 'none'
            };
        }
    };

    const getMultitabDocStoreForTab = (tab: TabModel) => {
        if (!tabsStore.multiDocumentTabStores[tab.id]) {
            const service = new SessionService();
            tabsStore.workflowInstances[tab.id] = new WorkflowInstanceStore(
                service,
                iotaSessionStore,
                userProfileStore
            );
            tabsStore.multiDocumentTabStores[tab.id] = new MultiDocumentTabStore(
                tab,
                service,
                tabsStore.workflowInstances[tab.id],
                iotaSessionStore
            );
        }

        return tabsStore.multiDocumentTabStores[tab.id];
    };

    return (
        <div>
            {tabsStore.multidocumentTabs &&
                tabsStore.multidocumentTabs.map(t => (
                    <div
                        id={`tab-content-${t.id}`}
                        key={`tab-content-${t.id}`}
                        style={getTabStyle(!!tabsStore!.selectedTab && tabsStore!.selectedTab.id === t.id)}
                    >
                        <MultiDocumentTabRenderer
                            tab={t}
                            tabsStore={tabsStore}
                            multiDocStore={getMultitabDocStoreForTab(t)}
                            multiDocVisualStore={multiDocumentTabVisualStore}
                        />
                    </div>
                ))}
        </div>
    );
};

export default observer(MultiDocumentTabsContent);
