import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { last } from 'lodash';
import { ApplicationDefinitionsStore, ProjectsStore } from '../../common/stores';
import { PackageChanges, PackageStateResult } from '../../common/services/types';
import { AskAlphaQuestionModel, AskAlphaErrorModel, AskAlphaSettingsModel } from '../models';
import { InstructWorkflowDto, QuestionResponseDto } from '../types';
import { Utils } from '../../common/misc/Utils';
import { DocumentVisualStore } from '../../documents/stores';
import AskAlphaService from '../services/AskAlphaService';
import AskAlphaPackagesStore from './AskAlphaPackagesStore';
import AskAlphaDocumentHelper from '../misc/AskAlphaDocumentHelper';
import AskAlphaHistoryService from '../services/AskAlphaHistoryService';

interface UiBehaviour {
    scrollIntoViewImmediately: boolean;
}

export default class AskAlphaVisualStore {
    selectedPackageId: string | undefined = undefined;

    questions: AskAlphaQuestionModel[] = [];

    errors: AskAlphaErrorModel[] = [];

    instructWorkflows: InstructWorkflowDto[] = [];

    settingsVisible: boolean = false;

    instructWorkflowsLoading: boolean = false;

    lastSentMessage: string = '';

    uiBehaviour: UiBehaviour = { scrollIntoViewImmediately: false };

    get selectedPackage() {
        if (!this.selectedPackageId) {
            return undefined;
        }

        return this.packagesStore.autocompletePackages.find(pkg => pkg.id === this.selectedPackageId);
    }

    get uploadAction() {
        if (!this.settings.values.projectId) {
            return undefined;
        }

        return `${process.env.REACT_APP_MANAGE_URL}projects/${this.settings.values.projectId}/iota/upload`;
    }

    get readyPackages() {
        return this.packagesStore.autocompletePackages.filter(pkg => pkg.state === PackageStateResult.Ready);
    }

    get packagesImportInProcess() {
        return this.packagesStore.autocompletePackages
            .filter(pkg => pkg.state !== PackageStateResult.Broken)
            .some(pkg => pkg.state !== PackageStateResult.Ready);
    }

    get isBusy() {
        return this.questions.some(q => q.processing);
    }

    get lastQuestion() {
        return last(this.questions);
    }

    get lastQuestionWithAnswer() {
        const questionsWithAnswer = this.questions.filter(
            (q): q is AskAlphaQuestionModel & { response: QuestionResponseDto } => q.hasAnswer
        );

        return last(questionsWithAnswer);
    }

    get selectedInstructWorkflow() {
        return this.instructWorkflows.find(f => f.id === this.settings.values.instructWorkflowId);
    }

    get displayItems() {
        return [...this.questions, ...this.errors].sort((a, b) => Utils.safeDateCompare(a.createDate, b.createDate));
    }

    get lastDisplayItem() {
        return last(this.displayItems);
    }

    readonly sessionId = 'ask-alpha-session';

    readonly settings = new AskAlphaSettingsModel();

    private readonly historyService: AskAlphaHistoryService;

    constructor(
        private readonly projectStore: ProjectsStore,
        private readonly documentStore: DocumentVisualStore,
        private readonly packagesStore: AskAlphaPackagesStore,
        private readonly applicationDefinitionsStore: ApplicationDefinitionsStore,
        private readonly service: AskAlphaService
    ) {
        makeObservable(this, {
            questions: observable,
            errors: observable,
            settingsVisible: observable,
            instructWorkflows: observable,
            instructWorkflowsLoading: observable,
            selectedPackageId: observable,
            lastSentMessage: observable,
            uiBehaviour: observable,
            packagesImportInProcess: computed,
            readyPackages: computed,
            selectedPackage: computed,
            uploadAction: computed,
            isBusy: computed,
            lastQuestion: computed,
            displayItems: computed,
            lastDisplayItem: computed,
            selectedInstructWorkflow: computed,
            changePackage: action.bound,
            setSelectedPackageId: action.bound,
            handlePackageChanges: action.bound,
            setSettingsVisible: action.bound,
            setInstructWorflowsLoading: action.bound,
            setInstructWorkflows: action.bound,
            setErrors: action.bound,
            addError: action.bound,
            askQuestion: action.bound,
            setLastSentMessage: action.bound,
            changeUiBehaviour: action.bound
        });

        this.packagesStore.setProjectIds(this.settings.values.projectId ? [this.settings.values.projectId] : []);

        this.projectStore.packageChanges.subscribe(this.handlePackageChanges);

        this.historyService = new AskAlphaHistoryService(this);

        const lastSentMessage = this.historyService.getLastSentMessage();

        if (lastSentMessage) {
            this.setLastSentMessage(lastSentMessage);
        }

        reaction(
            () => this.projectStore.currentProjectId,
            currentProjectId => {
                // Set default project if nothing was set before
                if (!this.settings.values.projectId && currentProjectId) {
                    this.settings.updateProjectId(currentProjectId);
                    this.packagesStore.setProjectIds([currentProjectId]);
                }

                if (!this.projectStore.projects.length) {
                    return;
                }

                const projectMissing = !this.projectStore.projects.some(p => p.id === this.settings.values.projectId);

                // Project was probably deleted, or is not accessible anymore, reset settings to default
                if (projectMissing && this.projectStore.currentProjectId) {
                    this.settings.resetApplicationDefinition();
                    this.settings.resetInstructWorkflow();

                    this.settings.updateProjectId(this.projectStore.currentProjectId);
                    this.packagesStore.setProjectIds([this.projectStore.currentProjectId]);
                }
            }
        );

        reaction(
            () => this.projectStore.projects,
            () => {
                this.packagesStore.searchAutocompletePackages();
            }
        );

        reaction(
            () => this.settings.values.projectId,
            projectId => {
                if (!projectId) {
                    return;
                }

                this.changePackage(undefined);

                this.packagesStore.setProjectIds([projectId]);
                this.packagesStore.setPackageSearchTerm('');
                this.packagesStore.searchAutocompletePackages();

                this.applicationDefinitionsStore.clearApplications();
                this.applicationDefinitionsStore.loadApplicationsForProject(projectId);

                this.getInstructWorkflows();
            }
        );

        reaction(
            () => this.lastQuestionWithAnswer,
            () => this.updateDocumentBlocks()
        );

        reaction(
            () => this.documentStore.pageWidth,
            () => this.updateDocumentBlocks()
        );
    }

    changeUiBehaviour(uiBehaviour: UiBehaviour) {
        this.uiBehaviour = uiBehaviour;
    }

    changePackage(pkgId: string | undefined) {
        if (pkgId === this.selectedPackageId) {
            return;
        }

        this.setSelectedPackageId(pkgId);
        this.clearChat();

        if (!pkgId) {
            return;
        }

        this.changeUiBehaviour({ scrollIntoViewImmediately: true });

        const questions = this.historyService
            .getPackageQuestions(pkgId)
            .map(data => new AskAlphaQuestionModel(this, this.service, data));

        this.setQuestions(questions);
    }

    setSelectedPackageId(pkgId: string | undefined) {
        this.selectedPackageId = pkgId;
    }

    handlePackageChanges(changes: PackageChanges) {
        if (!this.selectedPackageId && !this.packagesImportInProcess && changes.state === PackageStateResult.Ready) {
            this.changePackage(changes.id);
            this.updateSearch(changes.fileName);
        }
    }

    updateSearch(searchTerm: string) {
        this.packagesStore.setPackageSearchTerm(searchTerm);
        this.packagesStore.searchAutocompletePackages();
    }

    setSettingsVisible(settingsVisible: boolean) {
        this.settingsVisible = settingsVisible;
    }

    setInstructWorflowsLoading(instructWorkflowsLoading: boolean) {
        this.instructWorkflowsLoading = instructWorkflowsLoading;
    }

    setQuestions(questions: AskAlphaQuestionModel[]) {
        this.questions = questions;
    }

    setInstructWorkflows(instructWorkflows: InstructWorkflowDto[]) {
        this.instructWorkflows = instructWorkflows;
    }

    setErrors(errors: AskAlphaErrorModel[]) {
        this.errors = errors;
    }

    addError(message: string) {
        this.errors.push(new AskAlphaErrorModel(message));
    }

    setLastSentMessage(lastSentMessage: string) {
        this.lastSentMessage = lastSentMessage;
        this.historyService.updateLastSentMessage(this.lastSentMessage);
    }

    async askQuestion(message: string) {
        if (!this.selectedPackage) {
            return;
        }

        if (this.uiBehaviour.scrollIntoViewImmediately) {
            this.changeUiBehaviour({ scrollIntoViewImmediately: false });
        }

        this.setLastSentMessage(message);

        const lastQuestionNodeIds =
            this.settings.values.useContext && this.lastQuestion ? this.lastQuestion.nodeIds : [];

        const question = new AskAlphaQuestionModel(this, this.service, { message, packageId: this.selectedPackage.id });

        this.questions.push(question);

        await question.ask(lastQuestionNodeIds);

        this.scrollAndHighlightFirstBlock(question);

        this.historyService.addPackageQuestion(question);
    }

    async getInstructWorkflows() {
        if (!this.settings.values.projectId) {
            return;
        }

        try {
            this.setInstructWorflowsLoading(true);

            const instructWorkflows = await this.service.getInstructWorkflows(this.settings.values.projectId);

            this.setInstructWorkflows(instructWorkflows);

            if (this.settings.values.instructWorkflowId && !this.selectedInstructWorkflow) {
                this.settings.resetInstructWorkflow();
            }
        } finally {
            this.setInstructWorflowsLoading(false);
        }
    }

    clearChat(clearLocalStorage?: boolean) {
        this.setQuestions([]);
        this.setErrors([]);
        this.documentStore.highlightBlock(undefined, undefined);
        this.documentStore.setDocumentBlocks([]);

        if (clearLocalStorage && this.selectedPackageId) {
            this.historyService.removePackageQuestions(this.selectedPackageId);
        }
    }

    clearAllChats() {
        this.clearChat();
        this.historyService.removeAllPackagesQuestions();
    }

    private scrollAndHighlightFirstBlock(question: AskAlphaQuestionModel) {
        if (question.response && question.response.packageFields.length) {
            const firstPackageField = question.response.packageFields[0];
            this.documentStore.scrollAndHighlightBlock(firstPackageField.field.id);
        }
    }

    private updateDocumentBlocks() {
        if (!this.lastQuestionWithAnswer) {
            return;
        }

        const documentBlocks = AskAlphaDocumentHelper.getDocumentBlocks(
            this.lastQuestionWithAnswer.response.packageFields,
            this.documentStore.pageWidth,
            this.selectedPackageId
        );

        this.documentStore.setDocumentBlocks(documentBlocks);
    }
}
