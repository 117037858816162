import * as React from 'react';
import { Utils } from '../../../common/misc/Utils';
import { Form, Input } from 'antd';
import { FormInputFieldData } from '../../../common/services/types';
import { FormInstance } from 'antd/lib/form';
import { Subject, Subscription } from 'rxjs';

type Props = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    inputParams: any;
    form: FormInstance;
    highlightInputSubject?: Subject<string>;
    onHighlightBlock: (field: FormInputFieldData, inputId: string) => void;
};

export const SimpleContainerChildInput: React.FC<Props> = ({
    inputParams,
    highlightInputSubject,
    onHighlightBlock
}) => {
    const [isHighlighted, setIsHighlighted] = React.useState(false);

    let sub: React.MutableRefObject<Subscription | undefined> = React.useRef();

    React.useEffect(() => {
        if (highlightInputSubject) {
            sub.current = highlightInputSubject.subscribe(id => {
                if (id === inputParams.id) {
                    setIsHighlighted(true);
                } else {
                    setIsHighlighted(false);
                }
            });
        }

        return () => {
            if (sub.current) {
                sub.current.unsubscribe();
            }
        };
    }, [highlightInputSubject, inputParams.id]);

    const handleFocus = () => {
        const fieldData = Utils.extractFieldData(inputParams);
        if (fieldData) {
            onHighlightBlock(fieldData, inputParams.id);
        }
    };

    return (
        <Form.Item label={null} colon={false} labelCol={{ span: 24 }} name={inputParams.id}>
            <Input
                autoComplete="off"
                onFocus={handleFocus}
                className={isHighlighted ? ' iota-contract-shadow' : ''}
                style={Utils.buildDynamicInputStyles(inputParams)}
                disabled={inputParams.behavior && inputParams.behavior.readonly}
            />
        </Form.Item>
    );
};
