/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/member-ordering */
import { ProjectsService } from '../services';
import {
    ProjectResult,
    PackageListItemModel,
    PackageStateResult,
    PackageChanges,
    PackageSetResult,
    PackageListItemType,
    PackageResult,
    PackageSetType
} from '../services/types';
import { types } from '../services';
import { action, observable, runInAction, computed, makeObservable, reaction } from 'mobx';
import LocalStorageWorker from '../misc/StorageHelper';
import PushClient from '../services/ManagePushClient';
import { Observable, Subject } from 'rxjs';
import { ApplicationSessionChanges } from '../../sessions/types';
import { message } from 'antd';
import { PackageUpdateModel } from '../../analysis/types';
import { Constants } from '../misc/Constants';
import UserProfileStore from './UserProfileStore';

export default class ProjectsStore {
    projectsNames: { value: string; text: string }[];

    projectsColors: { [id: string]: string | undefined } = {};

    projects: ProjectResult[] = [];

    allUsers: { [id: string]: string } = {};

    project: ProjectResult | null;

    projectIsSelected: boolean = false;

    selectedPackageIds: string[] = [];

    stickedItemsPackageIds: string[] = [];

    isLoading: boolean = false;

    localStorageHelper: LocalStorageWorker;

    analyzeInProgress: boolean = false;

    docUploadAction: string = '';

    folderUploadAction: string = '';

    currentProjectId: string | undefined;

    packageListItems: PackageListItemModel[] = [];

    packageSorting: string = 'Date';

    packageSortingDirection: string = 'DESC';

    packageSearchTerm: string = '';

    packagesPage: number = 0;

    totalPackageCount: number = 0;

    packagesLoading: boolean = false;

    packagesForAutocomplete: PackageResult[] = [];

    isPushServiceConnected: boolean = true;

    sendingPackage: boolean = false;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    loadProjectsPromise: Promise<any>;

    sessionChanges: Observable<ApplicationSessionChanges | null>;

    packageChanges: Observable<PackageChanges>;

    packageSetChanges: Observable<PackageChanges>;

    packageSelectionSubject: Subject<string>;

    get selectedPackageId() {
        return this.selectedPackageIds && this.selectedPackageIds.length > 0
            ? this.selectedPackageIds[this.selectedPackageIds.length - 1]
            : undefined;
    }

    get packageCount() {
        return this.packageListItems.filter(p => p.type === types.PackageListItemType.Package).length;
    }

    get selectedPackageState() {
        if (this.selectedPackageId) {
            const pkg = this.packageListItems.find(p => p.id === this.selectedPackageId);
            return pkg ? pkg.state : '';
        } else {
            return '';
        }
    }

    get selectedPackageName() {
        if (this.selectedPackageId) {
            const pkgs = this.selectedPackageIds.map(x => this.packageListItems.find(p => p.id === x));
            return pkgs.length ? pkgs.map(x => x!.fileName).join('name-separator') : '';
        } else {
            return '';
        }
    }

    get selectedPackage() {
        if (this.selectedPackageId) {
            const pkg = this.packageListItems.find(p => p.id === this.selectedPackageId);
            return pkg;
        } else {
            return undefined;
        }
    }

    get selectedPackageProject() {
        if (this.selectedPackageId) {
            const pkg = this.packageListItems.find(p => p.id === this.selectedPackageId);
            return pkg ? pkg.projectId : '';
        } else {
            return '';
        }
    }

    get projectIds() {
        return this.projects.map(p => p.id);
    }

    get packagesOrderBy() {
        return {
            field: this.packageSorting === 'Name' ? 'fileName' : 'uploadedTime',
            direction: this.packageSortingDirection
        };
    }

    get currentUserId() {
        return this.userProfileStore.currentUserProfileInfo.userId;
    }

    get hasAccessToAllEntities() {
        return this.userProfileStore.hasAccessToAllEntities;
    }

    constructor(
        public service: ProjectsService,
        localStorageHelper: LocalStorageWorker,
        private userProfileStore: UserProfileStore
    ) {
        makeObservable<ProjectsStore, 'updatePackageSetState' | 'updatePackageState' | 'loadAllUsers'>(this, {
            projects: observable,
            allUsers: observable,
            projectIsSelected: observable,
            selectedPackageIds: observable,
            stickedItemsPackageIds: observable,
            isLoading: observable,
            analyzeInProgress: observable,
            docUploadAction: observable,
            folderUploadAction: observable,
            currentProjectId: observable,
            packageListItems: observable,
            packageSorting: observable,
            packageSortingDirection: observable,
            packageSearchTerm: observable,
            isPushServiceConnected: observable,
            sendingPackage: observable,
            packagesPage: observable,
            totalPackageCount: observable,
            packagesLoading: observable,
            packagesForAutocomplete: observable,
            currentUserId: computed,
            hasAccessToAllEntities: computed,
            selectedPackageId: computed,
            selectedPackageState: computed,
            selectedPackageName: computed,
            selectedPackage: computed,
            selectedPackageProject: computed,
            projectIds: computed,
            packageCount: computed,
            packagesOrderBy: computed,
            uploadPackage: action.bound,
            uploadPackageSet: action.bound,
            setPackageSearchTerm: action.bound,
            togglePackageSorting: action.bound,
            loadProjects: action,
            updatePackageInList: action.bound,
            setCurrentProject: action,
            setSelectedPackageId: action,
            setStickedPackageItem: action,
            removeStickedPackageItem: action,
            removeStickyPackageItems: action,
            clearPackageSelection: action.bound,
            handleDownload: action,
            handleSendViaEmail: action,
            getPackageIndexDate: action.bound,
            getPackagesFromSet: action.bound,
            getProjectNameById: action.bound,
            updatePackageSetState: action.bound,
            updatePackageState: action.bound,
            loadAllUsers: action.bound,
            searchPackages: action.bound,
            setPackagesPage: action.bound,
            setPackagesLoading: action.bound,
            setPackageListItems: action.bound
        });

        this.packageSelectionSubject = new Subject();
        this.loadProjectsPromise = this.loadProjects();
        this.localStorageHelper = localStorageHelper;
        PushClient.ConnectionStatusSubject.subscribe(connected => {
            runInAction(() => (this.isPushServiceConnected = connected));
        });

        const pushClient = new PushClient();
        const sessionsObs = pushClient.createSessionStateListener().publish();

        this.sessionChanges = sessionsObs.map(s => {
            if (!this.hasAccessToAllEntities && s.userId !== this.currentUserId) {
                return null;
            }

            return {
                id: s.id,
                state: s.state,
                error: s.error,
                packageId: s.packageId,
                packageSetId: s.packageSetId,
                projectId: s.projectId,
                runtimeSessionId: s.runtimeSessionId,
                created: s.created,
                updated: s.updated,
                applicationDefinitionId: s.applicationDefinitionId,
                applicationExtension: s.applicationExtension,
                applicationSettings: s.applicationSettings,
                iotaApplication: s.iotaApplication,
                isBulkSession: s.isBulkSession,
                userId: s.userId
            };
        });
        sessionsObs.connect();

        const packagesObs = pushClient.createPackageListener().publish();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.packageChanges = packagesObs.map((p: any) => {
            return {
                id: p.id || p._id,
                state: p.state,
                ...p
            };
        });
        packagesObs.connect();

        this.packageChanges.subscribe(p => this.updatePackageState(p));

        const packageSetsObs = pushClient.createPackageSetListener().publish();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.packageSetChanges = packageSetsObs.map((p: any) => {
            return p;
        });
        packageSetsObs.connect();

        this.packageSetChanges.subscribe(ps => this.updatePackageSetState(ps));

        this.loadAllUsers();

        reaction(
            () => this.packageListItems,
            packageListItems => {
                runInAction(() => {
                    this.stickedItemsPackageIds = this.stickedItemsPackageIds.filter(id =>
                        packageListItems.some(i => i.id === id)
                    );
                    this.selectedPackageIds = this.selectedPackageIds.filter(id =>
                        packageListItems.some(i => i.id === id)
                    );
                });
            }
        );
    }

    setPackagesLoading(packagesLoading: boolean) {
        this.packagesLoading = packagesLoading;
    }

    setPackagesPage(packagesPage: number) {
        this.packagesPage = packagesPage;
    }

    setPackageListItems(packageListItems: PackageListItemModel[]) {
        this.packageListItems = packageListItems;
    }

    uploadPackage(formData: FormData, projectId: string) {
        return this.service.uploadPackage(formData, projectId);
    }

    uploadPackageSet(formData: FormData, projectId: string) {
        return this.service.uploadPackageSet(formData, projectId);
    }

    setPackageSearchTerm(packageSearchTerm: string) {
        this.packageSearchTerm = packageSearchTerm;
    }

    togglePackageSorting() {
        if (this.packageSortingDirection === 'ASC') {
            this.packageSortingDirection = 'DESC';
        } else {
            this.packageSorting = this.packageSorting === 'Name' ? 'Date' : 'Name';
            this.packageSortingDirection = 'ASC';
        }
    }

    getProjectNameById(projectId: string) {
        const project = this.projects.find(p => p.id === projectId);
        return project ? project.name : '';
    }

    async loadProjects() {
        this.isLoading = true;
        let res = await this.service.getProjects();
        const proj = await this.service.getDefaultProject();
        if (res && res.length) {
            let envDefaultProjId = process.env.REACT_APP_ALPHA_CLIENT_DEFAULT_PROJECT_ID;
            if (envDefaultProjId && res.findIndex(x => x.id === envDefaultProjId) > -1) {
                res = res.filter(x => x.id === process.env.REACT_APP_ALPHA_CLIENT_DEFAULT_PROJECT_ID);
            } else {
                envDefaultProjId = undefined;
            }

            runInAction(() => (this.projects = res));
            this.projectsNames = res.map(r => {
                return { text: r.name, value: r.id };
            });

            this.projectsColors = res.reduce((acc: { [id: string]: string | undefined }, current) => {
                acc[current.id] = current.color;
                return acc;
            }, {});

            runInAction(async () => {
                this.isLoading = false;

                this.currentProjectId =
                    envDefaultProjId ||
                    (proj && proj.defaultProject) ||
                    (this.projects.length && this.projects[0].id) ||
                    undefined;
                this.docUploadAction = `${process.env.REACT_APP_MANAGE_URL}projects/${this.currentProjectId}/iota/upload`;
                this.folderUploadAction = `${process.env.REACT_APP_MANAGE_URL}projects/${this.currentProjectId}/upload-set`;

                await this.searchPackages();
                await this.searchAutocompletePackages();
            });
        } else {
            runInAction(() => {
                this.isLoading = false;
            });
            message.warning('There are no projects assigned to current user, no contract can be created', 5);
        }

        return Promise.resolve();
    }

    async searchPackages() {
        try {
            this.setPackagesLoading(true);

            const searchTerm = this.packageSearchTerm.trim();

            const request = {
                searchTerm,
                projectIds: this.projectIds,
                page: this.packagesPage,
                orderBy: this.packagesOrderBy,
                fieldsSearch: {
                    packageMatch: {
                        state: { $in: [PackageStateResult.Ready] }
                    },
                    additionalSearchTerm: searchTerm.length
                        ? {
                              key: 'userTags',
                              value: searchTerm
                          }
                        : {}
                },
                uploadedBy: this.hasAccessToAllEntities ? undefined : this.currentUserId
            };

            const response = await this.service.searchPackages(request);

            const packageListItems = this.createPackageListItems(response.packages);

            runInAction(() => {
                packageListItems.forEach(p => this.packageListItems.push(p));
                this.totalPackageCount = response.total;
            });
        } catch (err) {
            console.error(err);
        } finally {
            this.setPackagesLoading(false);
        }
    }

    async searchAutocompletePackages() {
        try {
            const searchTerm = this.packageSearchTerm.trim();

            const request = {
                searchTerm,
                page: 0,
                pageSize: 15,
                projectIds: this.projectIds,
                distinctBy: 'fileName',
                fieldsSearch: {
                    packageMatch: {
                        state: { $in: [PackageStateResult.Ready] }
                    }
                },
                uploadedBy: this.hasAccessToAllEntities ? undefined : this.currentUserId
            };

            const response = await this.service.searchPackagesForAutocomplete(request);

            runInAction(() => {
                this.packagesForAutocomplete = response.packages;
            });
        } catch (err) {
            console.error(err);
        }
    }

    createPackageListItems(packages: PackageResult[]) {
        return packages.reduce<PackageListItemModel[]>((acc, p) => {
            if (p.packageSet && p.packageSet.type === types.PackageSetType.Folder) {
                const packageSet = p.packageSet;
                const inList =
                    this.packageListItems.some(i => i.id === packageSet.id) || acc.some(i => i.id === packageSet.id);

                if (!inList) {
                    acc.push(
                        ...this.createPackageSetListItems(
                            packageSet,
                            packages.filter(i => i.packageSetId === packageSet.id)
                        )
                    );
                }
            } else {
                acc.push({ ...p, type: types.PackageListItemType.Package });
            }

            return acc;
        }, []);
    }

    createPackageSetListItems(packageSet: PackageSetResult, packages: PackageResult[]) {
        const packageListItems: PackageListItemModel[] = [];

        const packageSetItem = {
            fileName: packageSet.name || 'package-set',
            id: packageSet.id,
            projectId: packageSet.projectId,
            fileSizeBytes: 0,
            state: packageSet.state,
            indexDate: '',
            uploadedTime: packageSet.uploadTime || new Date().toString(),
            userTags: [],
            type: types.PackageListItemType.PackageSet,
            packages
        };

        packageListItems.push(packageSetItem);
        packageListItems.push(
            ...packageSetItem.packages.map(p => ({
                ...p,
                packageSetId: packageSet.id,
                type: types.PackageListItemType.Package
            }))
        );

        return packageListItems;
    }

    // pkg unknown to avoid compiler bug
    updatePackageInList(pkg: unknown, pkgUpd: PackageUpdateModel) {
        const packageToUpdate = pkg as PackageListItemModel;
        const packageIndex = this.packageListItems.findIndex(i => i.id === packageToUpdate.id);

        if (packageIndex !== -1) {
            const newPackageListItems = [...this.packageListItems];
            const packageListItem: PackageListItemModel = { ...packageToUpdate, ...pkgUpd };

            newPackageListItems[packageIndex] = packageListItem;

            this.setPackageListItems(newPackageListItems);
        }
    }

    async setCurrentProject(projectId: string) {
        try {
            this.isLoading = true;
            this.project = await this.service.getPackages(projectId);
            this.projectIsSelected = true;
            // runInAction(() => this.setSelectedPackageId(''));
            runInAction(() => (this.isLoading = false));
        } catch (err) {
            console.log(err);
        }
    }

    setSelectedPackageId(id: string, multiselect: boolean = false) {
        if (this.selectedPackageIds.includes(id)) {
            this.selectedPackageIds = this.selectedPackageIds.filter(pkgId => pkgId !== id);
            this.packageListItems = this.packageListItems.slice(); // Needed to re-render package list, otherwise deselct doesn't work properly
        } else if (this.selectedPackageIds.length < Constants.documentSelectionLimit) {
            if (multiselect) {
                this.selectedPackageIds.push(id);
            } else {
                this.selectedPackageIds = [id];
            }
        }

        if (
            this.stickedItemsPackageIds.length &&
            this.selectedPackageIds.some(packageId => !this.stickedItemsPackageIds.includes(packageId))
        ) {
            runInAction(() => {
                this.stickedItemsPackageIds = [...this.selectedPackageIds];
            });
        }
    }
    // TODO: Move to separate UI store
    setStickedPackageItem(id: string) {
        this.stickedItemsPackageIds.push(id);
    }

    removeStickedPackageItem(id?: string) {
        if (!id) {
            this.stickedItemsPackageIds = [];
        } else {
            const index = this.stickedItemsPackageIds.indexOf(id);
            this.stickedItemsPackageIds.splice(index, 1);
        }
    }

    removeStickyPackageItems() {
        this.stickedItemsPackageIds = [];
    }

    clearPackageSelection() {
        this.selectedPackageIds = [];
    }

    getSelectedPackageName(): string {
        return this.project!.packages!.find(p => p.id === this.selectedPackageId)!.fileName;
    }

    handleDownload(fileType: 'pdf' | 'apkg') {
        if (this.selectedPackageId) {
            this.service.handleDownload(this.selectedPackageId, fileType);
        }
    }

    async handleSendViaEmail(packageId: string, emails: string[], messageText?: string) {
        runInAction(() => {
            this.sendingPackage = true;
        });
        try {
            var resp = await this.service.sendPackageViaEmail(packageId, emails, messageText);
            resp.map(() => message.success('Sent message successfully.')).mapErr(() =>
                message.error('Failed to send message.')
            );

            return resp.map(() => 200).unwrapOr(500);
        } catch (err) {
            console.error(err);
            message.error('Failed to send message.');
        } finally {
            runInAction(() => {
                this.sendingPackage = false;
            });
        }

        return 500;
    }

    addItemsToStorage(key: string, value: string) {
        this.localStorageHelper.add(key, value);
    }

    getItemFromStorage(key: string) {
        return this.localStorageHelper.get(key);
    }

    resetNewContractDialogState() {
        this.clearPackageSelection();
        this.removeStickyPackageItems();
        this.setPackageSearchTerm('');
    }

    getPackageIndexDate(packageId: string) {
        let pkg = this.packageListItems.find(p => p.id === packageId);
        if (!pkg) {
            const sets = this.packageListItems.filter(p => p.type === PackageListItemType.PackageSet);
            let setPkg: PackageResult | undefined = undefined;
            for (let set of sets) {
                setPkg = set.packages ? set.packages.find(p => p.id === packageId) : undefined;
                if (setPkg) {
                    break;
                }
            }

            if (setPkg) {
                return setPkg.indexDate;
            }
        }

        return pkg ? pkg.indexDate : '';
    }

    async getPackagesFromSet(packageSetId: string) {
        try {
            const resp = await this.service.getPackageSet(packageSetId);
            return resp;
        } catch (err) {
            console.log(err);
        }
        return undefined;
    }

    private updatePackageSetState(data: unknown) {
        const packageSet = data as PackageSetResult;

        if (!this.projectIds.includes(packageSet.projectId) || packageSet.type === PackageSetType.Conjunction) {
            return;
        }

        const packageListItems = packageSet.packages.length
            ? this.createPackageSetListItems(packageSet, packageSet.packages)
            : [];
        const packageSetIndex = this.packageListItems.findIndex(i => i.id === packageSet.id);

        if (packageSetIndex === -1) {
            this.setPackageListItems([...packageListItems, ...this.packageListItems]);
            return;
        }

        const newPackageListItems = [...this.packageListItems].filter(
            i =>
                (i.type === types.PackageListItemType.PackageSet && i.id !== packageSet.id) ||
                (i.type === types.PackageListItemType.Package && i.packageSetId !== packageSet.id)
        );

        newPackageListItems.splice(packageSetIndex, 0, ...packageListItems);

        this.setPackageListItems(newPackageListItems);
    }

    private updatePackageState(data: unknown) {
        const packageChange = data as PackageChanges;

        if (!this.projectIds.includes(packageChange.projectId)) {
            return;
        }

        const packageListItem: PackageListItemModel = { ...packageChange, type: types.PackageListItemType.Package };
        const packageIndex = this.packageListItems.findIndex(i => i.id === packageListItem.id);

        if (packageIndex === -1) {
            this.setPackageListItems([packageListItem, ...this.packageListItems]);
            return;
        }

        const newPackageListItems = [...this.packageListItems];

        newPackageListItems[packageIndex] = packageListItem;

        this.setPackageListItems(newPackageListItems);

        if (packageListItem.state === PackageStateResult.Ready) {
            this.packageSelectionSubject.next(packageListItem.id);
        }
    }

    private async loadAllUsers() {
        const resp = await this.service.getAllUsers(false);
        resp.map(data => {
            const users = data ?? [];

            const distinctUsers = users.filter(this.distinctUser);
            runInAction(() => {
                for (var user of distinctUsers) {
                    this.allUsers[user.id] = user.firstName ? `${user.firstName} ${user.lastName}` : user.userName;
                }
            });
        }).mapErr(err => console.log(err));
    }

    private distinctUser(
        value: { userName: string; id: string },
        index: number,
        self: Array<{ userName: string; id: string }>
    ): boolean {
        const user = self.find(u => u.id === value.id);
        return self.indexOf(user!) === index;
    }
}
