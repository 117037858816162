import { TabModel } from '../types/TabModel';
import { WorkflowInstanceStore } from '.';
import { action, runInAction, observable, makeObservable } from 'mobx';
import { TabApplicationData } from '../services/types';
import { DynamicUiModel } from './DynamicUiModel';
import * as mobx from 'mobx';
import _ from 'lodash';
export default class DocumentTabStore {
    tab: TabModel;

    selectedPackageId: string;

    openSections: string[] = [];

    dynamicUiLoadRetries: number = 0;

    constructor(
        tab: TabModel,
        private workflowInstance: WorkflowInstanceStore
    ) {
        makeObservable<DocumentTabStore, 'setTab'>(this, {
            tab: observable,
            selectedPackageId: observable,
            openSections: observable,
            setSelectedPackageId: action.bound,
            setOpenSections: action.bound,
            handleTabUiUpdate: action.bound,
            loadTabUi: action.bound,
            setTab: action.bound
        });

        this.setTab(tab);
        this.workflowInstance.uiUpdateSubject.subscribe(this.handleTabUiUpdate);
        if (!this.tab.metadata.dynamicUI) {
            this.loadTabUi();
        }
    }

    setSelectedPackageId(pkgId: string) {
        this.selectedPackageId = pkgId;
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 0);
    }

    setOpenSections(keys: string[]) {
        this.openSections = keys;
    }

    handleTabUiUpdate(ui: DynamicUiModel | undefined) {
        if (!ui) {
            this.tab.metadata.uiIsLoading = true;
        } else {
            this.tab.metadata.dynamicUI = ui;
            const metadata = mobx.toJS(this.tab.metadata);

            metadata.freezeUi = false;
            metadata.dynamicUI = ui;

            if (metadata.uiIsLoading && !ui.inProgress && ui.progress != null) {
                _.delay(() => {
                    metadata.uiIsLoading = ui.inProgress;
                    runInAction(() => {
                        this.tab.metadata = metadata;
                    });
                }, 300);
            } else {
                metadata.uiIsLoading = ui.inProgress;
                this.tab.metadata = metadata;
            }
        }
    }

    async loadTabUi() {
        if (!this.tab.metadata) {
            return;
        }
        if (this.tab.metadata.sessionError && this.tab.metadata.sessionState === 'Aborted') {
            this.tab.metadata.uiIsLoading = false;
            return;
        }

        this.tab.metadata.uiIsLoading = true;
        const dynamicUI = await this.workflowInstance.getSessionUiModel(
            this.tab.metadata.sessionId as string,
            this.tab.metadata.sessionId as string,
            this.tab.metadata.applicationData as TabApplicationData
        );

        if (!dynamicUI && this.dynamicUiLoadRetries < 5 && !this.tab.metadata.sessionError) {
            await this.loadTabUi();
            this.dynamicUiLoadRetries++;
        } else {
            this.dynamicUiLoadRetries = 0;

            runInAction(() => {
                this.tab.metadata.dynamicUI = dynamicUI;
                this.tab.metadata.uiIsLoading = false;
            });
        }
    }

    private setTab(tab: TabModel) {
        this.tab = tab;
    }
}
