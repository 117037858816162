import * as React from 'react';
import { useLocalStore } from 'mobx-react-lite';
import { useStore } from '../../common/stores/context';
import { ApplicationDefinitionsStore, ErrorStore, ProjectsStore, UserProfileStore } from '../../common/stores';
import { ApplicationDefinitionsService, DocumentService, ProjectsService } from '../../common/services';
import { DocumentVisualStore } from '../../documents/stores';
import { context } from '../../analysis/stores';
import { context as sessionContext } from '../../sessions/stores';
import ImageService from '../../common/services/ImageService';
import AskAlphaVisualStore from './AskAlphaVisualStore';
import AskAlphaPackagesStore from './AskAlphaPackagesStore';
import AskAlphaService from '../services/AskAlphaService';
import LocalStorageWorker from '../../common/misc/StorageHelper';

function createAskAlphaStores(errorStore: ErrorStore, userProfileStore: UserProfileStore) {
    const imageService = new ImageService();
    const documentService = new DocumentService();
    const service = new AskAlphaService();
    const projectsService = new ProjectsService();
    const localStorageHelper = new LocalStorageWorker();
    const applicationDefinitionsService = new ApplicationDefinitionsService();

    const askAlphaApplicationDefinitionsStore = new ApplicationDefinitionsStore(applicationDefinitionsService);

    const askAlphaDocumentStore = new DocumentVisualStore(
        askAlphaApplicationDefinitionsStore,
        imageService,
        errorStore,
        documentService
    );

    // TODO: move all package related logic to package store, we need separate package state for two pages, but don't need two project stores
    const askAlphaProjectsStore = new ProjectsStore(projectsService, localStorageHelper, userProfileStore);

    const askAlphaPackagesStore = new AskAlphaPackagesStore(askAlphaProjectsStore, projectsService);

    const askAlphaStore = new AskAlphaVisualStore(
        askAlphaProjectsStore,
        askAlphaDocumentStore,
        askAlphaPackagesStore,
        askAlphaApplicationDefinitionsStore,
        service
    );

    return {
        askAlphaStore,
        askAlphaDocumentStore,
        askAlphaProjectsStore,
        askAlphaPackagesStore,
        askAlphaApplicationDefinitionsStore
    };
}

const storeContext = React.createContext<ReturnType<typeof createAskAlphaStores> | null>(null);

type Props = {
    children: React.ReactNode;
};

const AskAlphaStoresProvider: React.FC<Props> = ({ children }) => {
    const { errorStore, userProfileStore } = useStore();

    const askAlphaStores = useLocalStore(() => createAskAlphaStores(errorStore, userProfileStore));

    return <storeContext.Provider value={askAlphaStores}>{children}</storeContext.Provider>;
};

export const AskAlphaStoresConditionalProvider: React.FC<Props> = ({ children }) => {
    if (process.env.REACT_APP_ENABLE_ASK_ALPHA === 'true') {
        return (
            <sessionContext.SessionsStoreProvider>
                <context.AnalysisStoreProvider>
                    <AskAlphaStoresProvider>{children}</AskAlphaStoresProvider>
                </context.AnalysisStoreProvider>
            </sessionContext.SessionsStoreProvider>
        );
    }

    return <>{children}</>;
};

export const useAskAlphaStores = () => {
    const store = React.useContext(storeContext);

    if (!store) {
        // this is especially useful in TypeScript so you don't need to be checking for null all the time
        throw new Error('You have forgot to use StoreProvider, shame on you.');
    }

    return store;
};
