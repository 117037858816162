// import Keycloak, {} from 'keycloak-js';
// Keycloak is imported in public/index.html via <script>, because webpack can't handle it
// If you want to edit something here, you can uncomment the import above to get intellisense
// Don't forget to comment it back before commiting or trying to run, because it won't work
// @ts-nocheck
import isElectron from 'is-electron';
// import NoRolesError from '../types/CustomErrors';

const keyConfig = require('./keycloak.json');
// const APPLICATION_ID = 'alpha-portal';

class SecurityService {
    // eslint-disable-next-line no-undef
    inst: Keycloak;
    cachedUserInfo: Promise<{}>;

    get token() {
        return this.inst.token!;
    }

    constructor() {
        if (process.env.REACT_APP_AUTH_URL) {
            keyConfig.url = process.env.REACT_APP_AUTH_URL;
            console.log(keyConfig);
            // eslint-disable-next-line no-undef
            this.inst = new Keycloak(keyConfig);
        }
    }

    init(accessToken: string | null, refreshToken: string | null) {
        const prod = process.env.NODE_ENV === 'production';
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let cfg: any = { onLoad: 'login-required', checkLoginIframe: false };

        if (accessToken && refreshToken) {
            cfg.token = accessToken;
            cfg.refreshToken = refreshToken;
        }

        if (prod && isElectron()) {
            cfg = {
                onLoad: 'login-required',
                redirectUri: 'http://alpha.client/index.html',
                checkLoginIframe: false,
                pkceMethod: 'S256'
            };
        }

        return new Promise((res, rej) => {
            const promise = this.inst.init(cfg);
            promise
                .then(auth => {
                    res(auth);
                })
                .catch(err => rej(err));
        });
    }

    loadUserInfo() {
        if (!this.cachedUserInfo) {
            this.cachedUserInfo = this.inst.loadUserInfo();
        }

        return this.cachedUserInfo;
    }

    getOrRefreshToken(): Promise<{ token: string; refreshed: boolean }> {
        if (!this.inst.isTokenExpired) {
            return Promise.resolve({ token: this.inst.token!, refreshed: false });
        }

        return new Promise<{ token: string; refreshed: boolean }>((res, rej) => {
            this.inst
                .updateToken(30)
                .then(refreshed => {
                    if (refreshed) {
                        console.log('Token was updated');
                    }

                    res({ token: this.inst.token!, refreshed: true });
                })
                .catch(err => {
                    console.error('Error during token refresh');
                    rej(err);
                });
        });
    }

    invoke<T>(func: (token: string) => Promise<T>): Promise<T> {
        return new Promise<T>((res, rej) => {
            if (!this.inst.isTokenExpired(30)) {
                func(this.inst.token!)
                    .then(s => res(s))
                    .catch(err => rej(err));
                return;
            }

            console.log('Token is expired');
            this.inst
                .updateToken(30)
                .then(refreshed => {
                    if (refreshed) {
                        console.log('Token was updated');
                    }

                    func(this.inst.token!)
                        .then(s => res(s))
                        .catch(err => rej(err));
                })
                .catch(err => {
                    console.error('Error during token refresh');
                    rej(err);
                });
        });
    }
}

class MockedSecurityService extends SecurityService {
    // eslint-disable-next-line no-undef
    inst: Keycloak;

    constructor() {
        super();
        // eslint-disable-next-line no-undef
        this.inst = {};
    }

    get token() {
        return 'empty';
    }

    init() {
        return Promise.resolve(true);
    }

    getOrRefreshToken(): Promise<{ token: string; refreshed: boolean }> {
        return Promise.resolve({ token: this.token, refreshed: false });
    }

    invoke<T>(func: (token: string) => Promise<T>): Promise<T> {
        return new Promise<T>((res, rej) => {
            func(this.token)
                .then(s => res(s))
                .catch(err => rej(err));
        });
    }
}

let service: SecurityService;
if (process.env.REACT_APP_AUTH_URL) {
    service = new SecurityService();
} else {
    console.debug('Authentication is not enabled');
    service = new MockedSecurityService();
}

export default service;
