/* eslint-disable @typescript-eslint/member-ordering */
import {
    FormInputModels,
    CurrentWidgetValueIds,
    WidgetDataItem,
    WidgetData,
    TabApplicationData,
    ComplexWidgetDataItem,
    WidgetType,
    IotaAppComment
} from '../services/types';
import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import SessionService, { Filter } from '../services/SessionService';
import { WidgetValuesProvider, isStaticValues, WidgetValues } from '../services/WidgetValuesProvider';
import * as _ from 'lodash';
import { UserProfileStore, WorkflowInstanceStore } from '.';

type ParentBasedInputValues = {
    [key: string]: WidgetDataItem[];
};
export class DynamicUiModel {
    currentWidgetValueIds: CurrentWidgetValueIds = {};

    widgetValues: WidgetValues = {};

    currentSession: string;

    applicationData: TabApplicationData;

    parentBasedInputValues: ParentBasedInputValues = {};

    inProgress: boolean;

    comments: IotaAppComment[] = [];

    currentCommentsField: string;

    currentCommentsValue: string;

    validateOnLoad: boolean = false;

    private static makeKey(control: string, source: string): string {
        return `${control}:${source}`;
    }

    constructor(
        public uiModel: FormInputModels,
        public sessionService: SessionService,
        private userProfileStore?: UserProfileStore,
        private workflowInstance?: WorkflowInstanceStore
    ) {
        makeObservable(this, {
            parentBasedInputValues: observable,
            inProgress: observable,
            comments: observable,
            currentCommentsField: observable,
            currentCommentsValue: observable,
            validateOnLoad: observable,
            inputs: computed,
            containerInputs: computed,
            progress: computed,
            fieldsComments: computed,
            setInProgress: action,
            setCurrentCommentsField: action.bound,
            setCurrentComment: action.bound,
            saveFieldComment: action.bound,
            setComments: action,
            setValidateOnLoad: action.bound,
            setCurrentFieldsChange: action.bound
        });
    }

    get inputs() {
        return this.uiModel.inputs;
    }

    get containerInputs() {
        return this.uiModel.containerInputs;
    }

    get sections() {
        return this.uiModel.sections;
    }

    get actions() {
        return this.uiModel.actions;
    }

    get errors() {
        return this.uiModel.errors || [];
    }

    get layoutConfiguration() {
        return this.uiModel.layoutConfiguration;
    }

    get postActions() {
        return this.uiModel.postActions;
    }

    get preSubmitActions() {
        return this.uiModel.preSubmitActions;
    }

    get commentsDialogFieldName() {
        return this.inputs.find(i => i.id === this.currentCommentsField)?.name;
    }

    get progress() {
        const sessionProgressSrc = 'session-progress';
        const values = this.widgetValues[sessionProgressSrc];
        if (values) {
            const val = values[0].value;
            const progressVal = val === 1 && this.inProgress ? 0 : val;
            return progressVal;
        }

        return undefined;
    }

    get fieldsComments() {
        return this.comments.find(c => c.fieldId === this.currentCommentsField)?.comments;
    }

    get additionalInfo() {
        return this.uiModel.additionalInfo;
    }

    setValidateOnLoad(validateOnLoad: boolean) {
        this.validateOnLoad = validateOnLoad;
    }

    setInProgress(inProgress: boolean) {
        this.inProgress = inProgress;
    }

    setApplicationData(applicationData: TabApplicationData) {
        this.applicationData = applicationData;
    }

    setWidgetCurrentValues() {
        const inputs = [
            ..._.flatten((this.uiModel.containerInputs || []).map(x => x.children || [])).map(x => x.input),
            ...this.uiModel.inputs
        ];

        inputs.forEach(input => {
            if (input.source) {
                const controlKey = DynamicUiModel.makeKey(input.id, input.source.id);
                this.currentWidgetValueIds[controlKey] = input.value;
            }
        });
    }

    async setCurrentFieldsChange(changedFields: {}) {
        const id = Object.keys(changedFields)[0];
        let parentValues = [] as string[] | null;
        if (!Array.isArray(changedFields[id])) {
            parentValues!.push(changedFields[id]);
        } else {
            parentValues = changedFields[id];
        }

        parentValues = parentValues ? parentValues.filter(x => x !== null && x !== undefined) : null;

        if (!parentValues!.length) {
            parentValues = null;
        }
        let childInputId = '' as string | null;
        let parentInputId = '';
        if (this.inputs[0].controlType === WidgetType.VisualTable) {
            const inputs = _.flatten(this.inputs.map(x => x.behavior?.inputOverride));
            if (inputs?.length) {
                childInputId = inputs.find(x => x!.parentInputId === id)?.key || null;
            }
            parentInputId = id;
        } else {
            const input = this.inputs.find(i => i.id === id);
            parentInputId = (input && input.id) || '';
            childInputId = this.inputs.find(i => i.parentInputId === parentInputId)?.id || null;
        }

        if (childInputId && parentInputId) {
            const res = await this.sessionService.loadValuesForWidgets(
                this.currentSession,
                childInputId,
                this.applicationData.iotaApplication,
                this.applicationData.appExtension,
                this.applicationData.appSettings,
                0,
                9999,
                undefined,
                parentValues
            );

            res.map(data => {
                const items = data.rows.map(r => ({
                    text: r.items[0].value as string,
                    value: r.items[1].value as string
                }));
                runInAction(() => (this.parentBasedInputValues[parentInputId] = items));
            });

            // Reset source values for input which is child of childInputId
            if (this.inputs.some(i => i.parentInputId === childInputId)) {
                runInAction(() => (this.parentBasedInputValues[childInputId!] = []));
            }
        }
    }

    async getWidgetValues(
        key: string,
        skip: number = 0,
        take: number = 9999,
        filter: Filter | undefined = undefined,
        pageSize: number | undefined = undefined
    ): Promise<WidgetData> {
        const values = this.widgetValues[key];
        if (!isStaticValues(values)) {
            const resp = await values.fetch(skip, take, filter, pageSize);
            return resp;
        } else {
            return {
                total: values.length,
                data: values.slice(skip, skip + take),
                skip: skip
            };
        }
    }

    async getAllWidgetValues(key: string) {
        const values = this.widgetValues[key];
        try {
            if (!isStaticValues(values)) {
                const resp = await values.fetchAll();
                return resp;
            } else {
                return {
                    total: values.length,
                    data: values,
                    skip: 0
                };
            }
        } catch {
            console.error(`Source '${key}' was not found`);
            return { total: 0, data: [], skip: 0 };
        }
    }

    async getCurrentWidgetValue(
        control: string,
        source: string
    ): Promise<WidgetDataItem[] | WidgetDataItem | undefined> {
        const controlKey = DynamicUiModel.makeKey(control, source);

        if (!this.currentWidgetValueIds[controlKey]) {
            return Promise.resolve([]);
        }

        let currentVals: WidgetDataItem[] = [];
        const values = this.widgetValues[source];

        if (this.currentWidgetValueIds[controlKey] instanceof Array) {
            let arr = this.currentWidgetValueIds[controlKey] as string[];

            for (const id of arr) {
                if (!isStaticValues(values)) {
                    const item = await values.fetOne(id);
                    if (item) {
                        currentVals.push(item);
                    }
                } else {
                    currentVals.push(values.find(val => val.value === id)!);
                }
            }
            return currentVals;
        } else {
            if (!isStaticValues(values)) {
                const item = await values.fetOne(this.currentWidgetValueIds[controlKey] as string);
                return item;
            } else {
                return values.filter(val => this.currentWidgetValueIds[controlKey] === val.value)[0];
            }
        }
    }

    async getWidgetArrayValueFromSource(source: string): Promise<ComplexWidgetDataItem[] | undefined> {
        const values = this.widgetValues[source];
        if (!isStaticValues(values)) {
            return undefined;
        } else {
            return values;
        }
    }

    async loadValuesForWidgets(session: string) {
        this.currentSession = session;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let promises = [] as Promise<any>[];
        this.uiModel.sources?.forEach(source => {
            if (source.type === 'linked') {
                this.widgetValues[source.id] = new WidgetValuesProvider(
                    this.sessionService,
                    source.link,
                    session,
                    this.applicationData
                );
            } else {
                this.widgetValues[source.id] = source.data;
            }
        });
        return Promise.all(promises);
    }

    setCurrentCommentsField(fieldId: string) {
        this.currentCommentsField = fieldId;
    }

    setCurrentComment(value: string) {
        this.currentCommentsValue = value;
    }

    async saveFieldComment() {
        await this.sessionService.saveFieldComment(
            this.currentSession,
            this.currentCommentsField,
            this.currentCommentsValue
        );
        const comments = this.comments.find(c => c.fieldId === this.currentCommentsField);
        const newComment = {
            value: this.currentCommentsValue,
            userId: this.userProfileStore!.userProfile.userId,
            date: new Date()
        };
        if (comments) {
            runInAction(() => comments.comments.push(newComment));
        } else {
            runInAction(() => this.comments.push({ fieldId: this.currentCommentsField, comments: [newComment] }));
        }
        this.setCurrentComment('');
    }

    setComments(comments: IotaAppComment[]) {
        this.comments = comments;
    }

    async runValidationAction(runtimeInputs: WidgetValues, actionId: string) {
        if (!this.workflowInstance) {
            this.setValidateOnLoad(false);
            return;
        }

        this.workflowInstance.sessionExtension[this.currentSession] = this.applicationData.appExtension;

        await this.workflowInstance.continueSession(
            runtimeInputs,
            actionId,
            this.currentSession,
            this.applicationData.appExtension
        );
    }
}
