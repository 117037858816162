import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Tooltip } from 'antd';
import { ViewInDocumentButton } from '..';
import { InputBaseModel } from '../../models';
import { DocumentHelper, TypeUtils } from '../../../custom_shared/misc';
import { InputBindingType } from '../../../common/services/types';
import { GearIcon } from '../../../custom_shared/icons';
import './InputActions.less';

type TypeMeta = {
    meta: {
        type: string;
    };
};

const hasTypeMeta = (input: InputBaseModel): input is InputBaseModel & TypeMeta => {
    return !!(
        input.meta &&
        TypeUtils.isString(input.meta.type) &&
        (input.meta.type === InputBindingType.CodeRule.toLowerCase() ||
            input.meta.type === InputBindingType.Constant.toLowerCase())
    );
};

interface Props {
    input: InputBaseModel;
    additionalLeftNode?: React.ReactNode;
}

const InputActions: React.FC<Props> = ({ input, additionalLeftNode }) => {
    const renderViewInDocumentButton = DocumentHelper.hasValidBlockType(input);
    const renderGearIcon = hasTypeMeta(input);

    if (!renderViewInDocumentButton && !renderGearIcon && !additionalLeftNode) {
        return null;
    }

    return (
        <div className="input-actions-container" onClick={e => e.stopPropagation()}>
            {additionalLeftNode ? additionalLeftNode : null}

            {renderGearIcon && (
                <Tooltip
                    title={
                        input.meta.type === InputBindingType.CodeRule.toLowerCase()
                            ? InputBindingType.CodeRule
                            : InputBindingType.Constant
                    }
                >
                    <GearIcon width={17} height={17} />
                </Tooltip>
            )}

            {renderViewInDocumentButton && <ViewInDocumentButton key="view-in-document" input={input} />}
        </div>
    );
};

export default observer(InputActions);
