/* eslint-disable no-unused-vars */
import * as React from 'react';
import { SearchVisualStore } from '../stores';
import { observer } from 'mobx-react-lite';
import { CloseOutlined } from '@ant-design/icons';
import { Card, Spin, Tag, Menu, Dropdown, Col, Row, Button } from 'antd';
import Search from 'antd/lib/input/Search';
import { AutoSizer, List, CellMeasurerCache, ListRowProps, CellMeasurer } from 'react-virtualized';
import SearchResultRenderer from './results/SearchResultRenderer';
import { SearchResultSourceTag } from '../types';

type Props = {
    store: SearchVisualStore;
};

const CognitiveSearch: React.FC<Props> = ({ store }) => {
    const cache = new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 55,
        keyMapper: rowIndex =>
            store.searchResults.results[rowIndex].data.id || store.searchResults.results[rowIndex].data.packageId
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const rowRenderer = ({ index, style, columnIndex, ...rest }: ListRowProps) => {
        if (!store.searchResults || !store.searchResults.results || !store.searchResults.results.length) {
            return null;
        }

        return (
            <CellMeasurer cache={cache} rowIndex={index} columnIndex={0} {...rest}>
                <SearchResultRenderer index={index} style={style} store={store} />
            </CellMeasurer>
        );
    };

    const deepSearchMenu = (t: SearchResultSourceTag) => {
        return (
            <Menu>
                <Menu.Item onClick={() => store.deepSearchByTagValue(t)}>Deep search: {t.values.join(', ')}</Menu.Item>
            </Menu>
        );
    };

    return (
        <Card style={{ border: 'none' }} className="content-card shadow">
            <div>
                <Search
                    data-id="cognitive-search-input"
                    className="alpha-search-input"
                    placeholder="Search by name, tags, etc..."
                    onChange={e => store.setSearchKey(e.target.value)}
                    onSearch={store.search}
                    value={store.searchKey}
                    enterButton
                />
                <div style={{ marginTop: 10 }}>
                    {store.selectedTags.map((t, i) => (
                        <Dropdown
                            key={`cognitive-search-tag-${t.id}-${t.values.join('')}`}
                            overlay={() => deepSearchMenu(t)}
                            trigger={['contextMenu']}
                        >
                            <Tag
                                color={
                                    store.deepSearchTag && store.checkIfTagsAreEqual(t, store.deepSearchTag)
                                        ? '#3b8a00'
                                        : '#273c75'
                                }
                                style={{ cursor: 'pointer' }}
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                onClose={(e: any) => {
                                    e.stopPropagation();
                                    store.selectTag(t);
                                }}
                                closable
                            >
                                {t.id}: {t.values.join(', ')}
                            </Tag>
                        </Dropdown>
                    ))}
                </div>
            </div>
            <div>
                {store.isLoading ? (
                    <div style={{ margin: 10, textAlign: 'center' }}>
                        <Spin size="large" />
                    </div>
                ) : (
                    <Row>
                        <Col span={store.highlightPreview ? 9 : 24}>
                            <div style={{ height: 'calc(100vh - 260px)', width: '100%' }}>
                                <AutoSizer>
                                    {({ height, width }) => (
                                        <List
                                            height={height}
                                            rowCount={store.resultsCount}
                                            rowHeight={cache.rowHeight}
                                            deferredMeasurementCache={cache}
                                            rowRenderer={rowRenderer}
                                            width={width}
                                            overscanRowCount={15}
                                            style={{ outline: 'none', marginTop: 20, paddingRight: 20 }}
                                        />
                                    )}
                                </AutoSizer>
                            </div>
                        </Col>
                        <Col span={store.highlightPreview ? 15 : 0}>
                            {store.highlightPreview && (
                                <div className="search-blocks-preview large">
                                    <Button
                                        onClick={() => store.setHighlightPreview(undefined, undefined)}
                                        type="link"
                                        style={{
                                            position: 'absolute',
                                            top: '10px',
                                            right: '10px'
                                        }}
                                    >
                                        <CloseOutlined />
                                    </Button>
                                    <span dangerouslySetInnerHTML={{ __html: store.highlightPreview }} />
                                </div>
                            )}
                        </Col>
                    </Row>
                )}
            </div>
        </Card>
    );
};

export default observer(CognitiveSearch);
