import { SearchOutlined } from '@ant-design/icons';
import { Form, Modal, Tooltip } from 'antd';
import * as React from 'react';
import { DynamicUiModel } from '../../../common/stores/DynamicUiModel';
import { DynamicControlProps } from './types';
import Registry from '../DynamicControlsRegistry';
import { SessionService } from '../../../common/services';
import { WidgetType } from '../../../common/services/types';

export const DynamicModalForm: React.FC<DynamicControlProps> = ({ inputParams, form, getGearIcon, ui, onAction }) => {
    const [dialogVisible, setDialogVisible] = React.useState<boolean>(false);
    const [formUi, setFormUi] = React.useState<DynamicUiModel | undefined>(undefined);
    const [modalForm] = Form.useForm();
    const [title, setTitle] = React.useState<string>(
        inputParams.behavior?.header ? inputParams.behavior?.header : 'Modal form'
    );

    const initUi = React.useCallback(async () => {
        const override = inputParams.behavior?.inputOverride?.find(i => i.key === inputParams.id);

        if (override && override.modalFormHeader) {
            setTitle(override.modalFormHeader);
        }

        const service = new SessionService();
        const newFormUi = new DynamicUiModel(inputParams.modalForm!, service);
        newFormUi.setApplicationData(ui.applicationData);
        newFormUi.setWidgetCurrentValues();
        await newFormUi.loadValuesForWidgets(`${inputParams.id}-session`);
        setFormUi(newFormUi);
    }, [inputParams.behavior?.inputOverride, inputParams.id, inputParams.modalForm, ui.applicationData]);

    React.useEffect(() => {
        initUi();
    }, [initUi]);

    if (!inputParams.modalForm) {
        return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleValueSelection = (id: string, action: string, value: any) => {
        var obj = {};
        obj[inputParams.id] = value;
        form.setFieldsValue(obj);
        setDialogVisible(false);

        const actionId = inputParams.behavior && inputParams.behavior.onchange;
        if (actionId) {
            if (actionId.value) {
                onAction(inputParams.id, actionId.value, value);
            } else {
                onAction(inputParams.id, actionId, value);
            }
        }
    };

    const renderInputs = () => {
        if (!formUi) {
            return null;
        }

        return (
            // tslint:disable-next-line:triple-equals
            formUi.inputs.map((params, index) => {
                let WidgetComponent = Registry[params.controlType]!;
                return (
                    <WidgetComponent
                        key={index}
                        inputParams={params}
                        onAction={
                            params.controlType === WidgetType.ResultTable && !inputParams.behavior?.readonly
                                ? handleValueSelection
                                : undefined
                        }
                        ui={formUi}
                        form={modalForm}
                        onHighlightBlock={undefined}
                        highlightedInput={undefined}
                        getGearIcon={getGearIcon}
                        onDownload={undefined}
                        parentForm={form}
                    />
                );
            })
        );
    };

    const closeDialog = () => {
        setDialogVisible(false);
    };

    const openDialog = () => {
        setDialogVisible(true);
    };

    const getIcon = () => {
        if (inputParams.behavior?.iconType) {
            switch (inputParams.behavior?.iconType) {
                case 'search':
                default:
                    return (
                        <Tooltip title={title}>
                            <SearchOutlined
                                data-testid="dynamic-modal-form-toggle"
                                className="dynamic-modal-form-toggle"
                                style={{ marginLeft: '0px' }}
                                onClick={openDialog}
                            />
                        </Tooltip>
                    );
            }
        }

        return (
            <Tooltip title={title}>
                <SearchOutlined
                    className="dynamic-modal-form-toggle"
                    style={{ marginLeft: '0px' }}
                    onClick={openDialog}
                />
            </Tooltip>
        );
    };

    return (
        <>
            {getIcon()}
            <Modal
                title={title}
                visible={dialogVisible}
                className="dynamic-modal-form"
                onCancel={closeDialog}
                onOk={closeDialog}
                footer={null}
                width={1000}
                maskClosable={false}
                zIndex={10000}
                data-testid={'dynamic-modal-form'}
            >
                <Form form={modalForm} data-id={`modal-form-${inputParams.id}`}>
                    <div className="leftColumnContainer modal-form">{renderInputs()}</div>
                </Form>
            </Modal>
        </>
    );
};
