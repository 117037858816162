import * as React from 'react';
import { observer, Observer } from 'mobx-react-lite';
import { Progress, Modal, Row, Col, Button, Spin } from 'antd';
import { HomeVisualStore, PreviewsVisualStore, AnalyzeVisualStore } from '../stores';
import ImageWithFallback from '../../common/components/ImageWithFallback';
import errorImg from '../../common/resources/images/image-error.png';

type Props = {
    homeStore: HomeVisualStore;
    previewStore: PreviewsVisualStore;
    analyzeStore: AnalyzeVisualStore;
    visible: boolean;
    setVisibility: (visible: boolean) => void;
};

export const SessionProgressDialog: React.FC<Props> = ({ previewStore, analyzeStore, visible, setVisibility }) => {
    React.useEffect(() => {
        analyzeStore.getPreviewForCurrentRunningSession();
    }, [analyzeStore, analyzeStore.processedPackageId]);

    return (
        <Modal
            title="Analysing document..."
            closable={false}
            className="alpha-package-progress-modal"
            visible={visible}
            width={650}
            onCancel={() => setVisibility(false)}
            onOk={() => setVisibility(false)}
            footer={
                <Row>
                    <Col span={24} style={{ textAlign: 'right' }}>
                        <Button className="light" onClick={() => setVisibility(false)}>
                            Close
                        </Button>
                    </Col>
                </Row>
            }
        >
            <div className="doc-preview-large">
                <Observer>
                    {() =>
                        analyzeStore.processedPackageId &&
                        previewStore.largePreviewUrls[analyzeStore.processedPackageId] ? (
                            <ImageWithFallback
                                src={
                                    analyzeStore.processedPackageId
                                        ? previewStore.largePreviewUrls[analyzeStore.processedPackageId]
                                        : ''
                                }
                                fallbackSrc={errorImg}
                            />
                        ) : (
                            <Spin />
                        )
                    }
                </Observer>
            </div>
            <div className="action-title">Please wait...</div>
            <div className="action-description">
                Reading document(-s) <strong>{analyzeStore.processedPackageName}</strong>
                <br /> When complete, a new tab and form will open automatically
            </div>
            <Progress strokeWidth={8} status="active" strokeColor="#273C75" percent={100} showInfo={false} />
        </Modal>
    );
};

export default observer(SessionProgressDialog);
