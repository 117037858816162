import * as React from 'react';
import { useState } from 'react';
import { DynamicControlProps } from './types';
import { Checkbox, Form, Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Utils } from '../../../common/misc/Utils';
import { Subscription } from 'rxjs';

export const DynamicCheckbox: React.FC<DynamicControlProps> = ({
    inputParams,
    onHighlightBlock,
    highlightInputSubject
}) => {
    const checked = inputParams.value as boolean;
    const [isChecked, setIsChecked] = useState(checked);
    const [isHighlighted, setIsHighlighted] = useState(false);

    let sub: React.MutableRefObject<Subscription | undefined> = React.useRef();

    React.useEffect(() => {
        if (highlightInputSubject) {
            sub.current = highlightInputSubject.subscribe(id => {
                if (id === inputParams.id) {
                    setIsHighlighted(true);
                } else {
                    setIsHighlighted(false);
                }
            });
        }

        return () => {
            if (sub.current) {
                sub.current.unsubscribe();
            }
        };
    }, [highlightInputSubject, inputParams.id]);

    const handleChange = (event: CheckboxChangeEvent) => {
        if (!event.target.disabled) {
            setIsChecked(event.target.checked);
        }
    };

    const highlightField = () => {
        const fieldData = Utils.extractFieldData(inputParams);
        if (fieldData) {
            onHighlightBlock(fieldData, inputParams.id);
        }
    };

    const label = (
        <span className="alpha-doc-control-label">
            <Tooltip title={inputParams.name}>
                <span className="alpha-doc-control-name">{inputParams.name}</span>
            </Tooltip>
        </span>
    );

    return (
        <div className="checkbox-scan-result-container">
            <Form.Item
                data-id={inputParams.id}
                data-type={inputParams.controlType}
                className="checkbox-scan-result"
                name={inputParams.id}
                rules={[
                    {
                        required:
                            inputParams.validation &&
                            inputParams.validation.required &&
                            inputParams.validation.required.value,
                        message:
                            inputParams.validation &&
                            inputParams.validation.required &&
                            inputParams.validation.required.message
                    }
                ]}
                initialValue={isChecked}
                valuePropName="checked"
            >
                <Checkbox
                    style={{
                        boxShadow: isHighlighted ? '0px 0px 10px 1px rgba(131,172,222,1)' : 'none',
                        ...Utils.buildDynamicInputStyles(inputParams)
                    }}
                    disabled={inputParams.behavior && inputParams.behavior.readonly}
                    onChange={handleChange}
                >
                    {label}
                </Checkbox>
            </Form.Item>
            {inputParams.meta && inputParams.meta.field && inputParams.value && (
                <Tooltip title="View in document">
                    <div className="text-navigator-container">
                        <i className="alpha-icon xs form-anchor" onClick={highlightField} />
                    </div>
                </Tooltip>
            )}
        </div>
    );
};

export default DynamicCheckbox;
