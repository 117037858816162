import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { SearchResultModel, SearchResultFileModelData } from '../../types';
import { SearchVisualStore } from '../../stores';
import { Tooltip } from 'antd';
import { Utils } from '../../../common/misc/Utils';

type Props = {
    store: SearchVisualStore;
    result: SearchResultModel;
    style?: React.CSSProperties;
};

const FileSearchResult: React.FC<Props> = ({ result, style }) => {
    if (result.type !== 'FILES') {
        return null;
    }

    const item = result.data as SearchResultFileModelData;

    const handleLinkClick = () => {
        if (result.url || item.url) {
            window.open(result.url || item.url);
        }
    };

    const handleSiteNameClick = () => {
        if (item.siteName && item.siteUrl) {
            window.open(item.siteUrl);
        }
    };

    const thumbnailStyles = {
        display: 'inline-block',
        margin: 0,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundImage: `url(data:image/png;base64,${item.thumbnail})`
    };

    const documentThumbnailTooltip = () => {
        return (
            <span
                style={{
                    height: 176,
                    width: 176,
                    ...thumbnailStyles
                }}
            />
        );
    };

    const generateDocumentIcon = (documentName: string) => {
        if (item.thumbnail) {
            return (
                <Tooltip title={() => documentThumbnailTooltip()}>
                    <span
                        className={'alpha-icon md'}
                        style={{
                            height: 45,
                            width: 45,
                            ...thumbnailStyles
                        }}
                    />
                </Tooltip>
            );
        }

        return <i className={`alpha-icon md ${Utils.generateDocumentIconClass(documentName)}`} style={{ margin: 0 }} />;
    };

    return (
        <div style={{ cursor: 'default', ...style }} key={item.id} className={'ant-list-item project-list-item'}>
            <div>
                <span className="cognitive-search-thumbnail" style={{ textAlign: 'center', lineHeight: '32px' }}>
                    {generateDocumentIcon(item.name)}
                </span>
            </div>
            <div>
                <div>
                    <span
                        style={{
                            fontSize: 13,
                            cursor: 'pointer',
                            color: '#2750b7',
                            textDecoration: 'underline'
                        }}
                        onClick={handleLinkClick}
                    >
                        {item.name}
                    </span>
                </div>
                <div className="additional-info">
                    {item.folder && (
                        <span style={{ borderRadius: 4 }} className={'project-tag selectable'}>
                            Folder: {item.folder}
                        </span>
                    )}
                    {item.contentType && (
                        <span style={{ borderRadius: 4 }} className={'project-tag selectable'}>
                            Type: {item.contentType}
                        </span>
                    )}
                    {item.createdByUser && (
                        <span style={{ borderRadius: 4 }} className={'project-tag selectable'}>
                            Created by: {item.createdByUser}
                        </span>
                    )}
                    {item.createDate && (
                        <span style={{ borderRadius: 4 }} className={'project-tag selectable'}>
                            Created date: {Utils.formatDateStringLong(item.createDate)}
                        </span>
                    )}
                    {item.siteName && (
                        <span style={{ borderRadius: 4 }} className={'project-tag selectable'}>
                            Site name: {item.siteName}
                        </span>
                    )}
                    <div className="search-blocks-preview">{item.description}</div>
                    <div className="search-result-url" onClick={handleSiteNameClick}>
                        Sharepoint: {item.siteName}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(FileSearchResult);
