import * as React from 'react';
import { Route } from 'react-router';
import SessionsPage from '../screens/SessionsPage';
import { context } from '../stores';

const sessionssRoute = () => {
    return (
        <Route
            path={'/archive'}
            element={
                <context.SessionsStoreProvider>
                    <SessionsPage />
                </context.SessionsStoreProvider>
            }
        />
    );
};

export default sessionssRoute;
