/* eslint-disable @typescript-eslint/no-explicit-any */
import { SortOrder } from 'antd/lib/table/interface';

export enum PackageStateResult {
    Ready = 'Ready',
    Parsed = 'Parsed',
    Importing = 'Importing',
    Processing = 'Processing',
    Uploading = 'Uploading',
    Broken = 'Broken'
}

export enum PackageSetType {
    Conjunction = 'Conjunction',
    Folder = 'Folder'
}

export type AnchorResult = {
    id: string;
    keyword: string;
    aliases: string[];
};

export type NamedEntity = {
    start: number;
    end: number;
    text: string;
};

export type ApplicationSession = {
    id: string;
    packageId: string;
    projectId: string;
    runtimeSessionId: string;
    created: string;
    updated: string;
    applicationDefinitionId: string;
    error: string;
    applicationExtension: string;
    applicationName: string;
    applicationSettings: {};
    iotaApplication: string;
    packageName: string;
    projectName: string;
    state: SessionStates;
    packageSetId?: string;
    userId?: string;
    isBulkSession?: boolean;
    isReloadable?: boolean;
    capabilities?: ApplicationCapabilities;
    updatedBy?: string;
    sessionResults: Record<string, string> | null;
};

export type TabApplicationData = {
    appId: string;
    appName: string;
    appExtension: string;
    appSettings: unknown;
    iotaApplication: string;
};

export type SessionStates = 'Pending' | 'Finished' | 'WaitingAction' | 'Aborted' | 'NotStarted' | 'Archived';

export type ApplicationSessionResult = {
    id: string;
    packageId: string;
    projectId: string;
    runtimeSessionId: string;
    created: string;
    updated: string;
    applicationDefinitionId: string;
    applicationExtension: string;
    applicationSettings: {};
    iotaApplication: string;
    error: string;
    state: SessionStates;
    packageSetId?: string;
    isBulkSession?: boolean;
    userId?: string;
};

export type PackageResult = {
    id: string;
    fileName: string;
    filePath: string;
    projectId: string;
    contentType: string;
    state: PackageStateResult;
    operationState: string[];
    userTags: string[];
    fileSizeBytes: number;
    uploadedTime: string;
    indexDate: string;
    source: string;
    packageSetId?: string;
    packageSet?: PackageSetResult;
};

export class SearchPackagesResponse {
    searchPackages: {
        result: PackageResult[];
        pageInfo: {
            total: number;
        };
    };
}

export type FieldsSearch = {
    packageMatch?: Record<string, unknown>;
    additionalSearchTerm?: Record<string, unknown>;
};

export type SearchPackagesRequest = {
    projectIds: string[];
    page?: number;
    pageSize?: number;
    searchTerm?: string | null;
    fieldsSearch?: FieldsSearch | null;
    allSources?: boolean;
    orderBy?: OrderBy | null;
    distinctBy?: string | null;
    uploadedBy?: string | null;
};

export type OrderBy = {
    field: string;
    direction: string;
};

export enum PackageListItemType {
    Package = 'package',
    PackageSet = 'packageSet'
}

export type PackageListItemModel = {
    id: string;
    fileName: string;
    projectId: string;
    state: PackageStateResult;
    userTags: string[];
    fileSizeBytes: number;
    uploadedTime: string;
    indexDate: string;
    type?: PackageListItemType;
    packages?: PackageResult[];
    packageSetId?: string;
};

export type PackageSetResult = {
    id: string;
    name: string;
    projectId: string;
    packageIds: string[];
    type: PackageSetType;
    uploadTime?: string;
    state: PackageStateResult;
    packages: PackageResult[];
};

export type ProjectResult = {
    id: string;
    name: string;
    keywords: string[];
    labels: AnchorResult[];
    namedEntities: {
        label: string;
        text: string;
        entities: NamedEntity[];
    }[];
    packages: PackageResult[] | null;
    packageSets: PackageSetResult[] | null;
    color?: string;
};

export type DefaultProjectRes = {
    id: string;
    defaultProject: string;
};

export type ApplicationCapabilities = {
    isBulkProcessing: boolean;
    isReloadingDisabled: boolean;
    customUI?: string;
};

export type ApplicationDefinition = {
    id: string;
    name: string;
    applicationId: string;
    workflowId: string;
    projectId: string;
    extension: string;
    state: string;
    meta: string;
    settings?: string;
    iconFileId?: string;
    bindings: {
        inputId: string;
        type: string;
        value: unknown;
    }[];
    settingValues?: { [id: string]: unknown };
    iconUrl?: string;
    capabilities?: ApplicationCapabilities;
};

export type ApplicationDefinitionSubgroup = {
    id: string;
    name: string;
    applicationDefinitionIds: string[];
    subGroups: ApplicationDefinitionSubgroup[];
};

export type PackageChanges = {
    id: string;
    state: PackageStateResult;
    fileName: string;
    fileSizeBytes: number;
    uploadedTime: string;
    userTags: string[];
    projectId: string;
    filePath: string;
    indexDate: string;
    packageSetId?: string;
    source?: string;
};

export type PackageSetSession = {
    id: string;
    packages: PackageSetSessionPackage[];
};

export type PackageSetSessionPackage = {
    packageId: string;
    packageName: string;
    state: string;
    createdTime: string;
    updatedTime: string;
};

export type ApplicationDefinitionGroup = {
    id: string;
    projectId: string;
    name: string;
    applicationDefinitionIds: string[];
    subGroups: ApplicationDefinitionSubgroup[];
};

export type PackageSetSessionResult = {
    getPackageSetSessionById: PackageSetSession;
};

export type AllSessionsResult = {
    getSessionModels: ApplicationSession[];
};

export type SessionsForProjects = {
    getSessionModelsForProjects: {
        sessions: ApplicationSession[];
        total: number;
    };
};

export type SessionModelResult = {
    getSessionModelById: ApplicationSession[];
};

export type DefaultProjectResult = {
    getDefaultProject: DefaultProjectRes;
};

export type ProjectsByUserResult = {
    projectsByUser: ProjectResult[];
};

export type ApplicationDefinitionGroupsResult = {
    getAppDefinitionGroups: ApplicationDefinitionGroup[];
};

export type ApplicationDefinitionsResult = {
    getAppDefinitions: ApplicationDefinition[];
};

export type ProjectByIdResult = {
    projectById: ProjectResult | null;
};

export type WidgetDataItem = {
    text: string;
    value: string;
};

export type ComplexWidgetDataItem = {
    text: string;
    value: unknown;
};

export type PackageSetItemModel = {
    id: string;
    fileName: string;
    contentType: string;
    fileSizeBytes: number;
    indexDate: string;
};

export type PackageSetItemsResult = {
    getPackagesFromSet: PackageSetItemModel[];
};

export type WidgetData = {
    total: number;
    skip: number;
    data: WidgetDataItem[];
};

export type CurrentWidgetValueIds = {
    [key: string]: string | string[] | boolean | unknown[];
};

export enum WidgetType {
    Combobox = 'combobox',
    DatePicker = 'datepicker',
    Input = 'input',
    Checkbox = 'checkbox',
    Radio = 'radio',
    ListBox = 'listbox',
    VisualTable = 'visualtable',
    ResultTable = 'resulttable',
    ContainerInputChild = 'containerInputChild'
}

export type FormInputParams = {
    id: string;
    controlType: WidgetType;
    type: 'string' | 'number' | 'decimal' | 'numberWithKeyword' | 'percentage';
    name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: string | string[] | boolean | unknown[];
    unmodifiedValue: string | string[] | boolean | unknown[];
    tokenId: string;
    validation: {
        regex: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            value: any;
            message: string;
        };
        required: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            value: any;
            message: string;
        };
        maxLength: {
            value: number;
            message: string;
        };
    };
    description: string;
    behavior?: {
        multiselect?: boolean;
        readonly?: boolean;
        multiline?: boolean;
        comments?: boolean;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onchange?: any;
        width?: number;
        displayColumns?: DisplayColumnsBehavior[];
        inputOverride?: InputOverrideBehavior[];
        visibleInputs?: string[];
        systemInputs?: { key: string; value: string }[];
        style?: InputStyle;
        action?: InputAction;
        iconType?: string;
        header?: string;
        customSingleSelect?: string;
        inputTooltip?: string;
        icon?: string;
        currentPage?: number;
        sortedColumn?: { key: string; order: SortOrder };
        highlightingInput?: boolean;
        newLine?: boolean;
        orderIndex?: number;
        renderInSettingsModal?: boolean;
        horizontalAlignment?: 'center';
        validationAction?: string;
        hideZeros?: boolean;
    };
    modalForm?: FormInputModels;
    source: {
        id: string;
    };
    section?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    meta?: { [key: string]: any };
    parentInputId?: string;
    inputMask?: string;
};

export type InputAction = {
    name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    settings: any;
};

export type InputStyle = {
    backgroundColor?: string;
    borderColor?: string;
    color?: string;
};

export type IotaSessionResult = {
    sessionId: string;
    inProgress: boolean;
    response: FormInputModels;
};

export type DisplayColumnsBehavior = {
    key: string;
    header: string;
    canSort: boolean;
    source?: string;
    dataType?: string;
    rowSpecificSourceKey: string;
    valueFilters?: string[];
};

export type InputOverrideBehavior = {
    key: string;
    type: 'dropdown' | 'text' | 'datepicker';
    source: string;
    width?: 'default' | 'wide';
    hasModalForm?: boolean;
    parentInputId?: string;
    name?: string;
    multiSelect?: boolean;
    modalFormHeader?: string;
    hasRowSpecificSource?: boolean;
    rowSpecificSourceKey?: string;
    maxLength?: number;
    singleInputPerRow?: boolean;
};

export type SimpleRuntimeInputParams = {
    id: string;
    controlType: WidgetType;
    type: 'string' | 'number' | 'decimal';
    name: string;
    validation: {
        regex: string;
        required: boolean;
    };
    description: string;
    behavior?: {
        multiselect?: boolean;
        readonly?: boolean;
        multiline?: boolean;
        onchange?: string;
        width?: number;
    };
    value: string | string[] | boolean;
    unmodifiedValue: string | string[] | boolean;
    source: {
        id: string;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    meta?: { [key: string]: any };
    parentInputId?: string;
};

export type ContainerChildInput = {
    input: SimpleRuntimeInputParams;
    column: number;
    row: number;
};

export type ContainerInput = {
    children: ContainerChildInput[];
    rows: number;
    columns: number;
    id: string;
    section: string;
    headers: string[];
    footer: FormInputModels;
    behavior?: {
        useSimpleContainerControls: boolean;
        readonly?: boolean;
        horizontalAlignment?: 'center';
    };
};

export enum InputBindingType {
    CodeRule = 'Business Rule',
    Tag = 'Tag',
    Constant = 'Constant'
}

export type FormSourcesParams = {
    id: string;
    type: 'static' | 'linked';
    link: string;
    data: { value: string; text: string }[];
};

export type FormActionsParams = {
    id: string;
    name: string;
    description: string;
    isDisabled: boolean;
    message: string;
};

export type FormSectionsParams = {
    id: string;
    name: string;
    order: number;
    parentSectionId?: string;
    type: SectionType;
    location: SectionLocation;
    isCollapsed: boolean;
    behavior?: {
        isEditable: boolean;
    };
};

export enum SectionLocation {
    Bottom = 'Bottom',
    Left = 'Left'
}

export type FormErrorParams = {
    errorCode?: string;
    message?: string;
    details?: string;
};

export type FormInputModels = {
    ['inputs']: FormInputParams[];
    ['sources']: FormSourcesParams[];
    ['actions']: FormActionsParams[];
    ['sections']: FormSectionsParams[];
    ['errors']: FormErrorParams[] | null;
    ['containerInputs']: ContainerInput[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ['layoutConfiguration']: any | null;
    ['postActions']: RuntimePostAction[] | null;
    ['preSubmitActions']: RuntimePreSubmitAction[] | null;
    ['additionalInfo']: string | null;
};

export enum SectionType {
    Accordeon = 'Accordeon',
    Tab = 'Tab'
}
export type FormInputFieldData = {
    p: number; // Page number
    x: number;
    y: number;
    w: number; // Width
    h: number; // Height
    pw: number; // Page width
    ph: number; // Page height
    bt: string; // Block type
    pId?: string; // Package Id
    nodeHierarchy?: string;
};

export type RuntimePostAction = {
    type: 'Download' | 'Close'; // Add types if necessary
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    metadata: { [key: string]: any };
};

export type RuntimePreSubmitAction = {
    type: 'Authorize' | 'OidcCodeFlowAuthorize' | 'OidcImplicitFlowAuthorize'; // Add types if necessary
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    metadata: { [key: string]: any };
};

export type SessionState = {
    inProgress: boolean;
    response: FormInputModels;
    projectId: string;
    packageId: string;
};

export type StartSessionResponse = {
    sessionId: string;
    packageId: string;
};

export type SessionFilter = {
    projectIds?: string[];
    searchTerm?: string;
    userId?: string;
    dateFrom?: string;
    dateTo?: string;
    orderByField?: keyof ApplicationSession;
    orderByDirection?: 'ASC' | 'DESC';
    page: number;
    pageSize: number;
    sessionStates?: SessionStates[];
};

export type StartSessionForMultiplePackagesResponse = {
    sessionId: string;
    packageIds: string[];
    packageSetId: string;
};

export type SessionContinueRequest = {
    ['inputs']: {
        [key: string]: string;
    };
    ['action']: string;
};

export type DataResponse = {
    totalCount: number;
    page: number;
    pageSize: number;
    rows: DataResponseEntityRow[];
};

export type DataResponseEntityRow = {
    items: DataResponseEntity[];
};

export type DataResponseEntity = {
    field: string;
    value: unknown;
};

export type UserProfile = {
    userId: string;
    userName: string;
    name: string;
    timezone: string;
    avatarIconFileId: string;
    firstName: string;
    lastName: string;
    email: string;
    isDomainUser: boolean;
};

export type UserProfileResult = {
    getUserProfile: UserProfile;
};

export type FieldComment = {
    userId: string;
    value: string;
    date: Date;
};

export type IotaAppComment = {
    fieldId: string;
    comments: FieldComment[];
};

export type IotaAppCommentsResult = {
    getIotaAppComments: IotaAppComment[];
};

export type SearchPackageLinesRequest = {
    projectId: string;
    packageId: string;
    searchTerm: string;
    pageSize: number;
    page: number;
    fieldsSearch?: Record<string, unknown>;
    searchOptions?: Record<string, Record<string, unknown>>;
};

export type SearchPackageLinesResult = {
    searchPackageLines: {
        result: PackageFieldResult[];
        pageInfo: {
            total: number;
        };
    };
};

export type PackageFieldResult = {
    fieldId: string;
    rowId: number;
    page: number;
    x: number;
    y: number;
    w: number;
    h: number;
    pageWidth: number;
    pageHeight: number;
    text: string;
    normalizedText: string;
    anchor: string;
    imagePath: string;
    hasImage: boolean;
    packageId: string;
    label: string;
    additionalProperties: Record<string, unknown>;
    namedEntities: NamedEntity[];
    blockType: string;
    childFields: PackageFieldResult[] | null;
};
