import * as React from 'react';
import { DynamicControlProps } from './types';
import { Button, Form } from 'antd';
import { getConfLevelColor } from './ConfidenceLevel';
import { Subscription } from 'rxjs';

export const DynamicButton: React.FC<DynamicControlProps> = ({ inputParams, onAction, highlightInputSubject }) => {
    const actionId = inputParams.behavior && inputParams.behavior.onchange;
    // const [value, setValue] = useState(inputParams.type === 'number' && inputParams.value ? Number(inputParams.value) : inputParams.value);

    const [isHighlighted, setIsHighlighted] = React.useState(false);

    let sub: React.MutableRefObject<Subscription | undefined> = React.useRef();

    React.useEffect(() => {
        if (highlightInputSubject) {
            sub.current = highlightInputSubject.subscribe(id => {
                if (id === inputParams.id) {
                    setIsHighlighted(true);
                } else {
                    setIsHighlighted(false);
                }
            });
        }

        return () => {
            if (sub.current) {
                sub.current.unsubscribe();
            }
        };
    }, [highlightInputSubject, inputParams.id]);

    const handleClick = () => {
        if (actionId && !inputParams.behavior?.readonly) {
            onAction(inputParams.id, actionId);
        }
    };

    const getClassName = () => {
        return inputParams.value && !(inputParams.behavior && inputParams.behavior.readonly)
            ? getConfLevelColor(inputParams) + getInputCssClass()
            : getInputCssClass();
    };

    const getEditor = () => {
        return inputParams.behavior && inputParams.behavior.icon ? (
            inputParams.name ? (
                <div className="label-with-icon" style={{ cursor: 'pointer', color: '#9BA0AA' }} onClick={handleClick}>
                    <i className="alpha-icon xs plus" />
                    <span>{inputParams.name}</span>
                </div>
            ) : (
                <i className={`alpha-icon xxs ${inputParams.behavior.icon}`} onClick={handleClick} />
            )
        ) : (
            <Button
                className={getClassName()}
                onClick={handleClick}
                value={(inputParams.value && inputParams.value.toString()) || ''}
                disabled={inputParams.behavior && inputParams.behavior.readonly}
            >
                {inputParams.name}
            </Button>
        );
    };

    const getInputCssClass = () => {
        return isHighlighted ? ' iota-contract-shadow' : '';
    };
    const getValueFromEvent = (event: React.FormEvent<HTMLInputElement>) => {
        const eventVal = event.currentTarget.value;
        const val = inputParams.type === 'number' && eventVal ? Number(eventVal) : eventVal;
        return val;
    };

    const getButtonStyles = () => {
        return inputParams?.behavior?.style || {};
    };

    return (
        <Form.Item
            data-id={inputParams.id}
            data-type={inputParams.controlType}
            data-testid={inputParams.id}
            colon={false}
            labelCol={{ span: 24 }}
            className={`scan-results-dynamic-button ${inputParams.behavior && inputParams.behavior.width === 1 ? 'wide' : ''}`}
            style={getButtonStyles()}
            name={inputParams.section ? `${inputParams.id}-${inputParams.section}` : inputParams.id}
            getValueFromEvent={getValueFromEvent}
        >
            {getEditor()}
        </Form.Item>
    );
};
