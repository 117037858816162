import { Collapse, Form, FormInstance, Input, Divider } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Subject } from 'rxjs';
import authContext from '../../authorization/AuthContext';
import { hasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import { FormInputFieldData, FormInputParams, FormSectionsParams } from '../../common/services/types';
import { DynamicUiModel } from '../../common/stores/DynamicUiModel';
import { DynamicContainerInput } from '.';
import Registry from './DynamicControlsRegistry';
import { ChangeEvent } from 'react';
import { RightOutlined } from '@ant-design/icons';
import _ from 'lodash';
const { Panel } = Collapse;

const LARGE_NUMBER = 1000; // just random large number for ordering sections witout order index

const DEBOUNCE_SECTION_NAME_CHANGE_TIME = 500;

export const sortInputs = (inputs: FormInputParams[]) =>
    inputs.sort((a, b) => (a.behavior?.orderIndex || LARGE_NUMBER) - (b.behavior?.orderIndex || LARGE_NUMBER));

export const setInputReadonly = (inputParams: FormInputParams) => {
    if (inputParams.behavior) {
        inputParams.behavior.readonly = true;
    } else {
        inputParams.behavior = {
            readonly: true
        };
    }
};

const getExpandIcon = (isActive?: boolean) => <RightOutlined rotate={isActive ? 90 : 0} style={{ color: '#9BA0AA' }} />;

type Props = {
    ui: DynamicUiModel;
    onHighlightBlock: (field: FormInputFieldData, inputId: string) => void;
    onDownload?: (filePath: string) => void;
    highlightInputSubject?: Subject<string>;
    form: FormInstance;
    getGearIcon: (inputParams: FormInputParams) => false | JSX.Element | undefined;
    handleCommentFieldClick: (fieldId: string) => void;
    handleAction: (control: string, actionId: string) => void;
    sections: (FormSectionsParams | null)[];
    defaultOpenSections: string[];
    openSections?: string[];
    setOpenSections?: (keys: string[]) => void;
};

const DocumentSectionsRenderer: React.FC<Props> = ({
    ui,
    form,
    onHighlightBlock,
    getGearIcon,
    handleAction,
    sections,
    defaultOpenSections,
    openSections,
    setOpenSections,
    highlightInputSubject,
    onDownload,
    handleCommentFieldClick
}) => {
    const authCtx = React.useContext(authContext);

    const renderInputs = (section: string | undefined | null) => {
        return (
            // tslint:disable-next-line:triple-equals
            sortInputs(ui.inputs.filter(i => i.section == section && !i.behavior?.renderInSettingsModal)).map(
                (inputParams, index) => {
                    let WidgetComponent = Registry[inputParams.controlType]!;

                    if (!hasPermission(authCtx.permissions, AppPermissions.CanManageSessionsAndDocuments)) {
                        setInputReadonly(inputParams);
                    }

                    return (
                        <WidgetComponent
                            key={index}
                            inputParams={inputParams}
                            onAction={handleAction}
                            ui={ui}
                            form={form}
                            onHighlightBlock={onHighlightBlock}
                            highlightInputSubject={highlightInputSubject}
                            getGearIcon={getGearIcon}
                            onDownload={onDownload}
                            handleCommentFieldClick={handleCommentFieldClick}
                        />
                    );
                }
            )
        );
    };

    const childSections = [...new Set(ui.sections.filter(s => !!s.parentSectionId))];

    const handleSectionChange = (e: ChangeEvent<HTMLInputElement>, id: string) => {
        const { value } = e.target;
        const regex = `.*${id.split('_')[1].replace(/\|/g, '\\|')}`;
        const sectionsToRename = ui.sections.filter(s => s.id.match(regex));
        const inputsToRename = ui.inputs.filter(s => s.section?.match(regex));
        sectionsToRename.forEach(s => {
            const obj = Object.create(null);
            obj[s.id] = value;
            form.setFieldsValue(obj);
        });
        inputsToRename.forEach(s => {
            const index = s.id.indexOf('|');
            s.id = `${s.id.substring(0, index + 1)}${value}`;
        });
    };

    const debouncedSectionNameChange = _.debounce(handleSectionChange, DEBOUNCE_SECTION_NAME_CHANGE_TIME);

    const getSection = (section: FormSectionsParams, nestedSection?: boolean) => {
        const name = nestedSection ? section.id.split('|')[2] : section.name;
        if (section.behavior?.isEditable && (!section.id.split('|')[2] || nestedSection)) {
            return (
                <Form.Item
                    initialValue={name}
                    name={section.id}
                    className="editable-section-name"
                    rules={[{ required: true, message: 'Please input section name!', whitespace: true }]}
                >
                    <Input
                        onClick={e => e.stopPropagation()}
                        onChange={e => debouncedSectionNameChange(e, section.id)}
                    />
                </Form.Item>
            );
        }

        return <h1>{name}</h1>;
    };

    const getLayersOrInputs = (section: string) => {
        const res = childSections.filter(x => x.parentSectionId === section);
        if (res.length) {
            return (
                <Collapse
                    bordered={false}
                    expandIconPosition="left"
                    className="alpha-layer-section"
                    defaultActiveKey={Array.from(new Set(res.filter(r => !r.isCollapsed).map(s => s.name)))}
                    expandIcon={({ isActive }) => getExpandIcon(isActive)}
                >
                    {[...new Set(res.map(r => r.name))].map(x => (
                        <Panel
                            className="alpha-layer-header"
                            header={getSection(res.find(r => r.name === x)!)}
                            key={x}
                            forceRender
                        >
                            <Divider className="alpha-layer-divider" />
                            {res.find(r => r.name === x)!.id.split('|')[2]
                                ? res
                                      .filter(r => r.name === x && r.id.split('|')[1])
                                      .map((s, i, items) => (
                                          <Collapse
                                              defaultActiveKey={s.id}
                                              key={s.id}
                                              className="alpha-layer-subsection"
                                              bordered={false}
                                              expandIcon={({ isActive }) => getExpandIcon(isActive)}
                                          >
                                              <Panel
                                                  className="alpha-layer-subsection-header"
                                                  key={s.id}
                                                  header={getSection(s, true)}
                                                  forceRender
                                              >
                                                  {renderInputs(s.id)}
                                              </Panel>
                                              {i !== items.length - 1 && <Divider className="alpha-layer-divider" />}
                                          </Collapse>
                                      ))
                                : renderInputs(res.find(r => r.name === x)!.id)}
                        </Panel>
                    ))}
                </Collapse>
            );
        } else {
            return renderInputs(section);
        }
    };

    const renderContainerInputs = (section: string | undefined | null) => {
        if (ui.containerInputs) {
            var containerInputs = ui.containerInputs.filter(i => i.section === section);

            if (containerInputs && containerInputs.length > 0) {
                if (!hasPermission(authCtx.permissions, AppPermissions.CanManageSessionsAndDocuments)) {
                    for (let ci of containerInputs) {
                        if (ci.behavior) {
                            ci.behavior.readonly = true;
                        } else {
                            ci.behavior = {
                                useSimpleContainerControls: true,
                                readonly: true
                            };
                        }
                    }
                }

                return (
                    <div>
                        {containerInputs.map(c => (
                            <DynamicContainerInput
                                key={c.id}
                                containerInput={c}
                                ui={ui}
                                form={form!}
                                handleAction={handleAction}
                                onHighlightBlock={onHighlightBlock}
                                getGearIcon={getGearIcon}
                            />
                        ))}
                    </div>
                );
            }
        }

        return null;
    };

    const renderGeneralSection = () => {
        const inputs = renderInputs(null);
        const containerInputs = renderContainerInputs(null);

        if (!inputs.length && !containerInputs) {
            return null;
        }

        return (
            <Panel
                className="scan-results-collapse-panel"
                header={
                    <h1 data-id="section-general" className="alpha-form-header">
                        General information
                    </h1>
                }
                key={'section-general'}
                forceRender
            >
                {inputs}
                {containerInputs}
            </Panel>
        );
    };

    return (
        <Collapse
            bordered={false}
            expandIconPosition="left"
            defaultActiveKey={defaultOpenSections}
            activeKey={openSections && setOpenSections ? openSections : defaultOpenSections}
            onChange={(keys: string[]) => (setOpenSections ? setOpenSections(keys) : null)}
            className="scan-results-collapse"
            style={{ backgroundColor: '#f5f6f8' }}
            expandIcon={({ isActive }) => getExpandIcon(isActive)}
        >
            {sections.map(s =>
                s ? (
                    <Panel
                        className="scan-results-collapse-panel"
                        header={
                            <h1 data-id={`section-${s.id}`} className="alpha-form-header">
                                {s.name}
                            </h1>
                        }
                        key={`section-${s.id}`}
                        forceRender
                    >
                        <>
                            {getLayersOrInputs(s.id)}
                            {renderContainerInputs(s.id)}
                        </>
                    </Panel>
                ) : (
                    renderGeneralSection()
                )
            )}
        </Collapse>
    );
};

export default observer(DocumentSectionsRenderer);
