import * as React from 'react';
import { DynamicControlProps } from './types';
import { WidgetData } from '../../../common/services/types';
import { Radio, Tooltip, Form } from 'antd';
import { Utils } from '../../../common/misc/Utils';
import { Subscription } from 'rxjs';

export const DynamicRadioButton: React.FC<DynamicControlProps> = ({
    inputParams,
    ui,
    onHighlightBlock,
    highlightInputSubject
}) => {
    const [values, setValues] = React.useState<WidgetData>({
        data: [],
        skip: 0,
        total: 0
    });

    const [isHighlighted, setIsHighlighted] = React.useState(false);
    let sub: React.MutableRefObject<Subscription | undefined> = React.useRef();

    React.useEffect(() => {
        if (highlightInputSubject) {
            sub.current = highlightInputSubject.subscribe(id => {
                if (id === inputParams.id) {
                    setIsHighlighted(true);
                } else {
                    setIsHighlighted(false);
                }
            });
        }

        return () => {
            if (sub.current) {
                sub.current.unsubscribe();
            }
        };
    }, [highlightInputSubject, inputParams.id]);

    React.useEffect(() => {
        ui.getAllWidgetValues(inputParams.source.id).then(r => {
            setValues(r);
        });
    }, [inputParams.source.id, ui]);

    const highlightField = () => {
        const fieldData = Utils.extractFieldData(inputParams);
        if (fieldData) {
            onHighlightBlock(fieldData, inputParams.id);
        }
    };

    const label = (
        <span className="alpha-doc-control-label">
            <Tooltip title={inputParams.name}>
                <span className="alpha-doc-control-name">{inputParams.name}</span>
            </Tooltip>
            {inputParams.meta && inputParams.meta.field && inputParams.value && (
                <Tooltip title="View in document">
                    <i className="alpha-icon xs form-anchor" onClick={highlightField} />
                </Tooltip>
            )}
        </span>
    );

    return (
        <div className="radio-scan-result-container">
            <Form.Item
                colon={false}
                data-id={inputParams.id}
                className="radio-scan-result"
                data-type={inputParams.controlType}
                data-testid={inputParams.controlType}
                label={label}
                name={inputParams.id}
                rules={[
                    {
                        required:
                            inputParams.validation &&
                            inputParams.validation.required &&
                            inputParams.validation.required.value,
                        message:
                            inputParams.validation &&
                            inputParams.validation.required &&
                            inputParams.validation.required.message
                    },
                    {
                        pattern:
                            inputParams.validation &&
                            inputParams.validation.regex &&
                            new RegExp(inputParams.validation.regex.value),
                        message:
                            inputParams.validation &&
                            inputParams.validation.regex &&
                            inputParams.validation.regex.message
                    }
                ]}
                initialValue={inputParams.value}
            >
                <Radio.Group
                    disabled={inputParams.behavior && inputParams.behavior.readonly}
                    style={{ boxShadow: isHighlighted ? '0px 0px 10px 1px rgba(131,172,222,1)' : 'none' }}
                >
                    {values &&
                        values.data &&
                        values.data.map((option, index: number) => (
                            <Radio key={index} id={`radio-${index}`} name={inputParams.name} value={option.value}>
                                {option.text}
                            </Radio>
                        ))}
                </Radio.Group>
            </Form.Item>
        </div>
    );
};

export default DynamicRadioButton;
