import * as React from 'react';
import { useState } from 'react';
import { DynamicUiModel } from '../../common/stores/DynamicUiModel';
import { WidgetValues } from '../../common/services/WidgetValuesProvider';
import { Button, Menu, Dropdown, Col, Row, Tooltip, Form, FormInstance } from 'antd';
import { observer } from 'mobx-react-lite';
import FormItem from 'antd/lib/form/FormItem';
import {
    FormInputFieldData,
    FormInputParams,
    InputBindingType,
    RuntimePostAction,
    SectionLocation
} from '../../common/services/types';
import DynamicControlsBottomContainer from './DynamicControlsBottomContainer';
import { Utils } from '../../common/misc/Utils';
import { Subject, Subscription } from 'rxjs';
import { FormError } from '../../common/types/FormError';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import FieldCommentModal from './FieldCommentModal';
import DocumentHeaderRenderer from './DocumentHeaderRenderer';
import DocumentSectionsRenderer from './DocumentSectionsRenderer';
import OAuth2Login from 'react-simple-oauth2-login';

export const getGearIcon = (inputParams: FormInputParams) => {
    return (
        inputParams.meta &&
        inputParams.meta.type &&
        (inputParams.meta.type === InputBindingType.CodeRule.toLowerCase() ||
            inputParams.meta.type === InputBindingType.Constant.toLowerCase()) && (
            <Tooltip
                title={
                    (inputParams.meta.type as unknown) === 'coderule'
                        ? InputBindingType.CodeRule
                        : InputBindingType.Constant
                }
            >
                <i className="alpha-icon xs gear" />
            </Tooltip>
        )
    );
};

type Props = {
    packageName: string;
    appName: string;
    ui: DynamicUiModel;
    onsubmit: (values: WidgetValues, action: string) => Promise<void>;
    onHighlightBlock: (field: FormInputFieldData, inputId: string) => void;
    onReload?: () => void;
    showingBlocks: boolean;
    onTogglePageBlocks: () => void;
    form: FormInstance;
    bottomContainer?: boolean;
    onDownload?: (filePath: string) => void;
    onClose?: () => void;
    dataId?: string;
    sessionState?: string;
    openSections?: string[];
    setOpenSections?: (keys: string[]) => void;
    highlightInputSubject?: Subject<string>;
    setSettingsDialogVisible: (isVisible: boolean) => void;
    action: string;
    setAction: (action: string) => void;
    handleAction: (control: string, actionId: string) => Promise<void>;
    handleOidcFlowSuccess: (value: string) => Promise<void>;
    restricedModeEnabled?: boolean;
};

const DynamicControlsContainer: React.FC<Props> = ({
    ui,
    packageName,
    appName,
    onsubmit,
    onHighlightBlock,
    onReload,
    highlightInputSubject,
    showingBlocks,
    onTogglePageBlocks,
    bottomContainer,
    onDownload,
    onClose,
    dataId,
    sessionState,
    openSections,
    setOpenSections,
    form,
    setSettingsDialogVisible,
    action,
    setAction,
    handleAction,
    handleOidcFlowSuccess,
    restricedModeEnabled
}) => {
    const sections = React.useMemo(
        () => [null, ...new Set(ui.sections.filter(s => !s.parentSectionId && s.location !== SectionLocation.Bottom))],
        [ui.sections]
    );
    const defaultOpenSections = React.useMemo(
        () => ['section-general', ...sections.filter(x => x && !(x.isCollapsed === true)).map(s => `section-${s!.id}`)],
        [sections]
    );
    const [isCommentsModalVisible, setIsCommentsModalVisible] = useState(false);

    const handleClick = (id: string) => {
        setAction(id);
    };

    const handleRuntimeClosePostAction = React.useCallback(
        (runtimeAction: RuntimePostAction) => {
            if (runtimeAction.type !== 'Close' || !onClose) {
                return;
            }
            onClose();
        },
        [onClose]
    );

    const handleRuntimeDownloadPostAction = React.useCallback(
        (runtimeAction: RuntimePostAction) => {
            if (runtimeAction.type !== 'Download' || !runtimeAction.metadata.Url || !onDownload) {
                return;
            }
            if (confirm(`Do you want to download file produced after executing '${appName}' on '${packageName}'?`)) {
                onDownload(runtimeAction.metadata.Url);
            }
        },
        [appName, onDownload, packageName]
    );

    // Disabled exhaustive deps because it was causing multiple post actions handling
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleRuntimePostActions = () => {
        // Should handle on bottom container render only when bottom container is main
        const hasBottomContainer = !!ui.layoutConfiguration && ui.layoutConfiguration.sizeRatio === 0;
        const shouldHandleActions =
            (!bottomContainer && !hasBottomContainer) || (bottomContainer && hasBottomContainer);
        if (!ui.postActions || !ui.postActions.length || !shouldHandleActions) {
            return;
        }

        ui.postActions.forEach(async a => {
            switch (a.type) {
                case 'Download':
                    handleRuntimeDownloadPostAction(a);
                    break;
                case 'Close':
                    handleRuntimeClosePostAction(a);
                    break;
                default:
                    break;
            }
        });
    };

    const handleBottomPanelAction = async (actionId: string) => {
        setAction(actionId);
        var obj = Utils.prepareRuntimeDataObjectFromForm(form);
        await onsubmit(obj, actionId);
    };

    const checkInView = (elem: HTMLElement, container: HTMLElement) => {
        const { bottom, height, top } = elem.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();
        const headerHeight = 126;
        const footerHeight = 50;

        return top <= containerRect.top + headerHeight
            ? containerRect.top - top + headerHeight <= height
            : bottom - containerRect.bottom + footerHeight <= height;
    };

    let sub: React.MutableRefObject<Subscription | undefined> = React.useRef();
    React.useEffect(() => {
        const el = document.getElementsByClassName('alpha-form-controls')[0];

        if (el && ui.errors && ui.errors.length) {
            el.scrollTop = el.scrollHeight;
        }

        handleRuntimePostActions();

        if ((!openSections || openSections.length === 0) && setOpenSections) {
            setOpenSections(defaultOpenSections);
        }

        if (highlightInputSubject) {
            sub.current = highlightInputSubject.subscribe(id => {
                const container = document.getElementById(`alpha-application-form-${dataId ? dataId : 'undefined'}`);
                if (id && container) {
                    const input = container.querySelector(`[id='${id}']`);
                    if (input && !checkInView(input as HTMLElement, container as HTMLElement)) {
                        input.scrollIntoView({ behavior: 'auto', block: 'center' });
                    }
                }
            });
        }

        return () => {
            if (sub.current) {
                sub.current.unsubscribe();
            }
        };
        // Disabled exhaustive deps because it was causing multiple post actions handling
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const moreActionsMenu = (
        <Menu>
            {ui.actions &&
                ui.actions.length > 1 &&
                ui.actions.map((actions, index) => {
                    if (index === 0) {
                        return null;
                    }

                    return (
                        <Menu.Item
                            key={index}
                            onClick={() => handleAction('', actions.id)}
                            disabled={actions.isDisabled}
                        >
                            {actions.name}
                        </Menu.Item>
                    );
                })}
        </Menu>
    );

    const handleCommentFieldClick = (fieldId: string) => {
        ui.setCurrentCommentsField(fieldId);
        setIsCommentsModalVisible(true);
    };

    const handleCommentsModalCancelClick = () => {
        setIsCommentsModalVisible(false);
        ui.setCurrentComment('');
    };

    const handleValueChange = (changedValues: object) => {
        ui.setCurrentFieldsChange(changedValues);
    };

    const handleFormFinish = async () => {
        var obj = Utils.prepareRuntimeDataObjectFromForm(form);
        await onsubmit(obj, action);
    };

    const handleFinishFailed = ({ errorFields }: FormError) => {
        if (errorFields?.length) {
            const field = errorFields[0].name;
            form!.scrollToField(field, { behavior: 'smooth', block: 'center' });
        }
    };

    const handleEnterPress = (event: React.KeyboardEvent<HTMLFormElement>) => {
        const isTextArea = event.target instanceof HTMLTextAreaElement;
        // 13 is a key code for Enter
        if (event.keyCode === 13 && !isTextArea) {
            event.preventDefault();
        }
    };

    const getPopupContainerForMoreActions = (trigger: HTMLElement) => {
        let container = trigger.closest('.leftColumnContainer') as HTMLElement;

        if (!container) {
            return trigger.closest('.bottomContainer') as HTMLElement;
        }

        return container;
    };

    const renderOauthPreSubmitComponent = () => {
        const oauthPreSubmitAction = ui.preSubmitActions?.find(a =>
            ['OidcImplicitFlowAuthorize', 'OidcCodeFlowAuthorize'].includes(a.type)
        );
        if (!oauthPreSubmitAction) {
            return null;
        }

        const redirectUrl = window.location.protocol + '//' + window.location.host + '/openid-success';
        return (
            <div style={{ display: 'none' }}>
                <OAuth2Login
                    id={`${dataId}-oauth2-login-button`}
                    authorizationUrl={oauthPreSubmitAction.metadata.AuthorizationEndpoint}
                    clientId={oauthPreSubmitAction.metadata.ClientId}
                    responseType={oauthPreSubmitAction.metadata.ResponseType}
                    scope={oauthPreSubmitAction.metadata.Scope}
                    redirectUri={encodeURIComponent(redirectUrl)}
                    isCrossOrigin={oauthPreSubmitAction.metadata.IsCrossOrigin}
                    onSuccess={response => handleOidcFlowSuccess(response.toString())}
                    onFailure={response => console.error(response)}
                />
            </div>
        );
    };

    return !bottomContainer ? (
        <div className="alpha-application-form-container">
            <Form
                id={`alpha-application-form-${dataId ? dataId : 'undefined'}`}
                data-id={`alpha-application-form-${dataId ? dataId : 'undefined'}`}
                className="alpha-application-form"
                form={form}
                action={action}
                // onSubmit={handleSubmit}
                onKeyDown={handleEnterPress}
                onFinish={handleFormFinish}
                onFinishFailed={handleFinishFailed}
                onValuesChange={handleValueChange}
            >
                <DocumentHeaderRenderer
                    appName={appName}
                    packageName={packageName}
                    ui={ui}
                    onReload={onReload}
                    setSettingsDialogVisible={setSettingsDialogVisible}
                    sessionState={sessionState}
                    handleClick={handleClick}
                    dataId={dataId}
                    restricedModeEnabled={restricedModeEnabled}
                />
                <div className="alpha-form-controls">
                    <div className="scan-results-flex-container">
                        {renderOauthPreSubmitComponent()}
                        <DocumentSectionsRenderer
                            ui={ui}
                            form={form}
                            onHighlightBlock={onHighlightBlock}
                            highlightInputSubject={highlightInputSubject}
                            getGearIcon={getGearIcon}
                            onDownload={onDownload}
                            handleCommentFieldClick={handleCommentFieldClick}
                            handleAction={handleAction}
                            sections={sections}
                            openSections={openSections}
                            setOpenSections={setOpenSections}
                            defaultOpenSections={defaultOpenSections}
                        />
                        <div
                            style={{
                                flex: '1 1',
                                padding: '0 20px 18px 30px',
                                overflow: 'hidden',
                                maxWidth: '100%',
                                wordBreak: 'break-word'
                            }}
                        >
                            {ui.errors?.map((err, i) => (
                                <span key={`${err.errorCode}-${i}`} style={{ color: 'red' }}>
                                    {err.message}: {err.details}
                                </span>
                            ))}
                        </div>
                    </div>
                    {isCommentsModalVisible && (
                        <FieldCommentModal
                            isVisible={isCommentsModalVisible}
                            handleCancel={handleCommentsModalCancelClick}
                            ui={ui}
                        />
                    )}
                    <FormItem className="alpha-application-form-buttons extra-form-actions">
                        <HasPermission permissionClaim={AppPermissions.CanManageSessionsAndDocuments}>
                            <Row>
                                {ui.actions && ui.actions.length > 1 && sessionState !== 'Archived' && (
                                    <Col span={8}>
                                        <Dropdown
                                            overlay={moreActionsMenu}
                                            trigger={['click']}
                                            getPopupContainer={getPopupContainerForMoreActions}
                                        >
                                            <Button>More actions...</Button>
                                        </Dropdown>
                                    </Col>
                                )}
                                <Col
                                    span={
                                        (ui.actions && ui.actions.length > 1 && sessionState !== 'Archived' && 16) || 24
                                    }
                                    style={{ textAlign: 'right' }}
                                >
                                    <span style={{ marginRight: 10, color: '#ff4d4f' }}>
                                        {ui.actions[0] && ui.actions[0].message}
                                    </span>
                                </Col>
                            </Row>
                        </HasPermission>
                    </FormItem>
                </div>
            </Form>
        </div>
    ) : (
        <DynamicControlsBottomContainer
            ui={ui as DynamicUiModel}
            onHighlightBlock={onHighlightBlock}
            form={form!}
            getGearIcon={getGearIcon}
            handleAction={handleAction}
            onDownload={onDownload}
            handleActionClick={handleBottomPanelAction}
            onReload={onReload}
            handleFormFinish={handleFormFinish}
            showingBlocks={showingBlocks}
            onTogglePageBlocks={onTogglePageBlocks}
            dataId={dataId}
            appName={appName}
            highlightInputSubject={highlightInputSubject}
            restricedModeEnabled={restricedModeEnabled}
        />
    );
};
export default observer(DynamicControlsContainer);
