import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useSessionsStore } from '../stores/context';
import { SessionsList } from '../components';

const SessionsPage: React.FC = () => {
    const { sessionsVisualStore } = useSessionsStore();

    return <SessionsList store={sessionsVisualStore} />;
};

export default observer(SessionsPage);
