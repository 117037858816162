import * as React from 'react';
import { Alert } from 'antd';

export const PushServiceStatusBar: React.FC = () => {
    return (
        <Alert
            className="push-service-status-bar"
            message="Could not connect to the remote server. Reconnecting..."
            type="error"
            closable
            showIcon
        />
    );
};
