import * as React from 'react';

export default (offset: number = 300) => {
    const [scrollHeight, setScrollHeight] = React.useState(800);

    React.useEffect(() => {
        const adjustScrollHeight = () => setScrollHeight(window.innerHeight - offset);

        adjustScrollHeight();

        window.addEventListener('resize', adjustScrollHeight);

        return () => window.removeEventListener('resize', adjustScrollHeight);
    }, [offset]);

    return { scrollHeight };
};
