import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { FolderOpenOutlined, LoadingOutlined } from '@ant-design/icons';
import { Tooltip, Spin } from 'antd';
import { List, CellMeasurerCache, CellMeasurer, ListRowProps, ScrollParams } from 'react-virtualized';
import { ProjectsStore, ApplicationDefinitionsStore } from '../../common/stores';
import { PackageStateResult, PackageListItemModel, PackageListItemType } from '../../common/services/types';
import { Utils } from '../../common/misc/Utils';
import { NEW_ANALYSIS_CONTAINER_INITIAL_HEIGHT } from './NewContractDialog';
import stc from 'string-to-color';
import { Constants } from '../../common/misc/Constants';

const CONTAINER_TOP = 308;
const LARGE_NUMBER = 1000;
const LOAD_MORE_ROWS_OFFSET = 10;
type Props = {
    projectsStore: ProjectsStore;
    appStore: ApplicationDefinitionsStore;
    selectPackage: (pkg: PackageListItemModel, multiselect: boolean) => void;
    visible: boolean;
};

export const PackageList: React.FC<Props> = ({ projectsStore, selectPackage, visible }) => {
    const [ctrlDown, setCtrlDown] = React.useState<boolean>(false);
    const [selectedItemsHeight, setSelectedItemsHeight] = React.useState<{ [key: string]: number }>({});
    const [selectedDivs, setSelectedDiv] = React.useState({});
    const cache = React.useMemo(
        () =>
            new CellMeasurerCache({
                fixedWidth: true,
                defaultHeight: 55,
                keyMapper: rowIndex => projectsStore.packageListItems[rowIndex].id
            }),
        [projectsStore.packageListItems]
    );

    let listRef: React.RefObject<List> = React.useRef(null);

    React.useEffect(() => {
        document.addEventListener('keydown', ctrlDownEvent);
        document.addEventListener('keyup', ctrlUpEvent);

        const subscription = projectsStore.packageSelectionSubject.subscribe((id: string) => {
            const listContainer = document.getElementsByClassName('ReactVirtualized__Grid__innerScrollContainer')?.[0];
            listContainer.parentElement!.scrollTop = 0;

            setTimeout(() => {
                const firstListItem = listContainer?.children[0] as HTMLElement;
                const item = projectsStore.packageListItems.find(p => p.id === id);

                if (!firstListItem || !item) {
                    return;
                }

                Object.keys(selectedItemsHeight).forEach(x => delete selectedItemsHeight[x]);
                projectsStore.removeStickyPackageItems();
                projectsStore.clearPackageSelection();
                delete selectedDivs[id || ''];

                if (projectsStore.selectedPackageIds.length < Constants.documentSelectionLimit) {
                    selectedItemsHeight[item.id] = firstListItem.offsetHeight;
                }
                const obj = {};
                obj[item.id] = firstListItem;
                setSelectedDiv(obj);
                setSelectedItemsHeight(selectedItemsHeight);
                selectPackage(item, false);
            }, 100);
        });

        return () => {
            document.removeEventListener('keydown', ctrlDownEvent);
            document.removeEventListener('keyup', ctrlUpEvent);
            subscription.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (!visible) {
            projectsStore.removeStickedPackageItem();
            setSelectedItemsHeight({});
            setSelectedDiv({});
        }
    }, [projectsStore, visible]);

    const ctrlDownEvent = (ev: KeyboardEvent) => {
        if (ev.ctrlKey || ev.metaKey) {
            setCtrlDown(true);
        }
    };

    const ctrlUpEvent = (ev: KeyboardEvent) => {
        if (ev.key === 'Control' || ev.metaKey) {
            setCtrlDown(false);
        }
    };

    React.useEffect(() => {
        if (listRef && listRef.current) {
            cache.clearAll();
            listRef.current.recomputeRowHeights();
        }
    }, [cache, projectsStore.packageListItems]);

    const generateDocumentIcon = (documentName: string, type: PackageListItemType | undefined) => {
        if (type === PackageListItemType.PackageSet) {
            return <FolderOpenOutlined style={{ height: 16, width: 16, marginLeft: 2 }} />;
        }

        return <i className={Utils.generateDocumentIcon(documentName)} />;
    };

    const itemIsDisabledForMultiSelect = (item: PackageListItemModel) => {
        return (
            ctrlDown &&
            ((projectsStore.selectedPackageProject && item.projectId !== projectsStore.selectedPackageProject) ||
                (projectsStore.selectedPackage && item.type === PackageListItemType.PackageSet) ||
                (projectsStore.selectedPackage &&
                    projectsStore.selectedPackage.type === PackageListItemType.PackageSet))
        );
    };

    const handleSelect = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>, item: PackageListItemModel) => {
        if (itemIsDisabledForMultiSelect(item)) {
            return;
        }
        if (!projectsStore.selectedPackageIds.includes(item.id)) {
            if (projectsStore.selectedPackageIds.length === Constants.documentSelectionLimit) {
                return;
            }
            if (!ctrlDown) {
                Object.keys(selectedItemsHeight).forEach(x => delete selectedItemsHeight[x]);
                const id = projectsStore.stickedItemsPackageIds.find(x => x === item.id);
                if (id) {
                    projectsStore.removeStickedPackageItem(id);
                }
                delete selectedDivs[id || ''];
            }
            if (projectsStore.selectedPackageIds.length < Constants.documentSelectionLimit) {
                selectedItemsHeight[item.id] = ev.currentTarget.offsetHeight;
            }
            const obj = {};
            obj[item.id] = ev.currentTarget;
            setSelectedDiv(obj);
            setSelectedItemsHeight(selectedItemsHeight);
        } else {
            if (projectsStore.stickedItemsPackageIds.includes(item.id)) {
                projectsStore.removeStickedPackageItem(item.id);
            }
            delete selectedDivs[item.id];
            delete selectedItemsHeight[item.id];
            setSelectedItemsHeight(selectedItemsHeight);
        }

        selectPackage(item, ctrlDown);
    };

    const docItem = (item: PackageListItemModel, style: React.CSSProperties) => {
        return (
            <>
                <div
                    ref={el => {
                        if (
                            projectsStore.selectedPackageIds &&
                            projectsStore.selectedPackageIds.includes(item.id) &&
                            visible
                        ) {
                            selectedDivs[item.id] = el;
                        }
                    }}
                    style={{ ...style, borderBottom: '1px solid #E4E5ED', left: 6 }}
                    key={item.id}
                    className={`ant-list-item project-list-item 
                    ${projectsStore.selectedPackageIds && projectsStore.selectedPackageIds.includes(item.id) ? 'selected' : ''}
                    ${item.state !== PackageStateResult.Ready ? 'processing' : ''}
                    ${itemIsDisabledForMultiSelect(item) ? 'disabled' : ''}
                    ${item.state === PackageStateResult.Broken ? 'disabled' : ''}`}
                    onClick={ev => handleSelect(ev, item)}
                >
                    <Tooltip title={projectsStore.getProjectNameById(item.projectId)} placement="left">
                        <div className={`project-indicator ${projectsStore.projectsColors[item.projectId]}`} />
                    </Tooltip>
                    <div>{generateDocumentIcon(item.fileName, item.type)}</div>
                    <div>
                        <div>
                            <span style={{ fontSize: 14, whiteSpace: 'pre-wrap' }}>{item.fileName}</span>
                            {item.state !== PackageStateResult.Ready && item.state !== PackageStateResult.Broken && (
                                <Tooltip title="Processing...">
                                    <span className="info-label" style={{ marginLeft: 7 }}>
                                        <Spin
                                            indicator={
                                                <LoadingOutlined style={{ fontSize: 16, marginRight: 7 }} spin />
                                            }
                                        />
                                    </span>
                                </Tooltip>
                            )}
                        </div>
                        <div className="additional-info">
                            {item.state === PackageStateResult.Uploading && (
                                <span className="info-label">Uploading...</span>
                            )}
                            {item.state !== PackageStateResult.Uploading && item.uploadedTime && (
                                <span className="info-label">{Utils.formatDateStringShort(item.uploadedTime)}</span>
                            )}
                            {item.state !== PackageStateResult.Uploading && item.fileSizeBytes > 0 && (
                                <span className="info-label">{Utils.readableFileSize(item.fileSizeBytes, true)}</span>
                            )}
                            {item.userTags &&
                                item.userTags.map(t => (
                                    <span
                                        key={`${item.id}-${t}`}
                                        className="project-tag"
                                        style={{ background: `${stc(t)}` }}
                                    >
                                        {t}
                                    </span>
                                ))}
                            {item.state === PackageStateResult.Broken && (
                                <span
                                    key={`${item.id}-broken-tag`}
                                    className="project-tag broken-tag"
                                    style={{ background: '#B80C02' }}
                                >
                                    Broken
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                {/* <Divider style={{...style, ...dividerStyles}}/> */}
            </>
        );
    };

    const renderRow = ({ index, style, ...rest }: ListRowProps) => {
        return (
            <CellMeasurer cache={cache} rowIndex={index} {...rest}>
                {docItem(projectsStore.packageListItems[index], style)}
            </CellMeasurer>
        );
    };

    const getContainerHeight = (itemsHeight: {}) => {
        const itemsSticked = Object.keys(itemsHeight).filter(x => projectsStore.stickedItemsPackageIds.includes(x));
        return NEW_ANALYSIS_CONTAINER_INITIAL_HEIGHT - itemsSticked.map(x => itemsHeight[x]).reduce((a, b) => a + b, 0);
    };

    const handleScroll = (params: ScrollParams) => {
        loadMoreRows(params);

        const stickedItemsCount = projectsStore.stickedItemsPackageIds.length;
        if (stickedItemsCount === Constants.documentSelectionLimit || projectsStore.selectedPackageIds.length === 0) {
            return;
        }
        const boundaryValue =
            stickedItemsCount === 0
                ? CONTAINER_TOP
                : selectedItemsHeight[projectsStore.stickedItemsPackageIds[0]] + CONTAINER_TOP;
        const ids = Object.keys(selectedDivs).filter(x => !projectsStore.stickedItemsPackageIds.includes(x));
        if (!ids.length) {
            return;
        }
        const res = ids.map(id => {
            return { id: id, top: (selectedDivs[id] && selectedDivs[id].getBoundingClientRect().top) || LARGE_NUMBER };
        });
        const sorted = res.sort((a, b) => (a.top > b.top ? 1 : -1))[0];
        if (sorted.top < boundaryValue) {
            projectsStore.setStickedPackageItem(sorted.id);
        }
    };

    const loadMoreRows = ({ clientHeight, scrollHeight, scrollTop }: ScrollParams) => {
        if (projectsStore.packagesLoading) {
            return;
        }

        if (!projectsStore.packageCount || projectsStore.packageCount >= projectsStore.totalPackageCount) {
            return;
        }

        if (clientHeight + scrollTop + LOAD_MORE_ROWS_OFFSET < scrollHeight) {
            return;
        }

        projectsStore.setPackagesPage(projectsStore.packagesPage + 1);
        projectsStore.searchPackages();
    };

    return (
        <>
            {projectsStore.isLoading ? (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'middle',
                        flexFlow: 'column',
                        textAlign: 'center'
                    }}
                >
                    <Spin />
                    <div>Loading packages...</div>
                </div>
            ) : (
                <div style={{ position: 'relative' }}>
                    {projectsStore.stickedItemsPackageIds &&
                        projectsStore.stickedItemsPackageIds.map((x, index) => {
                            const style = { height: selectedItemsHeight[x] } as React.CSSProperties;
                            if (index === projectsStore.stickedItemsPackageIds.length - 1) {
                                style.boxShadow = '0px 2px 4px rgba(0, 0, 0, 0.15)';
                            }
                            return (
                                <div key="x" style={{ paddingLeft: '10px' }}>
                                    {docItem(projectsStore.packageListItems.find(s => s.id === x)!, style)}
                                </div>
                            );
                        })}
                    <List
                        ref={listRef}
                        className="ant-list ant-list-sm ant-list-split"
                        style={{
                            outline: 'none',
                            opacity: projectsStore.packagesLoading ? 0.4 : undefined,
                            paddingLeft: '10px'
                        }}
                        width={621}
                        height={getContainerHeight(selectedItemsHeight)}
                        rowHeight={cache.rowHeight}
                        deferredMeasurementCache={cache}
                        rowCount={projectsStore.packageListItems.length}
                        overscanRowCount={15}
                        rowRenderer={renderRow}
                        onScroll={handleScroll}
                    />

                    {projectsStore.packagesLoading && (
                        <div className="package-list-overlay">
                            <Spin />
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default observer(PackageList);
