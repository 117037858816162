import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Tooltip } from 'antd';
import { ViewInDocumentIcon } from '../../../custom_shared/icons';
import { ClickableIcon } from '../../../custom_shared/components';
import { InputBaseModel } from '../../models';
import { useAppStore } from '../../stores';

interface Props {
    input: InputBaseModel;
}

const ViewInDocumentButton: React.FC<Props> = ({ input }) => {
    const { documentVisualStore, documentPreviewStore } = useAppStore();

    const highlightBlock = () => documentVisualStore.highlightBlockByInputId(input.guid, undefined, true);

    const viewInDocument = () => {
        if (documentPreviewStore.searchInputVisible) {
            documentPreviewStore.setSearchInputVisible(false);
        }

        if (documentPreviewStore.expanded) {
            return highlightBlock();
        }

        documentPreviewStore.setExpanded(true);

        setTimeout(highlightBlock, 100);
    };

    return (
        <Tooltip title="View in document">
            <ClickableIcon icon={ViewInDocumentIcon} onClick={viewInDocument} />
        </Tooltip>
    );
};

export default observer(ViewInDocumentButton);
