import * as React from 'react';
import './ButtonWithIcon.less';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    label: string;
    icon: React.ReactNode;
    disabled?: boolean;
}

const ButtonWithIcon: React.FC<Props> = ({ label, icon, disabled, onClick, ...rest }: Props) => {
    return (
        <div
            className={`button-with-icon ${disabled ? 'disabled' : ''}`}
            onClick={disabled ? undefined : onClick}
            {...rest}
        >
            {icon}
            <span>{label}</span>
        </div>
    );
};

export default ButtonWithIcon;
