import * as React from 'react';
import { useState, ChangeEvent } from 'react';
import { DynamicControlProps } from './types';
import { Tooltip, InputNumber, Form, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { getConfLevelColor, ConfidenceLevel } from './ConfidenceLevel';
import { Utils } from '../../../common/misc/Utils';
import { Rule } from 'antd/lib/form';
import { FormInputParams } from '../../../common/services/types';
import { DynamicModalForm } from './DynamicModalForm';
import { CommentOutlined, QuestionCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { ExtendedMaskedInput } from './ExtendedMaskInput';
import { Subscription } from 'rxjs';

const formatDecimalInput = (str: string) => str.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const DynamicInput: React.FC<DynamicControlProps> = ({
    inputParams,
    form,
    onHighlightBlock,
    highlightInputSubject,
    getGearIcon,
    onAction,
    ui,
    highlightOnFocus,
    handleCommentFieldClick
}) => {
    const getInitialValue = React.useCallback(() => {
        if (['number', 'decimal', 'percentage'].indexOf(inputParams.type) > -1 && inputParams.value) {
            if (inputParams.type === 'decimal') {
                return Number(inputParams.value.toString().replace(/,/g, ''));
            }
            return typeof inputParams.value === 'number'
                ? inputParams.value
                : Number((inputParams.value as string).replace(/,/g, ''));
        }
        return inputParams.value ? inputParams.value.toString() : '';
    }, [inputParams.type, inputParams.value]);

    const [value, setValue] = useState(getInitialValue());
    const [isHighlighted, setIsHighlighted] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const initialValue = React.useMemo(getInitialValue, [getInitialValue]);

    let sub: React.MutableRefObject<Subscription | undefined> = React.useRef();

    const decimalInputRef = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        if (inputParams.type === 'decimal' && !isFocused) {
            if (value === null || (typeof value === 'string' && !value.length)) {
                return;
            }

            if (decimalInputRef.current) {
                const numberValue = Number(value);
                const fractionDigits = inputParams.behavior?.hideZeros && Number.isInteger(numberValue) ? 0 : 2;

                decimalInputRef.current.value = formatDecimalInput(numberValue.toFixed(fractionDigits));
            }
        }
    });

    React.useEffect(() => {
        if (highlightInputSubject) {
            sub.current = highlightInputSubject.subscribe(id => {
                if (id === inputParams.id) {
                    setIsHighlighted(true);
                } else {
                    setIsHighlighted(false);
                }
            });
        }

        return () => {
            if (sub.current) {
                sub.current.unsubscribe();
            }
        };
    }, [highlightInputSubject, inputParams.id]);

    const onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            const target = event.target as HTMLInputElement;
            target.blur();
        }
    };

    const handleInputNumberChange = (val: number | string) => setValue(val);

    const handleInputTextChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) =>
        setValue(event.target.value);

    const runAction = () => {
        const actionId = inputParams.behavior && inputParams.behavior.onchange;

        if (!actionId || value === initialValue) {
            return;
        }

        if (actionId.value) {
            onAction(inputParams.id, actionId.value, value);
        } else {
            onAction(inputParams.id, actionId, value);
        }
    };

    let type = 'number';
    if (inputParams.type === 'string') {
        type = 'text';
    }

    const highlightField = () => {
        const fieldData = Utils.extractFieldData(inputParams);
        if (fieldData) {
            onHighlightBlock(fieldData, inputParams.id);
        }
    };

    const handleFocus = () => {
        setIsFocused(true);

        if (highlightOnFocus) {
            highlightField();
        }
    };

    const handleBlur = () => {
        setIsFocused(false);
        runAction();
    };

    const getModalFormControl = (params: FormInputParams) => {
        if (!params.modalForm || !ui.actions || !ui.actions.length) {
            return null;
        }

        return (
            <DynamicModalForm
                inputParams={inputParams}
                form={form}
                getGearIcon={getGearIcon}
                onAction={onAction}
                onHighlightBlock={onHighlightBlock}
                ui={ui}
                highlightInputSubject={highlightInputSubject}
            />
        );
    };

    const getTooltip = () => {
        if (!inputParams.behavior || !inputParams.behavior.inputTooltip) {
            return null;
        }
        return (
            <Tooltip overlayClassName="alpha-input-tooltip" title={inputParams.behavior.inputTooltip} placement="top">
                <QuestionCircleOutlined style={{ marginLeft: 6, verticalAlign: 'middle' }} />
            </Tooltip>
        );
    };

    const getCommentStyle = () => {
        return ui.comments.find(c => c.fieldId === inputParams.id)?.comments.length
            ? 'has-comments comments-icon'
            : 'comments-icon';
    };

    const label = (
        <>
            <span className="alpha-doc-control-label with-controls">
                <Tooltip title={inputParams.name}>
                    <span className="alpha-doc-control-name">{inputParams.name}</span>
                </Tooltip>
                <span className="controls-container">
                    {getModalFormControl(inputParams)}

                    {inputParams.behavior && inputParams.behavior.readonly ? (
                        <></>
                    ) : (
                        <ConfidenceLevel input={inputParams} />
                    )}

                    {inputParams.meta && inputParams.meta.isEdited === true && (
                        <Tooltip title="Edited">
                            <WarningOutlined style={{ color: 'red', marginBottom: 2 }} />
                        </Tooltip>
                    )}
                    {inputParams.behavior?.comments && (
                        <CommentOutlined
                            className={getCommentStyle()}
                            onClick={() => handleCommentFieldClick!(inputParams.id)}
                        />
                    )}
                    {(inputParams.meta &&
                        inputParams.meta.field &&
                        inputParams.value != null &&
                        highlightOnFocus !== true && (
                            <Tooltip title="View in document">
                                <i className="alpha-icon xs form-anchor" onClick={highlightField} />
                            </Tooltip>
                        )) ||
                        getGearIcon(inputParams)}
                    {getTooltip()}
                </span>
            </span>
        </>
    );

    const getClassName = () => {
        return inputParams.value && !(inputParams.behavior && inputParams.behavior.readonly)
            ? getConfLevelColor(inputParams) + getInputCssClass()
            : getInputCssClass();
    };

    const getMaskedInput = (val: string) => {
        if (val === null || !val.length) {
            return val;
        }
        let res = `${val}`.replace(new RegExp(inputParams.inputMask!, 'g'), ',');
        return res;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const reformatMaskedInput = (params: any) => {
        let val = params.value as string;
        if (!val && !params.input) {
            params.visibleValue = params.value = '';
            return params;
        }
        const regex = /\B(?=(\d{3})+(?!\d))/g;
        if (params.selection.start === params.selection.end) {
            params.visibleValue = `${val}${(params.input && params.input) || ''}`.replace(regex, ',');
        } else {
            const int = Math.trunc(params.value.length / 3);
            const offset = !params.value.match(/^\d+$/) ? 0 : params.value.length % 3 === 0 ? int - 1 : int;
            val =
                `${params.value.substring(0, params.selection.start - offset)}${params.value.substring(params.selection.end - offset)}`.replace(
                    /,/g,
                    ''
                );
            params.visibleValue = `${val}`.replace(regex, ',');
        }
        params.selection.start = params.visibleValue.length;
        params.selection.end = params.visibleValue.length;
        return params;
    };

    const getEditor = () => {
        if (inputParams.behavior && inputParams.behavior.multiline) {
            return (
                <TextArea
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    className={getClassName()}
                    style={{
                        padding: '8px 10px',
                        resize: 'vertical',
                        ...Utils.buildDynamicInputStyles(inputParams)
                    }}
                    onChange={event => {
                        event.persist();
                        handleInputTextChange(event);
                    }}
                    maxLength={
                        inputParams.validation &&
                        inputParams.validation.maxLength &&
                        inputParams.validation.maxLength.value
                    }
                    disabled={inputParams.behavior && inputParams.behavior.readonly}
                    autoSize={{ minRows: 1, maxRows: 10 }}
                />
            );
        } else {
            switch (inputParams.type) {
                case 'number':
                    return (
                        <InputNumber
                            autoComplete="off"
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            onKeyUp={onKeyUp}
                            style={Utils.buildDynamicInputStyles(inputParams)}
                            formatter={val =>
                                inputParams.inputMask
                                    ? getMaskedInput(val as string)
                                    : `${Number.isNaN(val as number) ? '' : val}`
                            }
                            parser={val => (val ? val.toString().replace(/\D/g, '') : '')}
                            className={`${getClassName()} ${inputParams.type}-type`}
                            onChange={handleInputNumberChange}
                            maxLength={
                                inputParams.validation &&
                                inputParams.validation.maxLength &&
                                inputParams.validation.maxLength.value
                            }
                            disabled={inputParams.behavior && inputParams.behavior.readonly}
                        />
                    );

                case 'decimal':
                    return (
                        <InputNumber
                            ref={decimalInputRef}
                            autoComplete="off"
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            onKeyUp={onKeyUp}
                            step={0.01}
                            precision={2}
                            style={Utils.buildDynamicInputStyles(inputParams)}
                            formatter={val => (val ? formatDecimalInput(val.toString()) : '')}
                            parser={val => (val ? val.toString().replace(/,/g, '') : '')}
                            className={`${getClassName()} ${inputParams.type}-type`}
                            onChange={handleInputNumberChange}
                            maxLength={
                                inputParams.validation &&
                                inputParams.validation.maxLength &&
                                inputParams.validation.maxLength.value
                            }
                            disabled={inputParams.behavior && inputParams.behavior.readonly}
                        />
                    );
                case 'numberWithKeyword':
                    return (
                        <ExtendedMaskedInput
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            className="input-mask"
                            reformat={reformatMaskedInput}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                e.target.value = e.target.value.replace(/,/g, '');
                                handleInputTextChange(e);
                            }}
                        />
                    );
                case 'percentage':
                    return (
                        <InputNumber
                            min={0}
                            max={100}
                            onChange={handleInputNumberChange}
                            onKeyUp={onKeyUp}
                            onBlur={handleBlur}
                        />
                    );
                default:
                    return (
                        <Input
                            autoComplete="off"
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            onKeyUp={onKeyUp}
                            className={getClassName()}
                            type={type}
                            style={{ background: highlightInput(), ...Utils.buildDynamicInputStyles(inputParams) }}
                            onChange={event => {
                                event.persist();
                                handleInputTextChange(event);
                            }}
                            maxLength={
                                inputParams.validation &&
                                inputParams.validation.maxLength &&
                                inputParams.validation.maxLength.value
                            }
                            disabled={inputParams.behavior && inputParams.behavior.readonly}
                            title={form.getFieldValue(inputParams.id)}
                        />
                    );
            }
        }
    };

    const highlightInput = () => {
        if (!inputParams.behavior || inputParams.behavior.highlightingInput !== true || !value) {
            return;
        }

        const lowerCaseVal = value.toString().toLowerCase();

        switch (lowerCaseVal) {
            case 'true':
                return '#cbffcb';
            case 'false':
                return '#ff9d9d';
            default:
                return;
        }
    };

    const getInputCssClass = () => {
        return isHighlighted ? ' iota-contract-shadow' : '';
    };

    const getValueFromEvent = (event: React.FormEvent<HTMLInputElement> | number) => {
        if (!event || !(event as React.FormEvent<HTMLInputElement>).currentTarget) {
            return event;
        }
        const eventVal = (event as React.FormEvent<HTMLInputElement>).currentTarget.value;
        let val = inputParams.type === 'number' && eventVal ? Number(eventVal) : eventVal;
        val = inputParams.type === 'numberWithKeyword' ? (val as string).replace(/,/g, '') : val;
        return val;
    };

    const getRules = () => {
        const fieldType =
            inputParams.type === 'decimal' || inputParams.type === 'percentage'
                ? 'number'
                : inputParams.type === 'numberWithKeyword'
                  ? 'string'
                  : inputParams.type;
        const baseRules = [
            {
                required:
                    inputParams.validation && inputParams.validation.required && inputParams.validation.required.value,
                type: fieldType,
                message:
                    (inputParams.validation &&
                        inputParams.validation.required &&
                        inputParams.validation.required.message) ||
                    `${inputParams.name} is required`
            }
        ] as Rule[];

        if (inputParams.validation && inputParams.validation.maxLength) {
            baseRules.push({
                max: inputParams.validation.maxLength.value,
                type: fieldType,
                message:
                    (inputParams.validation &&
                        inputParams.validation.maxLength &&
                        inputParams.validation.maxLength.message) ||
                    `${inputParams.name} should not exceed ${inputParams.validation.maxLength.value} characters`
            });
        }

        if (inputParams.validation && inputParams.validation.regex) {
            baseRules.push({
                type: fieldType,
                pattern: new RegExp(inputParams.validation.regex.value),
                message: inputParams.validation && inputParams.validation.regex && inputParams.validation.regex.message
            });
        }

        if (inputParams.type === 'percentage') {
            baseRules.push({
                validator: (rule, val) => {
                    if (val > 100) {
                        return Promise.reject('Percentage value should be less than or equal to 100');
                    }

                    if (val < 0) {
                        return Promise.reject('Percentage value should be greater than or equal to 0');
                    }

                    return Promise.resolve();
                }
            });
        }

        return baseRules;
    };

    return (
        <Form.Item
            data-id={inputParams.id}
            data-type={inputParams.controlType}
            label={label}
            colon={false}
            labelCol={{ span: 24 }}
            className={type === 'text' && inputParams.behavior && inputParams.behavior.width === 1 ? 'wide' : ''}
            name={inputParams.id}
            getValueFromEvent={getValueFromEvent}
            rules={getRules()}
            initialValue={value}
        >
            {getEditor()}
        </Form.Item>
    );
};
