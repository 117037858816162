import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { InputSkeleton } from '..';
import { InputBaseModel } from '../../models';
import { InstanceUtils } from '../../misc';
import { InputWithMeta } from '../../componentTypes';

const isComponentWithMeta = (
    component: React.FunctionComponent<ComponentProps>
): component is InputWithMeta<ComponentProps> => 'meta' in component;

interface ComponentProps {
    input: InputBaseModel;
}

interface Props {
    input?: InputBaseModel;
    component?: React.FunctionComponent<ComponentProps>;
}

const InputRenderer: React.FC<Props> = ({ input, component: Component }) => {
    if (!input || !Component) {
        return null;
    }

    if (InstanceUtils.isInputWithSource(input) && input.sourceProvider.loading) {
        return <InputSkeleton />;
    }

    if (!isComponentWithMeta(Component)) {
        console.error(`Component for input "${input.id} ${input.guid}" does not have meta attribute`);
        return null;
    }

    if (input.constructor.name !== Component.meta.inputType) {
        console.error(
            `Input type mismatch: component requires "${Component.meta.inputType}" input type, but received "${input.constructor.name}" for input "${input.id} ${input.guid}"`
        );
        return null;
    }

    return <Component input={input} />;
};

export default observer(InputRenderer);
