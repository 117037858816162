import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import './SessionInfoLabel.less';

type Props = {
    placement: 'bottom' | 'left';
    infoText?: string | null;
};

const SessionInfoLabel: React.FC<Props> = ({ infoText, placement }) => {
    if (!infoText || infoText.trim() === '') {
        return null;
    }

    return (
        <Tooltip title={infoText} placement={placement}>
            <InfoCircleOutlined className="session-info-icon" />
        </Tooltip>
    );
};

export default observer(SessionInfoLabel);
