import { runInAction } from 'mobx';
import { Observable } from 'rxjs';
import ManagePushClient from '../services/ManagePushClient';
import { IotaSessionResult } from '../services/types';
import { CustomSessionChangeResult } from '../../custom_shared/types';

export default class IotaSessionsStore {
    public isPushServiceConnected: boolean = true;

    iotaSessionChanges: Observable<IotaSessionResult | CustomSessionChangeResult<unknown>>;

    constructor() {
        ManagePushClient.ConnectionStatusSubject.subscribe(connected => {
            runInAction(() => (this.isPushServiceConnected = connected));
        });

        const iotaPushClient = new ManagePushClient();
        const iotaSessionsObs = iotaPushClient.createIotaSessionListener().publish();

        this.iotaSessionChanges = iotaSessionsObs.map(s => {
            return s;
        });
        iotaSessionsObs.connect();
    }
}
