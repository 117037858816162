import * as React from 'react';
import { HomeVisualStore } from '../../analysis/stores';
import { ProjectsStore } from '../../common/stores';
import { Modal, Button, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { SelectValue } from 'antd/lib/select';

type Props = {
    homeStore: HomeVisualStore;
    projectsStore: ProjectsStore;
};

const ProjectsSelectionDialog: React.FC<Props> = ({ homeStore, projectsStore }) => {
    return (
        <Modal
            zIndex={20000}
            visible={homeStore.showProjectSelectionModal}
            onCancel={homeStore.cancelProjectSelection}
            onOk={homeStore.confirmProjectSelection}
            centered
            maskClosable={false}
            footer={[
                <Button
                    key="project-for-upload-confirm"
                    type="primary"
                    disabled={!homeStore.selectedProjectToUpload}
                    onClick={homeStore.confirmProjectSelection}
                    loading={homeStore.uploadingFile}
                >
                    Upload
                </Button>,
                <Button onClick={homeStore.cancelProjectSelection} key="project-for-upload-cancel">
                    Cancel
                </Button>
            ]}
        >
            <div style={{ textAlign: 'center' }}>
                <span style={{ marginRight: 12 }}>Project: </span>
                <Select
                    value={homeStore.selectedProjectToUpload}
                    style={{ width: 300, margin: '30px auto 20px', textAlign: 'left' }}
                    showSearch
                    onChange={(val: SelectValue) => {
                        homeStore.setSelectedProjectToUpload(val ? val.toString() : undefined);
                    }}
                    getPopupContainer={trigger => trigger.parentElement as HTMLElement}
                    dropdownStyle={{ textAlign: 'left' }}
                    filterOption={(input, option) => option?.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                    {projectsStore.projects &&
                        projectsStore.projects.map(p => (
                            <Select.Option key={p.id} value={p.id}>
                                {p.name}
                            </Select.Option>
                        ))}
                </Select>
            </div>
        </Modal>
    );
};

export default observer(ProjectsSelectionDialog);
