import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Form } from 'antd';
import { LayersTabModel } from '../../models';
import { PanelSwitch } from '../../components';
import { LayoutRendererProps } from '../../componentTypes';
import { useLayoutRendererStyle } from '../../hooks';
import LayerRenderer from './LayerRenderer';
import './LayersLayoutRenderer.less';

interface Props extends LayoutRendererProps {
    tab: LayersTabModel;
}

const LayersLayoutRenderer: React.FC<Props> = (props: Props) => {
    const { tab, formKey, form, onValuesChange, getComponentRows } = props;

    const rendererRef = React.useRef<HTMLDivElement>(null);

    const style = useLayoutRendererStyle(tab.id, rendererRef);

    const layerRefs = React.useRef<Map<string, React.RefObject<HTMLDivElement>>>(new Map());

    const addLayerRef = React.useCallback((layerId: string, layerRef: React.RefObject<HTMLDivElement>) => {
        layerRefs.current.set(layerId, layerRef);
    }, []);

    const onPanelSwitchClick = (layerId: string) => {
        const layerRef = layerRefs.current.get(layerId);

        if (layerRef && layerRef.current) {
            layerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <>
            <PanelSwitch
                options={tab.layers.map(layer => ({ id: layer.id, label: layer.name }))}
                onClick={onPanelSwitchClick}
            />

            <div ref={rendererRef} className="layers-layout-renderer-container custom-scroll" style={style}>
                <Form id={tab.id} key={formKey} form={form} onValuesChange={onValuesChange}>
                    <div className="layers-layout-content">
                        {tab.layers.map(layer => (
                            <LayerRenderer
                                key={layer.id}
                                tab={tab}
                                layer={layer}
                                getComponentRows={getComponentRows}
                                addLayerRef={addLayerRef}
                            />
                        ))}
                    </div>
                </Form>
            </div>
        </>
    );
};

export default observer(LayersLayoutRenderer);
