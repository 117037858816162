import { observable, action, reaction, makeObservable } from 'mobx';
import { z } from 'zod';
import { Disposer } from '../../custom_shared/misc';
import { RuntimeDataStore } from '../../custom_shared/stores';
import SessionStore from './SessionStore';

export enum DummyFormInputIds {
    Field1StringValue = 'field1_string_value',
    Field1NumberValue = 'field1_number_value'
}

const dummyFormInputIds = Object.values(DummyFormInputIds) as string[];

const dummyFormSchema = z.object({
    [DummyFormInputIds.Field1StringValue]: z.string(),
    [DummyFormInputIds.Field1NumberValue]: z.number()
});

type DummyForm = z.infer<typeof dummyFormSchema>;

export default class DummyFormStore extends Disposer {
    static readonly EntryId = 'DummyForm';

    values: DummyForm = {
        [DummyFormInputIds.Field1StringValue]: '',
        [DummyFormInputIds.Field1NumberValue]: 0
    };

    constructor(
        private readonly sessionStore: SessionStore,
        private readonly runtimeDataStore: RuntimeDataStore
    ) {
        super();

        makeObservable(this, {
            values: observable,
            setValues: action
        });

        this.setupRuntimeDataReaction();
    }

    setupRuntimeDataReaction() {
        this.reactions.push(
            reaction(
                () => this.sessionStore.runtimeData,
                runtimeData => {
                    const runtimeDataEntry = runtimeData[DummyFormStore.EntryId];

                    if (runtimeDataEntry) {
                        this.setValues(runtimeDataEntry.value);
                    } else {
                        this.setInitialData();
                    }
                }
            )
        );
    }

    setInitialData() {
        const values = this.sessionStore.initialInputs
            .filter(input => dummyFormInputIds.includes(input.id))
            .reduce(
                (acc, input) => {
                    acc[input.id] = input.initialValue;
                    return acc;
                },
                { ...this.values }
            );

        this.setValues(values);
    }

    setValues(data: unknown) {
        const parseResult = dummyFormSchema.safeParse(data);

        if (!parseResult.success) {
            return;
        }

        this.values = parseResult.data;

        this.runtimeDataStore.add(DummyFormStore.EntryId, this.values);
    }

    dispose() {
        this.disposeReactions();
    }
}
