import { LayoutSettingsBaseDto, LayoutSettingsType } from '../../types';

export default abstract class LayoutSettingsBaseModel {
    readonly type: LayoutSettingsType;

    constructor(data: LayoutSettingsBaseDto) {
        this.type = data.type;
    }

    abstract get label(): string;

    abstract get dto(): LayoutSettingsBaseDto;
}
