import { action, computed, observable, reaction, makeObservable } from 'mobx';
import { ProjectsStore, TabsStore, FileOperationsStore } from '../../common/stores';
import { RcFile } from 'antd/lib/upload';
import { AnalyzeVisualStore } from '.';
import { PackageListItemType, PackageSetType } from '../../common/services/types';
import { Utils } from '../../common/misc/Utils';
import { message } from 'antd';

export const LOAD_MORE_BUTTON_ID = 'load-more-button';
export default class HomeVisualStore {
    showNewContractModal: boolean = false;

    showProjectSelectionModal: boolean = false;

    fileToUpload: RcFile | undefined = undefined;

    filesToUpload: RcFile[] | undefined = undefined;

    selectedProjectToUpload: string | undefined = undefined;

    uploadingFile: boolean = false;

    get recentFinishedSessions() {
        return this.analyzeStore.recentFinishedSessions;
    }

    get openSessions() {
        return this.analyzeStore.openSessions;
    }

    get allUsers() {
        return this.projectsStore.allUsers;
    }

    constructor(
        private projectsStore: ProjectsStore,
        private analyzeStore: AnalyzeVisualStore,
        private tabsStore: TabsStore,
        private fileStore: FileOperationsStore
    ) {
        makeObservable(this, {
            showNewContractModal: observable,
            showProjectSelectionModal: observable,
            fileToUpload: observable,
            filesToUpload: observable,
            selectedProjectToUpload: observable,
            uploadingFile: observable,
            recentFinishedSessions: computed,
            openSessions: computed,
            allUsers: computed,
            setFileToUpload: action.bound,
            setFilesToUpload: action.bound,
            setShowProjectSelectionModal: action.bound,
            setSelectedProjectToUpload: action.bound,
            cancelProjectSelection: action.bound,
            setFileUploading: action.bound,
            confirmProjectSelection: action.bound,
            setShowNewContractModal: action.bound,
            analyzePackage: action.bound,
            analyzeMultiplePackages: action.bound
        });

        reaction(
            () => this.fileStore.isNewContractModalVisibile,
            p => {
                if (p) {
                    this.tabsStore.resetSelection();
                    this.setShowNewContractModal(true);
                }
            }
        );
    }

    setFileToUpload(file: RcFile | undefined) {
        this.fileToUpload = file;
    }

    setFilesToUpload(files: RcFile[] | undefined) {
        this.filesToUpload = files;
    }

    setShowProjectSelectionModal(show: boolean) {
        this.showProjectSelectionModal = show;
    }

    setSelectedProjectToUpload(id: string | undefined) {
        this.selectedProjectToUpload = id;
    }

    cancelProjectSelection() {
        this.setFileToUpload(undefined);
        this.setFilesToUpload(undefined);
        this.setShowProjectSelectionModal(false);
    }

    setFileUploading(loading: boolean) {
        this.uploadingFile = loading;
    }

    async confirmProjectSelection() {
        this.setFileUploading(true);

        if (this.selectedProjectToUpload) {
            const formData = new FormData();
            if (this.filesToUpload) {
                this.filesToUpload.forEach(file => {
                    formData.append('files', file);
                });
                const dateTimeString = Utils.formatDateLong(new Date());
                formData.append('type', PackageSetType.Folder);
                formData.append('name', `${dateTimeString} - ${this.filesToUpload.length} documents`);
                await this.projectsStore.uploadPackageSet(formData, this.selectedProjectToUpload);
                message.success('Files uploaded successfully.');
            } else if (this.fileToUpload) {
                formData.append('file', this.fileToUpload);
                await this.projectsStore.uploadPackage(formData, this.selectedProjectToUpload);
                message.success(`${this.fileToUpload.name} file uploaded successfully.`);
            }
        }
        this.cancelProjectSelection();
        this.setFileUploading(false);

        if (!this.showNewContractModal) {
            this.setShowNewContractModal(true);
        }
    }

    setShowNewContractModal(show: boolean) {
        if (!show) {
            this.fileStore.setIsFileUploadModalVisibile(false);
        }
        this.showNewContractModal = show;
    }

    async analyzePackage(
        pkgId: string,
        appId: string,
        pkgName: string,
        extension: string,
        type: PackageListItemType = PackageListItemType.Package,
        appName: string = ''
    ) {
        this.setShowNewContractModal(false);
        this.analyzeStore.analyzePackage(pkgId, appId, pkgName, extension, type, appName);
    }

    async analyzeMultiplePackages(
        pkgIds: string[],
        appId: string,
        pkgName: string,
        extension: string,
        appName: string = ''
    ) {
        this.setShowNewContractModal(false);

        this.analyzeStore.analyzeMultiplePackages(pkgIds, appId, pkgName, extension, appName);
    }
}
