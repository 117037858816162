import { MsalAuthProvider, LoginType } from 'react-aad-msal';

// Msal Configurations
const config = {
    auth: {
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_ALPHA_GRAPH_TENANT_ID}`,
        clientId: <string>process.env.REACT_APP_ALPHA_GRAPH_CLIENT_ID,
        redirectUri: <string>process.env.REACT_APP_ALPHA_GRAPH_REDIRECT_URL
    },
    cache: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        cacheLocation: <any>'localStorage',
        storeAuthStateInCookie: true
    }
};

// Authentication Parameters
export const authenticationParameters = {
    scopes: [
        'user.read',
        'people.read',
        'people.read.all',
        'user.readbasic.all',
        'contacts.read',
        'Mail.Read',
        'Mail.Read.Shared',
        'Mail.ReadBasic',
        'Files.Read',
        'Files.Read.All',
        'Files.Read.Selected',
        'Sites.FullControl.All',
        'Sites.Manage.All',
        'Sites.Read.All'
    ]
};

// Options
const options = {
    loginType: LoginType.Popup
};

const authProvider = new MsalAuthProvider(config, authenticationParameters, options);
export default authProvider;
