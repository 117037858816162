import * as React from 'react';
import { SearchVisualStore } from '../../stores';
import { SearchResultModel, SearchResultBasicModelData } from '../../types';
import { observer } from 'mobx-react-lite';
import { Utils } from '../../../common/misc/Utils';

type Props = {
    store: SearchVisualStore;
    result: SearchResultModel;
    style?: React.CSSProperties;
};

const BasicSearchResult: React.FC<Props> = ({ store, result, style }) => {
    if (result.type !== 'BASIC') {
        return null;
    }

    const item = result.data as SearchResultBasicModelData;

    if (!item) {
        return null;
    }

    const generateDocumentIcon = (documentName: string) => <i className={Utils.generateDocumentIcon(documentName)} />;

    const handlePackageLinkClick = (packageId: string) => {
        store.downloadPackage(packageId);
    };

    return (
        <div style={{ cursor: 'default', ...style }} key={item.id} className={'ant-list-item project-list-item'}>
            <div>{generateDocumentIcon(item.fileName)}</div>
            <div>
                <div>
                    <span
                        style={{
                            fontSize: 13,
                            cursor: 'pointer',
                            color: '#2750b7',
                            textDecoration: 'underline'
                        }}
                        onClick={() => handlePackageLinkClick(item.packageId)}
                    >
                        {item.fileName}
                    </span>
                </div>
                <div className="additional-info">
                    <div style={{ margin: '3px 0' }}>
                        {item.tags &&
                            store.splitTags(item.tags).map((t, i) => (
                                <span
                                    key={`${item.packageId}-${t.id}-${i}`}
                                    style={{ borderRadius: 4 }}
                                    className={`project-tag selectable ${store.selectedTags.find(tag => store.checkIfTagsAreEqual(tag, t)) ? 'selected' : ''}`}
                                    onClick={() => store.selectTag(t)}
                                >
                                    {t.id}: {t.values.join(', ')}
                                </span>
                            ))}
                    </div>
                    <div
                        className="search-blocks-preview"
                        onClick={() => store.setHighlightPreview(item.text, item.id)}
                        style={{
                            background:
                                store.highlightedBlockId && store.highlightedBlockId === item.id
                                    ? 'aliceblue'
                                    : 'transparent'
                        }}
                    >
                        {item.highlights.map((h, i) => (
                            <span key={`${item.packageId}-highlight-${i}`} dangerouslySetInnerHTML={{ __html: h }} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(BasicSearchResult);
