import * as React from 'react';
import { DynamicControlProps } from './types';
import { Tooltip, Form, Input, Button } from 'antd';
import { ConfidenceLevel, getConfLevelColor } from './ConfidenceLevel';
import { Utils } from '../../../common/misc/Utils';
import { DraggableModal } from 'ant-design-draggable-modal';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Subscription } from 'rxjs';

export const DynamicTextEditor: React.FC<DynamicControlProps> = ({
    inputParams,
    form,
    onHighlightBlock,
    highlightInputSubject,
    getGearIcon
}) => {
    const [dialogVisible, setDialogVisible] = React.useState(false);
    const [textEditorVal, setTextEditorVal] = React.useState('');
    const currentVal = inputParams.value as string;

    React.useEffect(() => {
        if (currentVal) {
            setTextEditorVal(currentVal);
        }
    }, [currentVal]);

    const [isHighlighted, setIsHighlighted] = React.useState(false);

    let sub: React.MutableRefObject<Subscription | undefined> = React.useRef();

    React.useEffect(() => {
        if (highlightInputSubject) {
            sub.current = highlightInputSubject.subscribe(id => {
                if (id === inputParams.id) {
                    setIsHighlighted(true);
                } else {
                    setIsHighlighted(false);
                }
            });
        }

        return () => {
            if (sub.current) {
                sub.current.unsubscribe();
            }
        };
    }, [highlightInputSubject, inputParams.id]);

    const highlightField = () => {
        const fieldData = Utils.extractFieldData(inputParams);
        if (fieldData) {
            onHighlightBlock(fieldData, inputParams.id);
        }
    };

    const getTooltip = () => {
        if (!inputParams.behavior || !inputParams.behavior.inputTooltip) {
            return null;
        }
        return (
            <Tooltip overlayClassName="alpha-input-tooltip" title={inputParams.behavior.inputTooltip} placement="top">
                <QuestionCircleOutlined style={{ marginLeft: 6, verticalAlign: 'middle' }} />
            </Tooltip>
        );
    };

    const label = (
        <>
            <span className="alpha-doc-control-label with-controls">
                <Tooltip title={inputParams.name}>
                    <span className="alpha-doc-control-name">{inputParams.name}</span>
                </Tooltip>
                <span className="controls-container">
                    {inputParams.behavior && inputParams.behavior.readonly ? (
                        <></>
                    ) : (
                        <ConfidenceLevel input={inputParams} />
                    )}

                    {(inputParams.meta && inputParams.meta.field && inputParams.value != null && (
                        <Tooltip title="View in document">
                            <i className="alpha-icon xs form-anchor" onClick={highlightField} />
                        </Tooltip>
                    )) ||
                        getGearIcon(inputParams)}
                    {getTooltip()}
                </span>
            </span>
        </>
    );

    const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTextEditorVal(event.target.value);
    };

    const setFormValue = () => {
        const obj = {};
        obj[inputParams.id] = textEditorVal;
        form.setFieldsValue(obj);
        setDialogVisible(false);
    };

    const cancelChange = () => {
        setTextEditorVal(form.getFieldValue(inputParams.id));
        setDialogVisible(false);
    };

    const handleButtonClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        const target = event.target as HTMLElement;
        if (!target.className.includes('ps__rail') && !target.className.includes('ps__thumb')) {
            setDialogVisible(true);
        }
    };

    const getInputCssClass = () => {
        let styleClass = 'texteditor-btn ';
        if (currentVal) {
            styleClass += getConfLevelColor(inputParams);
        }
        styleClass += isHighlighted ? ' iota-contract-shadow' : '';
        return styleClass;
    };

    return (
        <Form.Item
            data-id={inputParams.id}
            data-type={inputParams.controlType}
            label={label}
            colon={false}
            labelCol={{ span: 24 }}
            name={inputParams.id}
            className="wide"
            rules={[
                {
                    required:
                        inputParams.validation &&
                        inputParams.validation.required &&
                        inputParams.validation.required.value,
                    message:
                        (inputParams.validation &&
                            inputParams.validation.required &&
                            inputParams.validation.required.message) ||
                        `${inputParams.name} is required`
                }
            ]}
            style={{ maxHeight: 140 }}
            initialValue={currentVal}
        >
            <Button className={getInputCssClass()} onClick={handleButtonClick}>
                <div>{form.getFieldValue(inputParams.id) || currentVal || 'Edit'}</div>
            </Button>
            <DraggableModal
                zIndex={1001}
                maskClosable={false}
                title={inputParams.name}
                visible={dialogVisible}
                onCancel={() => cancelChange()}
                footer={[
                    <Button
                        key={`${inputParams.id}-${inputParams.name}-modal-ok`}
                        type="primary"
                        onClick={() => setFormValue()}
                    >
                        Ok
                    </Button>
                ]}
            >
                <Input.TextArea
                    autoComplete="off"
                    rows={25}
                    value={textEditorVal}
                    onChange={ev => handleTextAreaChange(ev)}
                    style={Utils.buildDynamicInputStyles(inputParams)}
                    disabled={inputParams.behavior && inputParams.behavior.readonly}
                />
            </DraggableModal>
        </Form.Item>
    );
};

export default DynamicTextEditor;
