import { FormInstance, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { DocumentVisualStore } from '../../documents/stores';
import { FormInputFieldData } from '../services/types';
import { DocumentTabStore, TabsStore, WorkflowInstanceStore } from '../stores';
import { DynamicUiModel } from '../stores/DynamicUiModel';
import { TabModel } from '../types/TabModel';
import WrappedDynamicControlsContainer from '../../documents/components/DynamicControlsContainer';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import { WidgetValues } from '../services/WidgetValuesProvider';
import PreSubmitAuthorizationDialog from './PreSubmitActions/PreSubmitAuthorizationDialog';

type ScanProps = {
    documentTabStore: DocumentTabStore;
    workflowInstance: WorkflowInstanceStore;
    tabsStore: TabsStore;
    form: FormInstance;
    bottomContainer?: boolean;
    setSettingsDialogVisible: (isVisible: boolean) => void;
    preSubmitDialogVisible: boolean;
    setPreSubmitDialogVisible: (isVisible: boolean) => void;
    handleSubmitClick: (values: WidgetValues, act: string) => Promise<void>;
    currentWidgetValues: WidgetValues | undefined;
    currentAction: string | undefined;
    setCurrentWidgetValues: (values: WidgetValues | undefined) => void;
    setCurrentAction: (action: string | undefined) => void;
    action: string;
    setAction: (action: string) => void;
    handleAction: (control: string, actionId: string) => Promise<void>;
    handleOidcFlowSuccess: (value: string) => Promise<void>;
    restricedModeEnabled?: boolean;
};

const ScanResultsRenderer: React.FC<ScanProps> = ({
    documentTabStore,
    workflowInstance,
    tabsStore,
    bottomContainer,
    form,
    setSettingsDialogVisible,
    preSubmitDialogVisible,
    setPreSubmitDialogVisible,
    handleSubmitClick,
    currentWidgetValues,
    currentAction,
    setCurrentWidgetValues,
    setCurrentAction,
    action,
    setAction,
    handleAction,
    handleOidcFlowSuccess,
    restricedModeEnabled
}) => {
    const navigate = useNavigate();

    const tab = documentTabStore.tab;

    const handleReload = (t: TabModel) => {
        tabsStore.removeTab(tabsStore.tabs.indexOf(t));
        tabsStore.resetSelection();
        navigate('/analysis');
        if (tab.metadata.packageId) {
            workflowInstance.reload(
                t.metadata.packageId as string,
                t.metadata.applicationData.appId as string,
                t.metadata.sessionId as string,
                t.metadata.packageName as string,
                t.metadata.applicationData.appExtension as string,
                (sessionId, packageId, packageName) => {
                    tabsStore.setSessionTabReloading({ sessionId, packageName, packageId });
                }
            );
        } else {
            workflowInstance.reloadPackageSession(
                t.metadata.sessionId as string,
                t.metadata.packageSetId as string,
                t.metadata.applicationData.appId as string,
                t.metadata.applicationData.appExtension as string,
                t.title,
                (sessionId, packageSetName, setId) => {
                    tabsStore.setSessionTabReloading({ sessionId, packageName: packageSetName, packageId: setId });
                },
                t.metadata.isBulk
            );
        }

        workflowInstance.updateSession(t.id, 'Archived');
    };

    const handleDownload = (filePath: string) => {
        workflowInstance.getFileFromIotaTempStorage(filePath);
    };

    const handleClose = () => {
        if (tab.metadata.parentTabId) {
            const parentTab = tabsStore.tabs.find(t => t.id === tab.metadata.parentTabId);
            if (parentTab) {
                tabsStore.selectTab(tabsStore.tabs.indexOf(parentTab));
            }
        }
        tabsStore.removeTab(tabsStore.tabs.indexOf(tab));
    };

    if (!tab.metadata) {
        return null;
    }
    if (!tab.metadata.dynamicUI && !tab.metadata.sessionError) {
        return null;
    } else if (!tab.metadata.dynamicUI && tab.metadata.sessionError) {
        return (
            <div className="error-container">
                <div className="error-container-header">
                    <ExclamationCircleOutlined /> Error
                </div>
                <div className="error-container-content">{tab.metadata.sessionError as string}</div>
            </div>
        );
    }
    const store = tabsStore.documentStores[tab.id];
    const onHighlightBlock = (field: FormInputFieldData, inputId: string) => {
        if (field.pId) {
            const rootStore = tabsStore.documentStores[`${tab.id}-${field.pId}`];
            const nextBlockToHighlight = rootStore?.getNextBlockToHighlight(inputId);
            if (nextBlockToHighlight) {
                const pkgId = nextBlockToHighlight.pId!;
                documentTabStore.setSelectedPackageId(pkgId);
                const storeId = `${tab.id}-${pkgId}`;
                const docStore = tabsStore.documentStores[storeId] ?? tabsStore.documentStores[tab.id];
                highlightProvidedBlock(docStore, nextBlockToHighlight, inputId);
                highlightProvidedBlock(rootStore, nextBlockToHighlight, inputId);
            }
        }

        highlightBlockHandler(store, field, inputId);
    };

    const highlightProvidedBlock = (documentStore: DocumentVisualStore, field: FormInputFieldData, inputId: string) => {
        if (documentStore) {
            documentStore.highlightBlock(field, inputId, field != null);
        }
    };

    const highlightBlockHandler = (documentStore: DocumentVisualStore, field: FormInputFieldData, inputId: string) => {
        if (documentStore) {
            const nextBlockToHighlight = documentStore.getNextBlockToHighlight(inputId);
            if (inputId === store.highlightedInputId && !nextBlockToHighlight) {
                documentStore.highlightBlock(undefined, undefined);
            } else {
                const selectedData =
                    inputId === store.highlightedInputId
                        ? (nextBlockToHighlight ?? field)
                        : (field ?? nextBlockToHighlight);
                documentStore.highlightBlock(selectedData, inputId, true);
            }
        }
    };

    const getReloadHandler = () => {
        const isReloadable = tab.metadata?.isReloadable;
        if (isReloadable === false) {
            return undefined;
        }

        return () => handleReload(tab);
    };

    const handleAuthorizationSubmit = async (
        values: WidgetValues,
        submitAction: string,
        username: string,
        pwd: string
    ) => {
        // @ts-ignore
        values.username = { value: username };

        // @ts-ignore
        values.password = { value: pwd };

        await onSubmit(values, submitAction);
        setCurrentAction(undefined);
        setCurrentWidgetValues(undefined);
    };

    const onSubmit = async (values: WidgetValues, submitAction: string) => {
        documentTabStore.handleTabUiUpdate(undefined);
        await workflowInstance.handleFormSubmit(values, submitAction, tab.metadata);
    };

    const controlsContainer = (
        <WrappedDynamicControlsContainer
            packageName={tab.metadata.packageName as string}
            ui={tab.metadata.dynamicUI! as DynamicUiModel}
            appName={tab.metadata.applicationData.appName as string}
            onsubmit={handleSubmitClick}
            onHighlightBlock={onHighlightBlock}
            onReload={getReloadHandler()}
            bottomContainer={bottomContainer}
            onDownload={handleDownload}
            onClose={handleClose}
            showingBlocks={store.showBlocks}
            onTogglePageBlocks={store.toggleShowBlocks}
            dataId={tab.id}
            sessionState={tab.metadata.sessionState}
            openSections={documentTabStore.openSections}
            setOpenSections={documentTabStore.setOpenSections}
            highlightInputSubject={store.highlightedInputIdSubject}
            form={form}
            setSettingsDialogVisible={setSettingsDialogVisible}
            action={action}
            setAction={setAction}
            handleAction={handleAction}
            handleOidcFlowSuccess={handleOidcFlowSuccess}
            restricedModeEnabled={restricedModeEnabled}
        />
    );

    const renderWrappedContent = () => {
        const ui = tab.metadata.dynamicUI! as DynamicUiModel;
        const hasPreSubmitActions = ui?.preSubmitActions?.length;
        const dialogTitleKey = 'DialogTitle';
        const passwordOnlyKey = 'PasswordOnly';
        return (
            <>
                {hasPreSubmitActions
                    ? ui.preSubmitActions.map((a, index) =>
                          a.type === 'Authorize' ? (
                              <PreSubmitAuthorizationDialog
                                  key={a.type + index}
                                  action={currentAction}
                                  widgetValues={currentWidgetValues}
                                  handleSubmit={handleAuthorizationSubmit}
                                  sessionId={tab.id}
                                  isVisible={preSubmitDialogVisible}
                                  setVisible={setPreSubmitDialogVisible}
                                  title={a.metadata[dialogTitleKey]}
                                  passwordOnly={a.metadata[passwordOnlyKey]}
                              />
                          ) : null
                      )
                    : null}
                {controlsContainer}
            </>
        );
    };

    return tab.metadata.freezeUi ? (
        <Spin className="freeze-spinner">{renderWrappedContent()}</Spin>
    ) : (
        renderWrappedContent()
    );
};

export default observer(ScanResultsRenderer);
