import * as React from 'react';
import { Radio, RadioGroupProps } from 'antd';
import { CustomInputLabel } from '..';
import './CustomRadioButton.less';

type CustomProps = {
    label: string;
    required?: boolean;
    topRightNode?: React.ReactNode;
    containerStyle?: React.CSSProperties;
    hasError?: boolean;
    getContainerRef?: (containerRef: React.RefObject<HTMLDivElement>) => void;
};

export type CustomRadioButtonProps = CustomProps & RadioGroupProps;

const CustomRadioButton: React.FC<CustomRadioButtonProps> = (props: CustomRadioButtonProps) => {
    const { label, required, topRightNode, containerStyle, hasError, getContainerRef, ...radioButtonProps } = props;

    const containerRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (getContainerRef) {
            getContainerRef(containerRef);
        }
    }, [getContainerRef]);

    const getContainerClassName = () => {
        let className = 'custom-radio-button-container';

        if (hasError) {
            className += ' has-error';
        }

        return className;
    };

    return (
        <div ref={containerRef} className={getContainerClassName()} style={containerStyle}>
            {!!topRightNode && <div className="top-right-node-container">{topRightNode}</div>}

            <Radio.Group className="custom-radio-button" {...radioButtonProps} />

            <CustomInputLabel label={label} required={required} />
        </div>
    );
};

export default CustomRadioButton;
