import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Popconfirm, Tooltip } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import { DeleteOutlined } from '@ant-design/icons';
import { AskAlphaSettingsValues } from '../../types';
import { useAskAlphaStores } from '../../stores/context';
import { AskAlphaSettings } from '..';
import './AskAlphaHeader.less';

type Props = {
    settingsForm: FormInstance<AskAlphaSettingsValues>;
};

const AskAlphaHeader: React.FC<Props> = ({ settingsForm }) => {
    const { askAlphaStore } = useAskAlphaStores();

    return (
        <div className="ask-alpha-header">
            <div className="ask-alpha-header-left-container">
                <h1 className="ask-alpha-header-title">Ask Alpha</h1>
            </div>

            <div className="ask-alpha-header-right-container">
                <AskAlphaSettings settingsForm={settingsForm} />

                <Popconfirm
                    placement="topRight"
                    title="Are you sure you want to clear the chat?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => askAlphaStore.clearChat(true)}
                >
                    <Tooltip title="Clear chat">
                        <DeleteOutlined className="header-button" />
                    </Tooltip>
                </Popconfirm>
            </div>
        </div>
    );
};

export default observer(AskAlphaHeader);
