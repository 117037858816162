import * as React from 'react';
import { Layout, Menu } from 'antd';
import { Routes } from 'react-router';
import { useStore } from '../../common/stores/context';
import { routes as analysis } from '../../analysis';
import { routes as previewRoute } from '../../preview';
import { routes as sessions } from '../../sessions';
import { routes as search } from '../../search';
import { routes as home } from '../../home';
import { routes as authorization } from '../../authorization';
import { routes as askAlpha } from '../../ask_alpha';
import DocumentTabs from '../../common/components/DocumentTabs';
import { observer } from 'mobx-react-lite';
import { PushServiceStatusBar } from '../../common/components/PushServiceStatusBar';
import { TabsContentContainer } from '../../common/components';
import UserProfileControls from '../../user_profile/components/UserProfileControls';
import { hasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import authContext from '../../authorization/AuthContext';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router';
import { CommentOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;

const menuItem = (icon: JSX.Element, title: string, key: string, dataId: string, clickHandler: () => void) => {
    return (
        <Menu.Item data-id={dataId} key={key} onClick={clickHandler}>
            {icon}
            {title}
        </Menu.Item>
    );
};

const MainLayout: React.FC = () => {
    const rootStore = useStore();
    const authCtx = React.useContext(authContext);
    const navigateTo = useNavigate();
    const location = useLocation();

    React.useEffect(() => {
        rootStore.userProfileStore.setUserPermissions(authCtx.permissions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const generateMenuItems = () => {
        let menuItems: JSX.Element[] = [];

        if (
            hasPermission(authCtx.permissions, [
                AppPermissions.CanSeeAnalysisPageAll,
                AppPermissions.CanSeeAnalysisPagePersonal
            ])
        ) {
            menuItems.push(
                menuItem(<i className="alpha-icon nav-analysis xs middle" />, 'Analysis', '1', 'main-menu-home', () =>
                    navigate('/analysis')
                )
            );
        }

        if (hasPermission(authCtx.permissions, AppPermissions.CanAccessArchive)) {
            menuItems.push(
                menuItem(<i className="alpha-icon nav-archive xs middle" />, 'Archive', '2', 'main-menu-archive', () =>
                    navigate('/archive')
                )
            );
        }

        if (process.env.REACT_APP_ENABLE_ASK_ALPHA === 'true') {
            menuItems.push(
                menuItem(<CommentOutlined className="alpha-icon xs" />, 'Ask Alpha', '3', 'main-menu-ask-alpha', () =>
                    navigate('/ask-alpha')
                )
            );
        }

        return menuItems;
    };

    const determineMenuSelection = () => {
        let selectedKeys: string[] = [];
        if (rootStore.tabsStore.selectedTab) {
            return selectedKeys;
        }

        if (location.pathname.includes('analysis')) {
            selectedKeys = ['1'];
        } else if (location.pathname.includes('archive')) {
            selectedKeys = ['2'];
        } else if (location.pathname.includes('ask-alpha')) {
            selectedKeys = ['3'];
        }

        return selectedKeys;
    };

    const navigate = (path: string) => {
        rootStore.tabsStore.resetSelection();
        // rootStore.routerStore.pushToHistory(path);
        navigateTo(path);
    };

    return (
        <Layout
            className={`main-layout ${rootStore.restricedAccessStore.restricedModeEnabled ? 'restriced-mode-layout' : ''}`}
        >
            {rootStore.projectsStore.isPushServiceConnected ? <></> : <PushServiceStatusBar />}
            {rootStore.restricedAccessStore.restricedModeEnabled ? null : (
                <Header
                    className="app-page-header"
                    style={{ paddingLeft: 40, paddingRight: 40, position: 'fixed', zIndex: 1000, width: '100%' }}
                >
                    <div className="header-content">
                        <div className="logo-container" onClick={() => navigate('/')}>
                            <i className="alpha-icon logo lg" />
                        </div>
                        <div className="main-menu-container">
                            <Menu
                                selectedKeys={determineMenuSelection()}
                                theme="light"
                                mode="horizontal"
                                style={{ lineHeight: '50px', height: 50 }}
                            >
                                {/* <Menu.Item data-id="main-menu-search" key="3" onClick={() => navigate('/search')}>
                                    <SearchOutlined className="alpha-icon xs middle" />
                    Search
                                </Menu.Item> */}
                                {generateMenuItems()}
                            </Menu>
                        </div>
                        <DocumentTabs />
                        <div className="controls-container">
                            <div className="profile-icon-container">
                                <UserProfileControls />
                            </div>
                        </div>
                    </div>
                </Header>
            )}
            <Content className="main-layout">
                <div style={{ display: !rootStore.tabsStore.selectedTab ? 'contents' : 'none' }}>
                    <Routes>
                        {home()}
                        {analysis()}
                        {previewRoute()}
                        {/* {documents()} */}
                        {sessions()}
                        {search()}
                        {authorization()}
                        {askAlpha()}
                    </Routes>
                </div>
                <TabsContentContainer />
            </Content>
            <div className="app-version-label">{`© Alpha. Version: ${process.env.REACT_APP_VERSION}`}</div>
            {/* <Footer className="alpha-layout-footer">
        © JE Alpha Project, {new Date().getFullYear()}
      </Footer> */}
        </Layout>
    );
};

export default observer(MainLayout);
