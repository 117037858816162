import * as React from 'react';
import PreviewPage from '../screens/Preview';
import { Route } from 'react-router';
import { context } from '../../analysis/stores';
import { context as sessionContext } from '../../sessions/stores';
import { context as documentsContext } from '../../documents/stores';

const previewRoute = () => {
    return (
        <Route
            path={'/preview/:sessionId'}
            element={
                <documentsContext.DocumentsStoreProvider>
                    <sessionContext.SessionsStoreProvider>
                        <context.AnalysisStoreProvider>
                            <PreviewPage />
                        </context.AnalysisStoreProvider>
                    </sessionContext.SessionsStoreProvider>
                </documentsContext.DocumentsStoreProvider>
            }
        />
    );
};

export default previewRoute;
