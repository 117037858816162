import { Tag } from 'antd';
import * as React from 'react';

export default class DynamicUiUtils {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static getStateDisplayVal(val: any) {
        let recordState = val ? (val as string) : undefined;
        switch (recordState) {
            case 'Analyzed':
                return <Tag color="#203058">Analyzed</Tag>;
            case 'Updated':
                return <Tag color="#FADB14">Updated</Tag>;
            case 'Completed':
                return <Tag color="#52C41A">Completed</Tag>;
            case 'Failed':
                return <Tag color="#F5222D">Analyzed</Tag>;
            default:
                return val;
        }
    }
}
