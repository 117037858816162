import { action, observable, runInAction, makeObservable } from 'mobx';
import { IotaSessionsStore, WorkflowInstanceStore } from '.';
import { SessionService } from '../services';
import { PackageSetSession } from '../services/types';
import { TabModel } from '../types/TabModel';
import { DynamicUiModel } from './DynamicUiModel';

export default class MultiDocumentTabStore {
    tab: TabModel;

    ui: DynamicUiModel | undefined;

    isLoading: boolean = false;

    setSessionData: PackageSetSession | undefined;

    constructor(
        tab: TabModel,
        private sessionService: SessionService,
        private workflowInstance: WorkflowInstanceStore,
        private iotaSessionsStore: IotaSessionsStore
    ) {
        makeObservable<MultiDocumentTabStore, 'setTab'>(this, {
            tab: observable,
            ui: observable,
            isLoading: observable,
            setSessionData: observable,
            loadTabData: action.bound,
            setLoading: action.bound,
            switchToLayout: action.bound,
            closeLayout: action.bound,
            saveLayout: action.bound,
            setTab: action.bound
        });

        this.iotaSessionsStore.iotaSessionChanges.subscribe(s => {
            if (this.tab.id === s.sessionId && !s.inProgress) {
                this.loadTabData();
            }
        });

        this.setTab(tab);
    }

    async loadTabData() {
        if (!this.tab) {
            return;
        }

        this.setLoading(true);
        const result = await this.sessionService.getPackageSetSession(this.tab.id);
        const ui = await this.workflowInstance.getSessionUiModel(
            this.tab.id,
            this.tab.id,
            this.tab.metadata.applicationData.appExtension
        );

        if (ui) {
            ui.uiModel.actions = [
                {
                    id: 'save-layout',
                    name: 'Save',
                    description: 'Save document',
                    message: '',
                    isDisabled: false
                },
                {
                    id: 'close-layout',
                    name: 'Close',
                    description: 'Close document',
                    message: '',
                    isDisabled: false
                }
            ];
        }

        if (result) {
            runInAction(() => {
                this.setSessionData = result;
                this.ui = ui;
            });
        }
        this.setLoading(false);
    }

    setLoading(loading: boolean) {
        this.isLoading = loading;
    }

    async switchToLayout(packageId: string) {
        this.setLoading(true);
        this.workflowInstance.continueSession(
            { PackageId: { value: packageId } },
            'switch-layout',
            this.tab.id,
            this.tab.metadata.applicationData.appExtension
        );
    }

    async closeLayout() {
        this.setLoading(true);
        this.workflowInstance.continueSession(
            {},
            'close-layout',
            this.tab.id,
            this.tab.metadata.applicationData.appExtension
        );
    }

    async saveLayout() {
        this.setLoading(true);
        this.workflowInstance.continueSession(
            {},
            'save-layout',
            this.tab.id,
            this.tab.metadata.applicationData.appExtension
        );
    }

    private setTab(tab: TabModel) {
        this.tab = tab;
    }
}
