import {
    LayoutSettingsBaseDto,
    BasicLayoutSettingsDto,
    LayersLayoutSettingsDto,
    LayersWithSectionsLayoutSettingsDto,
    LayerSettingsDto,
    SectionsLayoutSettingsDto,
    SettingsFormValues
} from '../types';
import {
    LayoutSettingsBaseModel,
    BasicLayoutSettingsModel,
    LayersLayoutSettingsModel,
    LayersWithSectionsLayoutSettingsModel,
    SectionsLayoutSettingsModel
} from '../models';
import { Utils, InstanceUtils } from '.';

const layerPrefix = 'Layer:';

export default class LayoutSettingsFactory {
    static createSettings(data: LayoutSettingsBaseDto): LayoutSettingsBaseModel {
        switch (data.type) {
            case 'Basic':
                return new BasicLayoutSettingsModel(data as BasicLayoutSettingsDto);
            case 'Layers':
                return new LayersLayoutSettingsModel(data as LayersLayoutSettingsDto);
            case 'LayersWithSections':
                return new LayersWithSectionsLayoutSettingsModel(data as LayersWithSectionsLayoutSettingsDto);
            case 'Sections':
                return new SectionsLayoutSettingsModel(data as SectionsLayoutSettingsDto);
            default:
                throw new Error('Unsupported settings type');
        }
    }

    static createSettingFromFormValues(formValues: SettingsFormValues): LayoutSettingsBaseModel {
        const dto: LayoutSettingsBaseDto = {
            type: formValues.type
        };

        const hasLayers = InstanceUtils.hasLayers(formValues);
        const hasSections = InstanceUtils.hasSections(formValues);

        if (dto.type === 'Layers' && hasLayers) {
            const data: LayersLayoutSettingsDto = { ...dto, numberOfLayers: formValues.numberOfLayers };
            return LayoutSettingsFactory.createSettings(data);
        }

        if (dto.type === 'LayersWithSections' && hasLayers && hasSections) {
            const layersSettings: LayerSettingsDto[] = Utils.createNumberArray(formValues.numberOfLayers).map(
                layerNumber => ({
                    layerNumber,
                    layerId: `${layerPrefix}${layerNumber}`,
                    numberOfSections: formValues.numberOfSections
                })
            );

            const data: LayersWithSectionsLayoutSettingsDto = { ...dto, layersSettings };
            return LayoutSettingsFactory.createSettings(data);
        }

        if (dto.type === 'Sections' && hasSections) {
            const data: SectionsLayoutSettingsDto = { ...dto, numberOfSections: formValues.numberOfSections };
            return LayoutSettingsFactory.createSettings(data);
        }

        return LayoutSettingsFactory.createSettings(dto);
    }
}
