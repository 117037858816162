import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Dropdown, Select, Checkbox, Form, Input, Tooltip, Button, Popconfirm } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import { AskAlphaSettingsValues, InstructWorkflowOutputSchemeType } from '../../types';
import { ApplicationDefinitionSubgroup } from '../../../common/services/types';
import { useAskAlphaStores } from '../../stores/context';
import './AskAlphaSettings.less';

type WorkflowOutputSchemeTypeOption = { value: InstructWorkflowOutputSchemeType; label: string };

export const workflowOutputSchemeTypeOptions: WorkflowOutputSchemeTypeOption[] = [
    {
        value: 'JsonScheme',
        label: 'Json Scheme'
    },
    {
        value: 'PythonDict',
        label: 'Python Dict'
    }
];

type Props = {
    settingsForm: FormInstance<AskAlphaSettingsValues>;
};

const AskAlphaSettings: React.FC<Props> = ({ settingsForm }) => {
    const { askAlphaStore, askAlphaProjectsStore, askAlphaApplicationDefinitionsStore } = useAskAlphaStores();

    const schemeNameDisabled = React.useMemo(
        () =>
            !!askAlphaStore.settings.values.instructWorkflowCustomOutputSchemeType ||
            !!askAlphaStore.settings.values.instructWorkflowCustomOutputScheme,
        [
            askAlphaStore.settings.values.instructWorkflowCustomOutputSchemeType,
            askAlphaStore.settings.values.instructWorkflowCustomOutputScheme
        ]
    );

    const customSchemeDisabled = React.useMemo(
        () => !!askAlphaStore.settings.values.instructWorkflowOutputSchemeName,
        [askAlphaStore.settings.values.instructWorkflowOutputSchemeName]
    );

    const onValuesChange = (changedValues: Partial<AskAlphaSettingsValues>) => {
        const resetOutputSchemeName =
            'instructWorkflowId' in changedValues ||
            changedValues.instructWorkflowCustomOutputSchemeType ||
            changedValues.instructWorkflowCustomOutputScheme;

        if (changedValues.projectId) {
            settingsForm.setFieldsValue({ instructWorkflowId: null });
            settingsForm.setFieldsValue({ instructWorkflowOutputSchemeName: null });
            settingsForm.setFieldsValue({ applicationDefinitionId: null });
        }

        if (resetOutputSchemeName) {
            settingsForm.setFieldsValue({ instructWorkflowOutputSchemeName: null });
        }

        if (changedValues.instructWorkflowOutputSchemeName) {
            settingsForm.setFieldsValue({ instructWorkflowCustomOutputSchemeType: null });
            settingsForm.setFieldsValue({ instructWorkflowCustomOutputScheme: null });
        }

        askAlphaStore.settings.setValues(settingsForm.getFieldsValue());
    };

    const getApplicationDefinitionIds = React.useCallback(
        <T extends { applicationDefinitionIds: string[]; subGroups: ApplicationDefinitionSubgroup[] }>(
            group: T
        ): string[] =>
            group.subGroups.reduce(
                (acc: string[], subgroup: ApplicationDefinitionSubgroup) => [
                    ...acc,
                    ...getApplicationDefinitionIds(subgroup)
                ],
                group.applicationDefinitionIds
            ),
        []
    );

    const applicationOptions = React.useMemo(() => {
        return askAlphaApplicationDefinitionsStore.projectApplicationGroups
            .map(group => {
                const applicationDefinitionIds = getApplicationDefinitionIds(group);
                return {
                    label: group.name,
                    options: askAlphaApplicationDefinitionsStore.projectApplications
                        .filter(app => applicationDefinitionIds.includes(app.id))
                        .map(app => ({
                            value: app.id,
                            label: app.name
                        }))
                };
            })
            .filter(group => group.options.length > 0);
    }, [
        askAlphaApplicationDefinitionsStore.projectApplicationGroups,
        askAlphaApplicationDefinitionsStore.projectApplications,
        getApplicationDefinitionIds
    ]);

    const menu = (
        <Form
            id="ask-aplha-settings-form"
            className="ask-aplha-settings-form"
            layout="vertical"
            form={settingsForm}
            onValuesChange={onValuesChange}
            preserve={false}
        >
            <Form.Item
                name="projectId"
                label="Project"
                initialValue={askAlphaStore.settings.values.projectId}
                rules={[
                    {
                        required: true,
                        message: 'Please select project'
                    }
                ]}
            >
                <Select
                    placeholder="Select project"
                    options={askAlphaProjectsStore.projects.map(project => ({
                        value: project.id,
                        label: project.name
                    }))}
                />
            </Form.Item>

            <Form.Item
                name="applicationDefinitionId"
                label="Application"
                initialValue={askAlphaStore.settings.values.applicationDefinitionId}
            >
                <Select
                    allowClear
                    placeholder="Select application"
                    loading={askAlphaApplicationDefinitionsStore.loading}
                    options={applicationOptions}
                />
            </Form.Item>

            <Form.Item
                name="instructWorkflowId"
                label="Instruct workflow"
                initialValue={askAlphaStore.settings.values.instructWorkflowId}
                rules={[
                    {
                        required: true,
                        message: 'Please select instruct workflow'
                    }
                ]}
            >
                <Select
                    className={`instruct-workflow-select ${askAlphaStore.instructWorkflowsLoading ? 'loading' : ''}`}
                    placeholder="Select workflow"
                    loading={askAlphaStore.instructWorkflowsLoading}
                    allowClear
                    options={askAlphaStore.instructWorkflows.map(workflow => ({
                        value: workflow.id,
                        label: workflow.name
                    }))}
                />
            </Form.Item>

            <Form.Item
                name="instructWorkflowOutputSchemeName"
                label="Output scheme"
                initialValue={askAlphaStore.settings.values.instructWorkflowOutputSchemeName}
            >
                <Select
                    allowClear
                    placeholder="Select output scheme"
                    disabled={schemeNameDisabled}
                    options={
                        askAlphaStore.selectedInstructWorkflow
                            ? askAlphaStore.selectedInstructWorkflow.outputSchemes.map(scheme => ({
                                  value: scheme.name,
                                  label: scheme.name
                              }))
                            : []
                    }
                />
            </Form.Item>

            <Form.Item
                name="instructWorkflowCustomOutputSchemeType"
                label="Custom output scheme type"
                initialValue={askAlphaStore.settings.values.instructWorkflowCustomOutputSchemeType}
            >
                <Select options={workflowOutputSchemeTypeOptions} disabled={customSchemeDisabled} allowClear />
            </Form.Item>

            <Form.Item
                name="instructWorkflowCustomOutputScheme"
                label="Custom output scheme"
                initialValue={askAlphaStore.settings.values.instructWorkflowCustomOutputScheme}
            >
                <Input.TextArea
                    className="custom-output-scheme"
                    autoSize={{ minRows: 4, maxRows: 8 }}
                    disabled={customSchemeDisabled}
                />
            </Form.Item>

            <Form.Item
                name="useContext"
                initialValue={askAlphaStore.settings.values.useContext}
                valuePropName="checked"
            >
                <Checkbox className="use-context-checkbox">Use Context</Checkbox>
            </Form.Item>

            <Popconfirm
                placement="topRight"
                title="Are you sure you want to clear all chats?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => askAlphaStore.clearAllChats()}
                overlayStyle={{ zIndex: 2000 }}
            >
                <Button className="light">Clear all chats</Button>
            </Popconfirm>
        </Form>
    );

    return (
        <Dropdown
            placement="topRight"
            trigger={['click']}
            overlay={menu}
            visible={askAlphaStore.settingsVisible}
            onVisibleChange={askAlphaStore.setSettingsVisible}
            arrow
        >
            <Tooltip title="Settings">
                <i
                    className="alpha-icon gear-gray ask-alpha-settings-icon"
                    onClick={() => askAlphaStore.setSettingsVisible(true)}
                />
            </Tooltip>
        </Dropdown>
    );
};

export default observer(AskAlphaSettings);
