import { observable, action, reaction, makeObservable } from 'mobx';
import { z } from 'zod';
import { Disposer } from '../../custom_shared/misc';
import { RuntimeDataStore } from '../../custom_shared/stores';
import BlazorAppSessionStore from './BlazorAppSessionStore';

export enum BlazorFormInputIds {
    Field1StringValue = 'field1_string_value',
    Field1NumberValue = 'field1_number_value'
}

const blazorFormInputIds = Object.values(BlazorFormInputIds) as string[];

const blazorFormSchema = z.object({
    [BlazorFormInputIds.Field1StringValue]: z.string(),
    [BlazorFormInputIds.Field1NumberValue]: z.number()
});

type BlazorForm = z.infer<typeof blazorFormSchema>;

export default class BlazorFormStore extends Disposer {
    static readonly EntryId = 'BlazorForm';

    blazorComponent: unknown;

    values: BlazorForm = {
        [BlazorFormInputIds.Field1StringValue]: '',
        [BlazorFormInputIds.Field1NumberValue]: 0
    };

    constructor(
        private readonly sessionStore: BlazorAppSessionStore,
        private readonly runtimeDataStore: RuntimeDataStore
    ) {
        super();

        makeObservable(this, {
            values: observable,
            blazorComponent: observable,
            setValues: action,
            setBlazorComponent: action.bound
        });

        this.setupRuntimeDataReaction();
    }

    setBlazorComponent(blazorComponent: unknown) {
        this.blazorComponent = blazorComponent;
    }

    setupRuntimeDataReaction() {
        this.reactions.push(
            reaction(
                () => this.sessionStore.runtimeData,
                runtimeData => {
                    const runtimeDataEntry = runtimeData[BlazorFormStore.EntryId];

                    if (runtimeDataEntry) {
                        this.setValues(runtimeDataEntry.value);
                    } else {
                        this.setInitialData();
                    }
                }
            )
        );
    }

    setInitialData() {
        const values = this.sessionStore.initialInputs
            .filter(input => blazorFormInputIds.includes(input.id))
            .reduce(
                (acc, input) => {
                    acc[input.id] = input.initialValue;
                    return acc;
                },
                { ...this.values }
            );

        this.setValues(values);
    }

    setValues(data: unknown) {
        const parseResult = blazorFormSchema.safeParse(data);

        if (!parseResult.success) {
            return;
        }

        this.values = parseResult.data;

        this.runtimeDataStore.add(BlazorFormStore.EntryId, this.values);
    }

    disposeBlazorComponent() {
        if (this.blazorComponent && typeof (this.blazorComponent as { dispose: () => void }).dispose === 'function') {
            (this.blazorComponent as { dispose: () => void }).dispose();
        }
    }

    dispose() {
        this.disposeReactions();
    }
}
