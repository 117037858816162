import { InputBaseModel } from '../models';
import { RequiredConditionFieldDto, RequiredConditionDto, ConditionOperator } from '../types';

type ConditionValidationResult = {
    condition: RequiredConditionDto;
    isValid: boolean;
    fieldResults: FieldValidationResult[];
};

type FieldValidationResult = {
    fieldId: string;
    isValid: boolean;
};

export default class RequiredConditionsValidator {
    public static validateConditions(
        conditions: RequiredConditionDto[],
        inputs: InputBaseModel[]
    ): ConditionValidationResult[] {
        return conditions.map(condition => {
            const fieldResults = RequiredConditionsValidator.validateFields(condition.fields, inputs);
            const isValid = RequiredConditionsValidator.validateCondition(condition.conditionOperator, fieldResults);

            return {
                condition,
                isValid,
                fieldResults
            };
        });
    }

    private static validateCondition(
        conditionOperator: ConditionOperator,
        fieldResults: FieldValidationResult[]
    ): boolean {
        switch (conditionOperator) {
            case 'And':
                return fieldResults.every(r => r.isValid);
            case 'Or':
                return fieldResults.some(r => r.isValid);
            default:
                return false;
        }
    }

    private static validateFields(
        fields: RequiredConditionFieldDto[],
        inputs: InputBaseModel[]
    ): FieldValidationResult[] {
        return fields.map(field => {
            const fieldInput = inputs.find(i => i.id === field.fieldId);
            const isValid = fieldInput ? RequiredConditionsValidator.validateField(field, fieldInput) : false;

            return {
                fieldId: field.fieldId,
                isValid
            };
        });
    }

    private static validateField(field: RequiredConditionFieldDto, input: InputBaseModel): boolean {
        switch (field.fieldOperator) {
            case 'Equal':
                return input.value === field.fieldValue;
            case 'NotEqual':
                return input.value !== field.fieldValue;
            case 'HasValue':
                return input.hasValue;
            default:
                return false;
        }
    }
}
