import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { DocumentVisualStore } from '../stores';
import XMLViewer from 'react-xml-viewer';
import { Spin } from 'antd';

type Props = {
    packageId: string;
    store: DocumentVisualStore;
    style?: React.CSSProperties;
};

const DocumentXml: React.FC<Props> = ({ packageId, store, style = { maxHeight: '100%', overflow: 'auto' } }) => {
    const [xmlContent, setXmlContent] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(true);

    const calculateHighlightedNode = () => {
        const { highlightedBlockProps } = store;

        if (!highlightedBlockProps || !highlightedBlockProps.nodeHierarchy) {
            return;
        }

        const rootXmlDomNode = document.getElementById(`${packageId}-xml-preview`);
        if (!rootXmlDomNode) {
            return;
        }

        const highlightedElements = rootXmlDomNode.getElementsByClassName('highlighted-xml-node');

        if (highlightedElements) {
            for (let i = 0; i < highlightedElements.length; i++) {
                highlightedElements[i].className = '';
            }
        }

        const hierarchy = highlightedBlockProps.nodeHierarchy.split('.');
        const xpathRootString = `//div[@id='${packageId}-xml-preview']`;
        let xpathString = xpathRootString;
        for (var node of hierarchy) {
            xpathString += `//span[text()='${node}']/parent::div`;
        }

        const element = document.evaluate(
            xpathString,
            document,
            null,
            XPathResult.FIRST_ORDERED_NODE_TYPE,
            null
        ).singleNodeValue;

        if (element) {
            const div = element as HTMLDivElement;
            div.className = 'highlighted-xml-node';
            div.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
    };

    React.useEffect(() => {
        if (packageId) {
            store.setPageWidth(100);
            store
                .getPackageXmlContent(packageId)
                .then(xml => {
                    setXmlContent(xml);
                    setIsLoading(false);
                })
                .catch(err => {
                    console.log(err);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(calculateHighlightedNode, [store.highlightedBlockProps]);

    if (!packageId) {
        return null;
    }

    const DocumentLoadingSpinner = (
        <div className="create-contract-document-loading">
            <Spin size="large" />
        </div>
    );

    return (
        <>
            {isLoading ? (
                DocumentLoadingSpinner
            ) : (
                <div id={`${packageId}-xml-preview`} style={style}>
                    <XMLViewer xml={xmlContent} />
                </div>
            )}
        </>
    );
};

export default observer(DocumentXml);
