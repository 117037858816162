import security from '../services/SecurityService';
import { action, runInAction, observable, makeObservable } from 'mobx';
import ProjectsStore from './ProjectsStore';
import WorkflowInstanceStore from './WorkflowInstanceStore';
const ipcRenderer = window.require && window.require('electron').ipcRenderer;

export default class FileOperationsStore {
    isNewContractModalVisibile: boolean = false;

    isSessionProgressDialogVisible: boolean = false;

    listenersRegistered: boolean = false;

    constructor(
        public workflowInstanceStore: WorkflowInstanceStore,
        public projectsStore: ProjectsStore
    ) {
        makeObservable(this, {
            isNewContractModalVisibile: observable,
            isSessionProgressDialogVisible: observable,
            setIsFileUploadModalVisibile: action,
            setIsSessionProgressDialogVisible: action,
            handlePdf: action
        });

        if (ipcRenderer) {
            ipcRenderer.on('handleSecondInstancePdf', (event: Event, arg: { id: string; filePath: string }) => {
                this.handlePdf(arg);
            });
        }
        this.handlePdf();
    }

    setIsFileUploadModalVisibile(isVisible: boolean) {
        this.isNewContractModalVisibile = isVisible;
    }

    setIsSessionProgressDialogVisible(isVisible: boolean) {
        this.isSessionProgressDialogVisible = isVisible;
    }

    handlePdf(secondInstanceArgs?: { id: string; filePath: string }) {
        const userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf('electron/') > -1 && !this.listenersRegistered) {
            this.listenersRegistered = true;
            // const mainProcess =  window.require('electron').remote;
            // TODO: check later
            ipcRenderer.on(
                'ALPHA:HOST:SESSION_STARTED',
                async (event: Event, arg: { sessionId: string; extension: string }) => {
                    this.setIsSessionProgressDialogVisible(true);
                    this.workflowInstanceStore.sessionId = arg.sessionId;
                    await new Promise((resolve, reject) => {
                        this.workflowInstanceStore.getSessionState(arg.sessionId, resolve, reject);
                    });
                }
            );
            ipcRenderer.on('ALPHA:HOST:UPLOAD_STARTED', () => {
                console.log('ALPHA:HOST:UPLOAD_STARTED');
                this.setIsFileUploadModalVisibile(true);
            });

            ipcRenderer.on('ALPHA:HOST:SESSION_STARTING', () => {
                runInAction(() => {
                    this.workflowInstanceStore.isPdfHandleInProgress = true;
                    this.workflowInstanceStore.renderScanResults = true;
                });
            });
        }
        if (userAgent.indexOf('electron/') > -1) {
            this.projectsStore.loadProjectsPromise.then(() => {
                security.invoke(token => {
                    const projectId =
                        process.env.REACT_APP_ALPHA_CLIENT_DEFAULT_PROJECT_ID || this.projectsStore.projects[0].id;
                    // eslint-disable-next-line max-len
                    ipcRenderer.send('ALPHA:UI:APP_READY', {
                        token: token,
                        envUrl: process.env.REACT_APP_MANAGE_URL,
                        projectId: projectId,
                        secondInstanceArgs: secondInstanceArgs
                    });
                    return Promise.resolve();
                });
            });
        }
    }
}
