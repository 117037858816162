import * as React from 'react';
import { IconProps } from '../../componentTypes';
import './ClickableIcon.less';

interface Props extends IconProps {
    icon: React.FunctionComponent<IconProps>;
    className?: string;
    disabled?: boolean;
    onClick: () => void;
}

const ClickableIcon: React.FC<Props> = ({ icon: Icon, disabled, onClick, className = '', ...props }: Props) => (
    <Icon
        className={`clickable-icon ${disabled ? 'disabled' : ''} ${className}`}
        onClick={disabled ? undefined : onClick}
        {...props}
    />
);

export default ClickableIcon;
