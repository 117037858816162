import { gql } from 'apollo-boost';
import {
    ApplicationDefinition,
    ApplicationDefinitionsResult,
    ApplicationDefinitionGroupsResult,
    ApplicationDefinitionGroup
} from './types';
import { execQuery } from './DataService';

export default class ApplicationDefinitionsService {
    async getApplicationDefinitionsForProject(projectId: string, state: string): Promise<ApplicationDefinition[]> {
        const result = await execQuery<ApplicationDefinitionsResult>({
            query: gql`
                query getAppDefinitions($projectId: String!, $state: String!) {
                    getAppDefinitions(projectId: $projectId, state: $state) {
                        id
                        name
                        projectId
                        applicationId
                        workflowId
                        state
                        settings
                        meta
                        settingValues
                        iconFileId
                        capabilities

                        ... on ApplicationDefinition {
                            bindings
                        }

                        ... on ApplicationDefinitionConditional {
                            conditions
                            inputGroups
                        }
                    }
                }
            `,
            variables: {
                projectId: projectId,
                state: state
            },
            fetchPolicy: 'network-only'
        });
        if (result.errors) {
            throw result.errors[0];
        }

        return result.data.getAppDefinitions || [];
    }

    async getApplicationDefinitionGroupsForProject(projectId: string): Promise<ApplicationDefinitionGroup[]> {
        const result = await execQuery<ApplicationDefinitionGroupsResult>({
            query: gql`
                query getAppDefinitionGroups($projectId: String!) {
                    getAppDefinitionGroups(projectId: $projectId) {
                        id
                        name
                        projectId
                        applicationDefinitionIds
                        subGroups
                    }
                }
            `,
            variables: {
                projectId: projectId
            },
            fetchPolicy: 'network-only'
        });
        if (result.errors) {
            console.log(result.errors);
            throw result.errors[0];
        }

        return result.data.getAppDefinitionGroups || [];
    }
}
