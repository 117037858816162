import * as React from 'react';
import { useLocalStore } from 'mobx-react-lite';
import { useStore } from '../../common/stores/context';
import { TabsStore, UserProfileStore } from '../../common/stores';
import HomeVisualStore from './HomeVisualStore';

function createStore(tabs: TabsStore, users: UserProfileStore) {
    const homeVisualStore = new HomeVisualStore(tabs, users);

    return {
        homeVisualStore
    };
}

const storeContext = React.createContext<ReturnType<typeof createStore> | null>(null);
type Props = {
    children: React.ReactNode;
};

export const HomeStoreProvider: React.FC<Props> = ({ children }) => {
    const root = useStore();
    const store = useLocalStore(() => createStore(root.tabsStore, root.userProfileStore));
    return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
};

export const useHomeStore = () => {
    const store = React.useContext(storeContext);
    if (!store) {
        // this is especially useful in TypeScript so you don't need to be checking for null all the time
        throw new Error('You have forgot to use StoreProvider, shame on you.');
    }

    return store;
};
