import * as React from 'react';
import { observer } from 'mobx-react-lite';
import DocumentTabsContent from './DocumentTabsContent';
import { MultiDocumentTabsContent } from '.';

const TabsContentContainer: React.FC = () => {
    return (
        <div>
            <DocumentTabsContent />
            <MultiDocumentTabsContent />
        </div>
    );
};

export default observer(TabsContentContainer);
