import { observable, runInAction, action, reaction, IReactionDisposer, makeObservable } from 'mobx';
import { ProjectsStore } from '../../common/stores';
import ImageService from '../../common/services/ImageService';
import { ImagePreviewSize } from '../../documents/types/types';
import { PackageChanges, PackageStateResult } from '../../common/services/types';

type PackagePreviewPendingModel = {
    packageId: string;
    imageSize: ImagePreviewSize;
};
export default class PreviewsVisualStore {
    smallPreviewUrls: { [packageId: string]: string } = {};

    largePreviewUrls: { [packageId: string]: string } = {};

    packagePreviewPending: PackagePreviewPendingModel | undefined;

    disposer: IReactionDisposer | null;

    previewLoadRetries: number = 0;

    constructor(
        private projectsStore: ProjectsStore,
        private imageService: ImageService
    ) {
        makeObservable<PreviewsVisualStore, 'getImagePreviewById'>(this, {
            smallPreviewUrls: observable,
            largePreviewUrls: observable,
            packagePreviewPending: observable,
            setPackagePreviewPending: action.bound,
            handlePackageChanges: action.bound,
            getPreviews: action.bound,
            getImagePreviewById: action.bound
        });

        projectsStore.packageChanges.subscribe(this.handlePackageChanges);
    }

    setPackagePreviewPending(pkgPreviewPending: PackagePreviewPendingModel | undefined) {
        this.packagePreviewPending = pkgPreviewPending;
    }

    async handlePackageChanges(changes: unknown) {
        const pkgChanges = changes as PackageChanges;
        if (
            this.packagePreviewPending &&
            pkgChanges.id === this.packagePreviewPending.packageId &&
            pkgChanges.state === PackageStateResult.Ready
        ) {
            await this.getPreviews(this.packagePreviewPending.packageId, this.packagePreviewPending.imageSize);
            runInAction(() => {
                this.packagePreviewPending = undefined;
            });
        }
    }

    async getPreviews(packageId: string, imageSize: ImagePreviewSize) {
        let pkg = this.projectsStore.packageListItems.find(p => p.id === packageId);

        if (pkg && pkg.state !== PackageStateResult.Ready) {
            if (!this.disposer) {
                this.disposer = reaction(
                    () => pkg!.state,
                    s => {
                        if (s === PackageStateResult.Ready) {
                            this.getPreviews(pkg!.id, imageSize);
                        }
                    }
                );
            }
        } else if (pkg) {
            await this.getImagePreviewById(packageId, imageSize, pkg.indexDate);
        } else {
            await this.getImagePreviewById(packageId, imageSize);
        }
    }

    private async getImagePreviewById(packageId: string, imageSize: ImagePreviewSize, indexDate: string = '') {
        if (this.disposer) {
            this.disposer();
            this.disposer = null;
        }

        this.disposer = null;
        const resp = await this.imageService.getImagePaths(packageId, indexDate, 0, 1);
        resp.map(imageNames => {
            if (imageNames.length === 0 && this.previewLoadRetries < 5) {
                setTimeout(async () => {
                    await this.getPreviews(packageId, imageSize);
                    this.previewLoadRetries++;
                }, 5000);
            } else {
                const url = this.imageService.getImageUrlFromPath(imageNames[0], imageSize, indexDate);
                runInAction(() => {
                    if (imageSize === ImagePreviewSize.Small) {
                        this.smallPreviewUrls[packageId] = url;
                    } else {
                        this.largePreviewUrls[packageId] = url;
                    }
                });
            }
        }).mapErr(() => {
            if (this.previewLoadRetries < 5) {
                setTimeout(async () => {
                    await this.getPreviews(packageId, imageSize);
                    this.previewLoadRetries++;
                }, 5000);
            } else {
                this.previewLoadRetries = 0;
            }
        });
    }
}
