import * as React from 'react';
import { observer } from 'mobx-react-lite';
import DocumentWithMinimap, { Props as DocumentMinimapProps } from './DocumentWithMinimap';
import DocumentExcel, { Props as DocumentExcelProps } from './DocumentExcel';
import DocumentXml from './DocumentXml';
import { Utils } from '../../common/misc/Utils';

type Props = {
    packageName: string;
} & DocumentMinimapProps &
    Omit<DocumentExcelProps, 'highlightedInputId'>;

const DocumentPreviewSelector: React.FC<Props> = ({ packageName, documentVisualStore, ...childrenProps }) => {
    const isExcel = Utils.isExcelByExtension(packageName);
    const isXml = packageName.endsWith('xml');

    if (isExcel) {
        // To prevent excel table from appearing before main ui is loaded
        if (childrenProps.modelIsLoading) {
            return null;
        }

        return (
            <DocumentExcel
                highlightedInputId={documentVisualStore.highlightedInputId}
                documentVisualStore={documentVisualStore}
                packageName={packageName}
                {...childrenProps}
            />
        );
    }

    if (isXml) {
        return <DocumentXml packageId={childrenProps.packageId} store={documentVisualStore} />;
    }

    return <DocumentWithMinimap documentVisualStore={documentVisualStore} {...childrenProps} />;
};

export default observer(DocumentPreviewSelector);
