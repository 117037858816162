import { reaction } from 'mobx';
import { TabModel } from '../../common/types/TabModel';
import { RootStore } from '../../common/stores/context';
import { DocumentVisualStore } from '../../documents/stores';
import { DocumentService } from '../../common/services';
import { RuntimeDataStore } from '../../custom_shared/stores';
import { Disposer } from '../../custom_shared/misc';
import SessionStore from './SessionStore';
import DummyFormStore from './DummyFormStore';
import ImageService from '../../common/services/ImageService';

export default class AppStore extends Disposer {
    readonly sessionStore: SessionStore;
    readonly dummyFormStore: DummyFormStore;
    readonly documentVisualStore: DocumentVisualStore;
    readonly runtimeDataStore: RuntimeDataStore;

    readonly imageService = new ImageService();
    readonly documentService = new DocumentService();

    constructor(
        readonly tab: TabModel,
        readonly rootStore: RootStore
    ) {
        super();

        this.runtimeDataStore = new RuntimeDataStore();

        this.sessionStore = new SessionStore(this.tab, this.rootStore.iotaSessionStore, this.runtimeDataStore);

        this.dummyFormStore = new DummyFormStore(this.sessionStore, this.runtimeDataStore);

        this.documentVisualStore = new DocumentVisualStore(
            this.rootStore.applicationDefinitionsStore,
            this.imageService,
            this.rootStore.errorStore,
            this.documentService
        );

        this.setupSessionStateReaction();
    }

    setupSessionStateReaction() {
        this.reactions.push(
            reaction(
                () => this.sessionStore.isFinished,
                isFinished => {
                    if (isFinished) {
                        this.dispose();
                    }
                }
            )
        );
    }

    removeAppTab() {
        const tabIndex = this.rootStore.tabsStore.tabs.indexOf(this.tab);
        this.rootStore.tabsStore.removeTab(tabIndex);
    }

    dispose() {
        this.dummyFormStore.dispose();
        this.disposeReactions();
        this.removeAppTab();
    }
}
