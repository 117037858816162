/* eslint-disable @typescript-eslint/no-explicit-any */
import { observable, action, runInAction, makeObservable } from 'mobx';
import { TabsStore, ProjectsStore } from '.';
import { ApplicationSessionChanges } from '../../sessions/types';
import { notification } from 'antd';
import { TabModel } from '../types/TabModel';

type SessionQueueItem = {
    sessionId: string;
    applicationName: string;
    packageName: string;
};

export default class NotificationStore {
    sessionsUpdateQueue: SessionQueueItem[] = [];

    constructor(
        private tabsStore: TabsStore,
        private projectsStore: ProjectsStore
    ) {
        makeObservable<NotificationStore, 'readQueueFromSessionStorage' | 'openSessionNotification'>(this, {
            sessionsUpdateQueue: observable,
            addSessionNotification: action.bound,
            readQueueFromSessionStorage: action.bound,
            openSessionNotification: action.bound
        });

        this.projectsStore.sessionChanges.subscribe(this.openSessionNotification);
        this.readQueueFromSessionStorage();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }

    addSessionNotification(sessionId: string, applicationName: string, packageName: string) {
        this.sessionsUpdateQueue.push({
            sessionId,
            applicationName,
            packageName
        });
        sessionStorage.setItem('sessionQueue', JSON.stringify(this.sessionsUpdateQueue));
    }

    private readQueueFromSessionStorage() {
        var storedQueue = sessionStorage.getItem('sessionQueue');
        if (storedQueue) {
            this.sessionsUpdateQueue = JSON.parse(storedQueue);
        }
    }

    private openSessionNotification(updatePayload: ApplicationSessionChanges | null) {
        if (!updatePayload) {
            return;
        }

        const hasToOpen =
            (updatePayload.state === 'WaitingAction' || updatePayload.state === 'Finished') &&
            this.sessionsUpdateQueue &&
            this.sessionsUpdateQueue.length > 0;
        if (hasToOpen) {
            let queueItem = this.sessionsUpdateQueue.find(i => i.sessionId === updatePayload.id);
            if (queueItem) {
                // Format JSON, before passing from camel case to pascal case
                var keys = Object.getOwnPropertyNames(updatePayload.applicationSettings);
                for (var key of keys) {
                    if (updatePayload.applicationSettings[key]) {
                        updatePayload.applicationSettings[key[0].toUpperCase() + key.slice(1)] =
                            updatePayload.applicationSettings[key];
                        delete updatePayload.applicationSettings[key];
                    }
                }
                const tab: TabModel = {
                    id: updatePayload.id,
                    title: queueItem!.packageName,
                    isSelected: false,
                    type: 'document',
                    metadata: {
                        dynamicUI: undefined,
                        packageId: updatePayload.packageId,
                        packageSetId: updatePayload.packageSetId,
                        packageName: queueItem!.packageName,
                        sessionId: updatePayload.id,
                        sessionState: updatePayload.state,
                        projectId: updatePayload.projectId,
                        sessionError: updatePayload.error,
                        uiIsLoading: true,
                        runtimeSessionId: updatePayload.runtimeSessionId,
                        isReloadable: updatePayload.isReloadable,
                        customUI: updatePayload.capabilities?.customUI,
                        isBulk: updatePayload.isBulkSession,
                        applicationData: {
                            appName: queueItem!.applicationName,
                            appId: updatePayload.applicationDefinitionId,
                            appExtension: updatePayload.applicationExtension,
                            appSettings: updatePayload.applicationSettings,
                            iotaApplication: updatePayload.iotaApplication
                        }
                    }
                };

                this.tabsStore.addTab(tab);
                const index = this.tabsStore.tabs.indexOf(tab);

                if (tab.metadata && tab.metadata.sessionError) {
                    notification.error({
                        style: { cursor: 'pointer' },
                        placement: 'bottomRight',
                        message: 'Analysis is complete with errors',
                        description: `"${queueItem.applicationName}" was executed with errors on "${queueItem.packageName}". Click to open.`,
                        onClick: () => {
                            this.tabsStore.selectTab(index);
                        }
                    });
                } else {
                    notification.success({
                        style: { cursor: 'pointer' },
                        placement: 'bottomRight',
                        message: 'Analysis is complete',
                        description: `"${queueItem.applicationName}" was successfully executed on "${queueItem.packageName}". Click to open.`,
                        onClick: () => {
                            this.tabsStore.addTab(tab);
                        }
                    });
                }

                runInAction(() => {
                    this.sessionsUpdateQueue = this.sessionsUpdateQueue.filter(s => s.sessionId !== updatePayload.id);
                    sessionStorage.setItem('sessionQueue', JSON.stringify(this.sessionsUpdateQueue));
                });
            }
        }
    }
}
