import * as React from 'react';
import { observer } from 'mobx-react-lite';

type Props = {
    src: string;
    fallbackSrc?: string;
    className?: string;
    alt?: string;
};

export const ImageWithFallback: React.FC<Props> = ({ src, fallbackSrc, className, alt }) => {
    const [failed, setFailed] = React.useState(false);

    return (
        <>
            {failed ? (
                <img src={fallbackSrc} className={className} alt={alt} />
            ) : (
                <img src={src} onError={() => setFailed(true)} className={className} alt={alt} />
            )}
        </>
    );
};

export default observer(ImageWithFallback);
