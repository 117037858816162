import * as React from 'react';
import { useState, useEffect } from 'react';
import { DynamicControlProps } from './types';
import { WidgetDataItem } from '../../../common/services/types';
import DynamicDropDown from './DynamicDropDown';
import { Tooltip, Form } from 'antd';
import { getConfLevelColor, ConfidenceLevel } from './ConfidenceLevel';
import { Utils } from '../../../common/misc/Utils';
import { CommentOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Subscription } from 'rxjs';
import { SelectWithTextSelection } from '../../../common/components/SelectWithTextSelection';

export const DynamicCombobox: React.FC<DynamicControlProps> = ({
    inputParams,
    onAction,
    ui,
    form,
    onHighlightBlock,
    highlightInputSubject,
    getGearIcon,
    handleCommentFieldClick
}) => {
    const [values, setValues] = useState<WidgetDataItem[]>([]);
    const [currentValue, setCurrentValue] = useState<{ value: string; text: string }[] | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [isHighlighted, setIsHighlighted] = useState(false);

    useEffect(() => {
        if (inputParams.behavior && inputParams.behavior.multiselect) {
            setLoading(true);
            ui.getWidgetValues(inputParams.source.id).then(r => {
                setValues(r.data);
                setLoading(false);
            });

            ui.getCurrentWidgetValue(inputParams.id, inputParams.source.id).then(r => {
                const obj = {};
                obj[inputParams.id] =
                    r === undefined
                        ? r
                        : r instanceof Array
                          ? r.length
                              ? r.map(v => v && v.value)
                              : undefined
                          : [r].map(v => v && v.value);
                form.setFieldsValue(obj);
                setCurrentValue(r === undefined ? r : (r as Array<WidgetDataItem>));
            });
        }
    }, [form, inputParams.behavior, inputParams.id, inputParams.source.id, ui]);

    let sub: React.MutableRefObject<Subscription | undefined> = React.useRef();

    React.useEffect(() => {
        if (highlightInputSubject) {
            sub.current = highlightInputSubject.subscribe(id => {
                if (id === inputParams.id) {
                    setIsHighlighted(true);
                } else {
                    setIsHighlighted(false);
                }
            });
        }

        return () => {
            if (sub.current) {
                sub.current.unsubscribe();
            }
        };
    }, [highlightInputSubject, inputParams.id]);

    if (!(inputParams.behavior && inputParams.behavior.multiselect)) {
        return (
            <DynamicDropDown
                inputParams={inputParams}
                onAction={onAction}
                ui={ui}
                form={form}
                onHighlightBlock={onHighlightBlock}
                highlightInputSubject={highlightInputSubject}
                getGearIcon={getGearIcon}
                handleCommentFieldClick={handleCommentFieldClick}
            />
        );
    } else {
        // const handleChange = (value: string) => {
        //     // setCurrentValue(event.target.value);
        //     throw new Error('Not implemented!');
        //     // onchange(inputParams.id, event.target.value);
        // };

        const highlightField = () => {
            const fieldData = Utils.extractFieldData(inputParams);
            if (fieldData) {
                onHighlightBlock(fieldData, inputParams.id);
            }
        };

        const getTooltip = () => {
            if (!inputParams.behavior || !inputParams.behavior.inputTooltip) {
                return null;
            }
            return (
                <Tooltip
                    overlayClassName="alpha-input-tooltip"
                    title={inputParams.behavior.inputTooltip}
                    placement="top"
                >
                    <QuestionCircleOutlined style={{ marginLeft: 6, verticalAlign: 'middle' }} />
                </Tooltip>
            );
        };

        const label = (
            <>
                <span className="alpha-doc-control-label with-controls">
                    <Tooltip title={inputParams.name}>
                        <span className="alpha-doc-control-name">{inputParams.name}</span>
                    </Tooltip>
                    <span className="controls-container">
                        {inputParams.behavior && inputParams.behavior.readonly ? (
                            <></>
                        ) : (
                            <ConfidenceLevel input={inputParams} />
                        )}

                        {inputParams.behavior?.comments && (
                            <CommentOutlined
                                className="comments-icon"
                                onClick={() => handleCommentFieldClick!(inputParams.id)}
                            />
                        )}

                        {(inputParams.meta && inputParams.meta.field && inputParams.value != null && (
                            <Tooltip title="View in document">
                                <i className="alpha-icon xs form-anchor" onClick={highlightField} />
                            </Tooltip>
                        )) ||
                            getGearIcon(inputParams)}
                        {getTooltip()}
                    </span>
                </span>
            </>
        );

        const getInputCssClass = () => {
            let styleClass = '';

            if (inputParams.behavior && inputParams.behavior.style) {
                styleClass += ' colored-select';
            }

            styleClass += isHighlighted ? ' iota-contract-shadow' : '';
            return styleClass;
        };

        return (
            <Form.Item
                data-id={inputParams.id}
                data-type={inputParams.controlType}
                label={label}
                colon={false}
                labelCol={{ span: 24 }}
                name={inputParams.id}
                rules={[
                    {
                        required:
                            inputParams.validation &&
                            inputParams.validation.required &&
                            inputParams.validation.required.value,
                        message:
                            (inputParams.validation &&
                                inputParams.validation.required &&
                                inputParams.validation.required.message) ||
                            `${inputParams.name} is required`
                    },
                    {
                        pattern:
                            inputParams.validation &&
                            inputParams.validation.regex &&
                            new RegExp(inputParams.validation.regex.value),
                        message:
                            (inputParams.validation &&
                                inputParams.validation.regex &&
                                inputParams.validation.regex.message) ||
                            'Invalid input format'
                    }
                ]}
                initialValue={currentValue ? currentValue.map(v => v.value) : undefined}
            >
                <SelectWithTextSelection
                    className={currentValue ? getConfLevelColor(inputParams) + getInputCssClass() : getInputCssClass()}
                    allowClear={inputParams.validation?.required?.value === true ? false : true}
                    style={{
                        boxShadow: isHighlighted ? '0px 0px 10px 1px rgba(131,172,222,1)' : 'none',
                        ...Utils.buildDynamicInputStyles(inputParams)
                    }}
                    mode="multiple"
                    showSearch
                    filterOption
                    optionFilterProp="label"
                    disabled={(inputParams.behavior && inputParams.behavior.readonly) || loading}
                    loading={loading}
                    getPopupContainer={trigger => trigger.parentNode as HTMLElement}
                    options={
                        values &&
                        values.map(v => {
                            return { label: v.text, value: v.value };
                        })
                    }
                    suffixIcon={<i className="alpha-icon xxs arrow-down-icon" style={{ margin: 0, width: 8 }} />}
                />
            </Form.Item>
        );
    }
};

export default DynamicCombobox;
