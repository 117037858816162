export enum ImagePreviewSize {
    Large = 'Large',
    Small = 'Small',
    DocumentSize = 'DocumentSize',
    LargeDocumentSize = 'LargeDocumentSize'
}

export type ImagePreviewWithSize = {
    path: string;
    page: number;
    size: {
        width: number;
        height: number;
    };
};
