import { computed, makeObservable } from 'mobx';
import { CustomUiDemoAppRuntimeResponse } from '../types';
import { TabModel } from '../../common/types/TabModel';
import { IotaSessionsStore } from '../../common/stores';
import { CustomRuntimeInput, CustomSessionRuntimeData } from '../../custom_shared/types';
import { SessionStoreBase, RuntimeDataStore } from '../../custom_shared/stores';

enum Actions {
    UpdateSession = 'UpdateSession',
    FinishSession = 'FinishSession'
}

export default class SessionStore extends SessionStoreBase<CustomUiDemoAppRuntimeResponse> {
    constructor(
        tab: TabModel,
        iotaSessionsStore: IotaSessionsStore,
        private readonly runtimeDataStore: RuntimeDataStore
    ) {
        super(tab, iotaSessionsStore);

        makeObservable(this, {
            initialInputs: computed,
            runtimeData: computed
        });
    }

    async update() {
        await this.continueSession(Actions.UpdateSession, this.runtimeDataStore.getValues());
    }

    async finish() {
        await this.continueSession(Actions.FinishSession, this.runtimeDataStore.getValues());
    }

    get initialInputs(): CustomRuntimeInput[] {
        return this.response ? this.response.initialInputs : [];
    }

    get runtimeData(): CustomSessionRuntimeData {
        return this.response ? this.response.runtimeData : {};
    }
}
