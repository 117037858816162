import { createContext } from 'react';

const authContext = createContext({
    permissions: [] as string[],
    objectPermissions: {} as { [id: string]: string[] }
});

export const AuthProvider = authContext.Provider;
export const AuthConsumer = authContext.Consumer;
export default authContext;
