import { HomeVisualStore, AnalyzeVisualStore } from '../stores';
import * as React from 'react';
import { Card, Table, Tooltip } from 'antd';
import { observer, Observer } from 'mobx-react-lite';
import { ApplicationSession } from '../../common/services/types';
import { generateDocumentIcon } from '../../common/components/DocumentIconRenderer';
import { useNavigate } from 'react-router';
import { CompletedSessionIcon } from '../../common/components';

type Props = {
    store: HomeVisualStore;
    analyzeStore: AnalyzeVisualStore;
};

export const RecentlyClosedSessionsCard: React.FC<Props> = ({ store, analyzeStore }) => {
    const navigate = useNavigate();
    const columns = [
        {
            title: 'Document',
            key: 'packageName',
            render: (s: ApplicationSession) => {
                return (
                    <div className="label-with-icon archive-doc-name">
                        {generateDocumentIcon(s.packageName, !!s.packageSetId)} <span>{s.packageName}</span>
                    </div>
                );
            }
        },
        {
            title: 'Application',
            dataIndex: 'applicationName',
            key: 'applicationName'
        },
        {
            title: 'Project',
            dataIndex: 'projectName',
            key: 'projectName'
        },
        {
            title: 'User',
            key: 'userId',
            render: (s: ApplicationSession) => {
                return store.allUsers[s.userId!];
            }
        },
        {
            title: 'Created Date & Time',
            key: 'createdDate',
            render: (s: ApplicationSession) => {
                return formatDate(s.created);
            }
        },
        {
            title: 'Updated Date & Time',
            key: 'updatedDate',
            render: (s: ApplicationSession) => {
                return formatDate(s.updated);
            }
        },
        {
            title: 'Status',
            key: 'status',
            width: '95px',
            render: (s: ApplicationSession) => {
                return <>{generateStatusIcon(s)}</>;
            }
        },
        {
            key: 'operations',
            render: (s: ApplicationSession) => {
                return (
                    <div className="closed-session-operation">
                        {s.isReloadable !== false ? (
                            <Tooltip title="Start from scratch">
                                <i
                                    className={'alpha-icon xs reload'}
                                    onClick={() => analyzeStore.startSessionFromExisting(s)}
                                />
                            </Tooltip>
                        ) : null}
                        <Tooltip title="Switch to Analysis form">
                            <i
                                className={'alpha-icon xs session-switch'}
                                onClick={() => analyzeStore.switchToSession(s)}
                            />
                        </Tooltip>
                    </div>
                );
            },
            width: '95px'
        }
    ];

    const generateStatusIcon = (session: ApplicationSession) => {
        if (session.error) {
            return (
                <Tooltip title={session.error}>
                    <span className="closed-session-status" style={{ background: '#F5222D', fontSize: 11 }}>
                        Failed
                    </span>
                </Tooltip>
            );
        }

        return <CompletedSessionIcon session={session} />;
    };

    const formatDate = (date: string | null) => {
        if (date) {
            return Intl.DateTimeFormat('en-Gb', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit'
            }).format(new Date(date));
        } else {
            return '';
        }
    };

    const goToSessions = () => {
        navigate('/archive');
    };

    const ref = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (!ref.current || !analyzeStore.sessionToHighlight) {
            return;
        }

        ref.current.scrollIntoView({ block: 'start', behavior: 'smooth' });

        const highlightTimeout = setTimeout(() => {
            analyzeStore.setSessionToHighlight(null);
        }, 3000);

        return () => clearTimeout(highlightTimeout);
    }, [analyzeStore, analyzeStore.sessionToHighlight]);

    return (
        <Card className="content-card table-content borderless">
            <div ref={ref} className="card-header">
                <span className="content-card-title">Recently closed</span>
            </div>
            <Observer>
                {() => (
                    <Table
                        rowKey={r => r.id}
                        className="alpha-table recently-closed-sessions"
                        rowClassName={session => (session.id === analyzeStore.sessionToHighlight ? 'error-row' : '')}
                        footer={() => {
                            if (analyzeStore.recentFinishedSessions && analyzeStore.recentFinishedSessions.length > 0) {
                                return (
                                    <div className="table-footer-link label-with-icon" onClick={goToSessions}>
                                        <span>View all</span>
                                    </div>
                                );
                            } else {
                                return null;
                            }
                        }}
                        columns={columns}
                        dataSource={analyzeStore.recentFinishedSessions}
                        pagination={false}
                    />
                )}
            </Observer>
        </Card>
    );
};

export default observer(RecentlyClosedSessionsCard);
