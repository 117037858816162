import * as React from 'react';
import { Row, Col, Button, message, Spin } from 'antd';
import RecentlyClosedSessionsCard from '../components/RecentlyClosedSessionsCard';
import { useHomeStore } from '../stores/context';
import { observer } from 'mobx-react-lite';
import OpenOrdersCard from '../components/OpenSessionsCard';
import { useStore } from '../../common/stores/context';
import { useSessionsStore } from '../../sessions/stores/context';
import NewContractDialog from '../components/NewContractDialog';
import SessionProgressDialog from '../components/SessionProgressDialog';
import Dragger from 'antd/lib/upload/Dragger';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { FileUploadResponse } from '../types';
import { PackageStateResult } from '../../common/services/types';
import ProjectsSelectionDialog from '../components/ProjectsSelectionDialog';
import { ImagePreviewSize } from '../../../modules/documents/types/types';
import { hasPermission, HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import authContext from '../../authorization/AuthContext';
import { useNavigate, useParams, useLocation } from 'react-router';
import { analysisPath } from '../routes';
import { Utils } from '../../common/misc/Utils';

const HomePage: React.FC = () => {
    const { homeVisualStore, previewsVisualStore, analyzeVisualStore } = useHomeStore();
    const { projectsStore, applicationDefinitionsStore, tabsStore, fileStore, restricedAccessStore } = useStore();
    const { sessionsVisualStore } = useSessionsStore();
    const { sessionId } = useParams();
    const authCtx = React.useContext(authContext);
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const toolbar = searchParams.get('toolbar');

    React.useEffect(() => {
        (async () => {
            try {
                if (!sessionId) {
                    return;
                }

                const restricedModeEnabled = toolbar !== null;
                restricedAccessStore.setRestrictedModeEnabled(restricedModeEnabled);

                if (restricedModeEnabled) {
                    restricedAccessStore.setRestrictedSessionInitializing(true);
                }

                // Make sure session id is valid before trying to load the session
                if (!Utils.isValidObjectId(sessionId)) {
                    message.error('Invalid session id');
                    navigate(analysisPath);
                    return;
                }

                // Check if session is already open and select tab
                const tab = tabsStore.tabs.find(t => t.id === sessionId);

                if (tab) {
                    tabsStore.selectTab(tabsStore.tabs.indexOf(tab), false);
                    return;
                }

                // Otherwise try to load the session
                await projectsStore.loadProjectsPromise;

                const session = await sessionsVisualStore.getSessionById(sessionId);

                if (!session) {
                    message.error(
                        'You do not have permission to view this session. Please contact your administrator for assistance.'
                    );
                    navigate(analysisPath);
                    return;
                }

                tabsStore.addTab({
                    id: session.id,
                    title: session.packageName,
                    isSelected: true,
                    type: session.isBulkSession ? 'multidocument' : 'document',
                    metadata: {
                        packageId: session.packageId,
                        sessionId: session.id,
                        packageName: session.packageName,
                        dynamicUI: undefined,
                        sessionError: session.error,
                        sessionState: session.state,
                        runtimeSessionId: session.runtimeSessionId,
                        packageSetId: session.packageSetId,
                        projectName: session.projectName,
                        projectId: session.projectId,
                        isReloadable: session.isReloadable,
                        customUI: session.capabilities?.customUI,
                        isBulk: session.isBulkSession,
                        applicationData: {
                            appName: session.applicationName,
                            appId: session.applicationDefinitionId,
                            appExtension: session.applicationExtension,
                            appSettings: session.applicationSettings,
                            iotaApplication: session.iotaApplication
                        }
                    }
                });
            } finally {
                if (restricedAccessStore.restrictedSessionInitializing) {
                    restricedAccessStore.setRestrictedSessionInitializing(false);
                }
            }
        })();
    }, [sessionId, toolbar, navigate, sessionsVisualStore, tabsStore, projectsStore, restricedAccessStore]);

    React.useEffect(() => {
        const loadForCurrentUserOnly =
            hasPermission(authCtx.permissions, AppPermissions.CanSeeAnalysisPagePersonal) &&
            !hasPermission(authCtx.permissions, AppPermissions.CanSeeAnalysisPageAll);
        sessionsVisualStore.setLoadForCurrentUserOnly(loadForCurrentUserOnly);
        analyzeVisualStore.loadSessions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [analyzeVisualStore]);

    React.useEffect(() => {
        homeVisualStore.openSessions.forEach(s => {
            if (!previewsVisualStore.smallPreviewUrls[s.packageId ?? s.packageSetId]) {
                previewsVisualStore.getPreviews(s.packageId ?? s.packageSetId, ImagePreviewSize.Small);
            }
        });
    }, [homeVisualStore.openSessions, previewsVisualStore]);

    React.useEffect(() => {
        if (fileStore.isSessionProgressDialogVisible) {
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileStore.isSessionProgressDialogVisible]);

    React.useEffect(() => {
        if (fileStore.isNewContractModalVisibile) {
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileStore.isNewContractModalVisibile]);

    const beforeUpload = (file: RcFile) => {
        if (projectsStore.projects && projectsStore.projects.length > 1) {
            homeVisualStore.setFileToUpload(file);
            homeVisualStore.setShowProjectSelectionModal(true);
            return false;
        } else {
            homeVisualStore.setShowNewContractModal(true);
            return true;
        }
    };

    const onChange = (info: UploadChangeParam) => {
        const status = info.file.status;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }

        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
            const response: FileUploadResponse = info.file.response;
            let newPkg = projectsStore.packageListItems.find(
                p => p.fileName === info.file.name && p.state === PackageStateResult.Uploading
            );
            if (newPkg) {
                projectsStore.updatePackageInList(newPkg, {
                    ...response,
                    state: PackageStateResult.Importing,
                    fileSizeBytes: info.file.size as number
                });
            }
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    if (restricedAccessStore.restrictedSessionInitializing) {
        return <Spin className="sessions-loading-spinner" style={{ height: '100vh' }} size="large" />;
    }

    return (
        <HasPermission
            permissionClaim={[AppPermissions.CanSeeAnalysisPageAll, AppPermissions.CanSeeAnalysisPagePersonal]}
        >
            <div style={{ marginBottom: 50 }}>
                <Row style={{ height: 40, marginBottom: 30 }}>
                    <Col span={2}>
                        <h1 style={{ fontSize: 34 }}>Home</h1>
                    </Col>
                    <Col span={22} style={{ textAlign: 'right', paddingTop: 4 }}>
                        <Button
                            style={{ height: 40 }}
                            type="primary"
                            onClick={() => homeVisualStore.setShowNewContractModal(true)}
                        >
                            <div>
                                <span>New Analysis</span>
                            </div>
                        </Button>
                    </Col>
                </Row>
                <HasPermission permissionClaim={AppPermissions.CanEditProcessDocuments}>
                    <Dragger
                        beforeUpload={beforeUpload}
                        showUploadList={false}
                        onChange={onChange}
                        name="file"
                        multiple={false}
                        action={projectsStore.docUploadAction}
                        height={84}
                    >
                        <p className="ant-upload-text home-upload-text">
                            <i className="alpha-icon sm home-upload-file" />
                            Drag & drop file here to upload...
                        </p>
                    </Dragger>
                </HasPermission>
                <NewContractDialog
                    projectsStore={projectsStore}
                    appStore={applicationDefinitionsStore}
                    previewsStore={previewsVisualStore}
                    homeStore={homeVisualStore}
                    visible={homeVisualStore.showNewContractModal || fileStore.isNewContractModalVisibile}
                    setVisibility={homeVisualStore.setShowNewContractModal}
                />
                <ProjectsSelectionDialog homeStore={homeVisualStore} projectsStore={projectsStore} />
                <SessionProgressDialog
                    homeStore={homeVisualStore}
                    previewStore={previewsVisualStore}
                    analyzeStore={analyzeVisualStore}
                    visible={analyzeVisualStore.showProgressModal || fileStore.isSessionProgressDialogVisible}
                    setVisibility={analyzeVisualStore.setShowProgressModal}
                />
                {!analyzeVisualStore.sessionsInitializing ? (
                    <>
                        <OpenOrdersCard
                            store={homeVisualStore}
                            tabsStore={tabsStore}
                            previewsStore={previewsVisualStore}
                            analyzeStore={analyzeVisualStore}
                            sessionsVisualStore={sessionsVisualStore}
                        />
                        <HasPermission permissionClaim={AppPermissions.CanAccessArchive}>
                            <RecentlyClosedSessionsCard store={homeVisualStore} analyzeStore={analyzeVisualStore} />
                        </HasPermission>
                    </>
                ) : (
                    <Spin className="sessions-loading-spinner" size="large" />
                )}
            </div>
        </HasPermission>
    );
};

export default observer(HomePage);
